<template>
  <auth-layout>
    <create-passcode-form/>
  </auth-layout>
</template>
<script>
	//@ is an alias to /src
	import CreatePasscodeForm from '@/components/form/CreatePasscodeForm.vue'
  import AuthLayout from "@/layout/AuthLayout";
	export default{
			name: 'SignUpCreatePasscodeView',
			components:{
        AuthLayout,
				CreatePasscodeForm
			}
	}
</script>
<style scoped>
</style>
