<template>

		<section id="AccountCreationErrorCard" class="container-small">
            <div class="image-wrapper"><img src="@/assets/images/delete-1.png" loading="lazy" width="161" alt=""></div>
            <div class="form-wrapper">
              <div class="form-header-wrapper">
                <div class="margin-top">
                  <h3 class="text-align-center text-color-white">Something went wrong</h3>
                  <div class="margin-top margin-xsmall padding-horizontal padding-small">
<!--                    <p class="newsletter-text text-color-white text-align-center">Here we go your journey towards awesomeness starts now</p>-->
                  </div>
                </div>
              </div>
              <div class="form-body-wrapper">
                <div class="margin-top margin-large">
                  <div class="form-fields-wrapper">
                    <div class="margin-top">
                      <div class="margin-bottom margin-small">
                        <router-link to="/log-in-view">
                          <a class="button white-button w-button">Proceed to Login</a>
                        </router-link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
</template>
<script>
	export default {
	name: "AccountCreationErrorCard"
	};
</script>
<style scoped lang="scss">
   h3 {margin: 40px 0 0; }
   ul {list-style-type: none; padding: 0; }
   li {display: inline-block; margin: 0 10px; }
   //a {color: #42b983;}
</style>

