<template>
  <section id="FundWalletCard" class="dashboard-body-wrapper align-center">

          <div class="back-button margin-vertical margin-large">
<!--            <a href="@/assets/views/home-view.html" class="w-inline-block"><img src="@/assets/images/Navigation-controls.svg" loading="lazy" alt=""></a>-->
            <router-link to="/home-view"><a class="w-inline-block"><img src="@/assets/images/Navigation-controls.svg" loading="lazy" alt=""></a></router-link>
          </div>

          <div class="add-money-banner">
            <div class="add-money-wrapper">
              <div>
                <div class="text-block-58">Fund Wallet</div>
                <div class="text-block-59">Add money to your Rubies wallet</div>
              </div>
              <div><img src="@/assets/images/add-money-1-1.png" loading="lazy" width="84" alt=""></div>
            </div>
          </div>


          <div class="margin-top margin-medium">

            <router-link to="/interac-funding">
              <a class="interac-card w-inline-block">
                <div class="setup-title-wrapper"><img src="@/assets/images/bank.svg" loading="lazy" alt="">
                  <div class="setup-title">
                    <div class="text-block-50">Fund with Interac</div>
                    <div class="text-block-51">Connect your bank account and fund instantly.</div>
                  </div>
                </div><img src="@/assets/images/Component-3.svg" loading="lazy" alt="">
              </a>
            </router-link>

          </div>


<!--          <div class="margin-top margin-medium">-->
<!--            <div class="connected-bank-wrapper">-->
<!--              <div class="margin-bottom margin-medium">-->
<!--                <div class="text-block-60">CONNECTED BANK ACCOUNTS</div>-->
<!--              </div>-->
<!--              <div class="connected-banks-list">-->

<!--                <router-link to="">-->
<!--                  <a  class="interac-card w-inline-block">-->
<!--                    <div class="setup-title-wrapper"><img src="@/assets/images/bank.svg" loading="lazy" alt="">-->
<!--                      <div class="setup-title">-->
<!--                        <div class="text-block-50">0123456789</div>-->
<!--                        <div class="text-block-51">REVOLUT | Orofin Oluwaseun Godwin</div>-->
<!--                      </div>-->
<!--                    </div>-->
<!--                  </a>-->
<!--                </router-link>-->

<!--              </div>-->
<!--            </div>-->
<!--          </div>-->

        </section>
</template>
<script>
	export default {
	name: "FundWalletCard"
	};
</script>
<style scoped lang="scss">
   h3 {margin: 40px 0 0; }
   ul {list-style-type: none; padding: 0; }
   li {display: inline-block; margin: 0 10px; }
</style>

