<template>
  <dashboard-layout>
    <div class="dashboard-body-wrapper align-center">
      <div class="kyc-nav-wrapper margin-vertical margin-large">
        <router-link to="/kyc-verification">
          <a class="w-inline-block"><img src="@/assets/images/Navigation-controls.svg" loading="lazy" alt=""></a>
        </router-link>
      </div>
      <IdentityKycCard/>
    </div>
  </dashboard-layout>
</template>
<script>
	//@ is an alias to /src
	import IdentityKycCard from '@/components/card/IdentityKycCard.vue'
  import DashboardLayout from "@/layout/DashboardLayout";
	export default{
			name: 'IdentityVerificationView',
			components:{
        DashboardLayout,
				IdentityKycCard
			}
	}
</script>
<style scoped>
</style>
