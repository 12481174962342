<template>
  <dashboard-layout>
    <div class="dashboard-body-wrapper align-center">
      <div class="kyc-nav-wrapper margin-vertical margin-large">
        <router-link to="/add-recipient">
          <a class="w-inline-block"><img src="@/assets/images/Navigation-controls.svg" loading="lazy" alt=""></a>
        </router-link>
      </div>
      <TransferAmountCard/>
    </div>
  </dashboard-layout>
</template>
<script>
	//@ is an alias to /src
	import TransferAmountCard from '@/components/card/TransferAmountCard.vue'
  import DashboardLayout from "@/layout/DashboardLayout";
	export default{
			name: 'TransferAmountView',
			components:{
        DashboardLayout,
				TransferAmountCard,
			}
	}
</script>
<style scoped>
</style>
