<template>
  <section id="SignUpForm" class="container-small">
            <div class="image-wrapper"><img src="@/assets/images/Group-22264.svg" loading="lazy" alt=""></div>
            <div class="form-wrapper">
              <div class="form-header-wrapper">
                <div class="margin-top margin-xlarge">
                  <h3>Enter phone number</h3>
                </div>
                <div class="margin-top margin-xsmall">
                  <p class="text-weight-medium text-color-grey">What mobile phone number would you like to use on your account?</p>
                </div>
              </div>
              <form class="form-body-wrapper" @submit.prevent="initiateEnrollment" >

                <div class="margin-top margin-large">
                  <div class="form-fields-wrapper">
                    <div class="margin-top">
                      <div class="margin-bottom margin-small">
                        <div class="phone-input-field">

                          <div data-hover="false" data-delay="0" class="w-dropdown">
                            <div class="select-country-wrapper w-dropdown-toggle">
                              <div class="icon w-icon-dropdown-toggle"></div>
                              <div class="country-flag">🇨🇦</div>
                            </div>
                            <select class="w-dropdown-list">
                              <option  class="w-dropdown-link">Link 1</option>
                              <option  class="w-dropdown-link">Link 2</option>
                              <option  class="w-dropdown-link">Link 3</option>
                            </select>
                          </div>
                          <input type="number" v-model="model.customerPhone" class="input-field phone-number w-input" maxlength="256" name="field" data-name="Field" placeholder="Enter Phone Number " id="field" required="">

                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="margin-top margin-large">
                  <div class="form-fields-wrapper">
                    <div class="margin-top">
<!--                      <div class="margin-bottom margin-small">-->
<!--                        <router-link to="/sign-up-verify-phoneNumber"><a class="button max-width-full w-button">Proceed</a></router-link>-->
<!--                      </div>-->
                      <button class="button max-width-full w-button" >Proceed</button>

                    </div>
                  </div>
                </div>

              </form>
              <br/>
              <router-link to="/log-in-view"><p class="button is-secondary max-width-full text-weight-medium w-button">Don’t have an account? <span class="text-weight-bold">Log In</span></p></router-link>
            </div>
          </section>
</template>
<script>
	import AuthenticationRequest from "@/model/request/AuthenticationRequest";
  import StoreUtils from "@/util/baseUtils/StoreUtils";
  import {mapState} from "vuex";

  export default {
	name: "SignUpForm",
    data(){
      return {
        model: new AuthenticationRequest().initiateEnrollment
      }
    },
    methods:{
      initiateEnrollment(){
        // StoreUtils.dispatch(StoreUtils.actions.auth.initiateEnrollment, this.model)
        StoreUtils.commit(StoreUtils.mutations.auth.updateSignUpFormData, this.model)
        this.$router.push("/sign-up-verify-phoneNumber")
      },
    },
    computed:{
      ...mapState({
        loading: state => state.auth.loading,
        screen: state => state.auth.screen,
        auth: state => state.auth
      })
    }
	};
</script>
<style scoped lang="scss">
   h3 {margin: 40px 0 0; }
   ul {list-style-type: none; padding: 0; }
   li {display: inline-block; margin: 0 10px; }

   /* Chrome, Safari, Edge, Opera */
   input::-webkit-outer-spin-button,
   input::-webkit-inner-spin-button {
     -webkit-appearance: none;
     margin: 0;
   }

   /* Firefox */
   input[type=number] {
     -moz-appearance: textfield;
   }

</style>

