import swal from "sweetalert";
import StoreUtils from "@/util/baseUtils/StoreUtils";
import KycAddressRequest from "@/model/request/KycAddressRequest";
import KycAddressService from "@/service/KycAddressService";
import KycAddressResponse from "@/model/response/KycAddressResponse";
import RouterUtils from "@/util/baseUtils/RouterUtils";




export const state = {
    loading: false,
    address: null,
    addresses: new KycAddressResponse().read,
    addressReadBy: new KycAddressResponse().readBy,
    provinces: new KycAddressResponse().getProvinces,
    reaByCustomerId: new KycAddressResponse().readByCustomerId,
};

export const getters = {
    getAddress: (state) => {
        return state.address;
    },
    getAddresses: (state) => {
        return state.addresses;
    },
    getAddressReadBy: (state) => {
      return state.addressReadBy;
    },
    getProvinces: (state) => {
      return state.provinces
    },
    getReaByCustomerId: (state) => {
        return state.reaByCustomerId
    }
};


export const mutations = {
    updateLoading(state, payload){
        state.loading = payload
    },
    updateAddress(state, payload){
        state.address = payload
    },
    updateAddresses(state, payload){
        state.addresses = payload
    },
    updateAddressReadBy(state, payload){
      state.addressReadBy = payload
    },
    updateProvinces(state, payload){
        state.provinces = payload
    },
    updateReaByCustomerId(state, payload){
      state.reaByCustomerId = payload
    },
};

export const actions = {
    // eslint-disable-next-line no-empty-pattern
    addressCreate({}, payload = new KycAddressRequest().create){
        StoreUtils.commit(StoreUtils.mutations.address.updateLoading, true)
        return KycAddressService.callAddressCreateApi(payload).then(response=>{
            StoreUtils.commit(StoreUtils.mutations.address.updateLoading, false)
            let responseData = response.data
            if (responseData.responseCode === "00"){
                swal("Success",responseData.responseMessage, 'success').then(r => console.log(r))
                RouterUtils.navigateTo(RouterUtils.routes.kyc.reviewingKyc.name)
            }else{
                swal("Error",responseData.responseMessage, 'error').then(r => console.log(r))
            }
        }).catch(error=>{
            console.log(error)
            StoreUtils.commit(StoreUtils.mutations.address.updateLoading, false)
        })
    },

    // eslint-disable-next-line no-empty-pattern
    addressUpdate({}, payload = new KycAddressRequest().update){
        StoreUtils.commit(StoreUtils.mutations.address.updateLoading, true)
        return KycAddressService.callAddressUpdateApi(payload).then(response=>{
            StoreUtils.commit(StoreUtils.mutations.address.updateLoading, false)
            let responseData = response.data
            if (responseData.responseCode === "00"){
                swal("Success",responseData.responseMessage, 'success').then(r => console.log(r))
            }else{
                swal("Error",responseData.responseMessage, 'error').then(r => console.log(r))
            }
        }).catch(error=>{
            console.log(error)
            StoreUtils.commit(StoreUtils.mutations.address.updateLoading, false)
        })
    },

    // eslint-disable-next-line no-empty-pattern
    addressRead({}, payload = new KycAddressRequest().readBy){
        StoreUtils.commit(StoreUtils.mutations.address.updateLoading, true)
        return KycAddressService.callAddressReadByApi(payload).then(response=>{
            StoreUtils.commit(StoreUtils.mutations.address.updateLoading, false)
            let responseData = response.data
            if (responseData.responseCode === "00"){
                StoreUtils.commit(StoreUtils.mutations.addressReadBy, responseData.addressReadBy)
            }else{
                swal("Error",responseData.responseMessage, 'error').then(r => console.log(r))
            }
        }).catch(error=>{
            console.log(error)
            StoreUtils.commit(StoreUtils.mutations.address.updateLoading, false)
        })
    },

    // eslint-disable-next-line no-empty-pattern
    addressUpdateStatus({}, payload = new KycAddressRequest().updateStatus){
        StoreUtils.commit(StoreUtils.mutations.address.updateLoading, true)
        return KycAddressService.callAddressUpdateStatusApi(payload).then(response=>{
            StoreUtils.commit(StoreUtils.mutations.address.updateLoading, false)
            let responseData = response.data
            if (responseData.responseCode === "00"){
                swal("Success",responseData.responseMessage, 'success').then(r => console.log(r))
            }else{
                swal("Error",responseData.responseMessage, 'error').then(r => console.log(r))
            }
        }).catch(error=>{
            console.log(error)
            StoreUtils.commit(StoreUtils.mutations.address.updateLoading, false)
        })
    },

    //eslint-disable-next-line no-empty-pattern
    addressReads({}, payload = {}){
        StoreUtils.commit(StoreUtils.mutations.address.updateLoading, true)
        return KycAddressService.callAddressReadApi(payload).then(response=>{
            StoreUtils.commit(StoreUtils.mutations.address.updateLoading, false)
            let responseData = response.data
            if (responseData.responseCode === '00'){
                StoreUtils.commit(StoreUtils.mutations.addresses, responseData.addresses)
            }else{
                swal("Error",responseData.responseMessage, 'error').then(r => console.log(r))
            }
        }).catch(error=>{
            console.log(error)
            StoreUtils.commit(StoreUtils.mutations.address.updateLoading, false)
        })
    },

    //eslint-disable-next-line no-empty-pattern
    addressFetchProvinces({}, payload = {}){
        // StoreUtils.commit(StoreUtils.mutations.address.updateLoading, true)
        return KycAddressService.callAddressReadCanadianProvincesApi(payload).then(response=>{
            // StoreUtils.commit(StoreUtils.mutations.address.updateLoading, false)
            let responseData = response.data
            if (responseData.responseCode === '00'){
                StoreUtils.commit(StoreUtils.mutations.address.updateProvinces, responseData)
            }else{
                swal("Error",responseData.responseMessage, 'error').then(r => console.log(r))
            }
        }).catch(error=>{
            console.log(error)
            // StoreUtils.commit(StoreUtils.mutations.address.updateLoading, false)
        })
    },

    // eslint-disable-next-line no-empty-pattern
    addressReaByCustomerId({}, payload = new KycAddressRequest().readByCustomerId){
        StoreUtils.commit(StoreUtils.mutations.address.updateLoading, true)
        return KycAddressService.callReadByCustomerIdApi(payload).then(response=>{
            StoreUtils.commit(StoreUtils.mutations.address.updateLoading, false)
            let responseData = response.data
            if (responseData.responseCode === "00"){
                StoreUtils.commit(StoreUtils.mutations.address.updateReaByCustomerId, responseData)
            }else{
                swal("Error",responseData.responseMessage, 'error').then(r => console.log(r))
            }
        }).catch(error=>{
            console.log(error)
            StoreUtils.commit(StoreUtils.mutations.address.updateLoading, false)
        })
    },

};