import client  from "./BaseService";

export default {
    callIdentificationCreateApi(payload) {
        return client.apiClient.post("identification/create", payload);
    },
    callIdentificationReadApi() {
        return client.apiClient.post("identification/read", {});
    },
    callIdentificationUpdateApi(payload) {
        return client.apiClient.post("identification/update", payload);
    },
    callIdentificationReadByApi(payload) {
        return client.apiClient.post("identification/read-by-identification-id", payload);
    },
    callReadByIdentificationCustomerIdApi(payload) {
        return client.apiClient.post("identification/read-by-identification-customer-id", payload);
    },
};