<template>
  <auth-layout>
    <ResetPasscodeForm/>
  </auth-layout>
</template>


<script>
import ResetPasscodeForm from "@/components/form/ResetPasscodeForm";
import AuthLayout from "@/layout/AuthLayout";
export default {
  name: "ForgotPasswordResetPasscodeView",
  components: {AuthLayout, ResetPasscodeForm}
}
</script>


<style scoped>
</style>