class BeneficiaryResponse {
    read = {
        responseCode: null,
        responseMessage: null,
        data:[]
    };


    readBy = {
        responseCode: null,
        responseMessage: null,
        data: {
            beneficiaryId: null,
            beneficiaryName: null,
            beneficiaryUserId: null,
            beneficiaryAccountNumber: null,
            beneficiaryBankCode: null,
            beneficiaryBankName: null,
            beneficiaryStatus: null,
            beneficiaryCreatedAt: null,
            beneficiaryUpdatedAt: null
        }
    };


    data = {
        beneficiaryId: null,
        beneficiaryName: null,
        beneficiaryUserId: null,
        beneficiaryAccountNumber: null,
        beneficiaryBankCode: null,
        beneficiaryBankName: null,
        beneficiaryStatus: null,
        beneficiaryCreatedAt: null,
        beneficiaryUpdatedAt: null
    }



}

export default BeneficiaryResponse;