<template>
  <dashboard-layout>
    <div class="referral-header-wrapper">
      <div data-animation="over-right" data-collapse="small" data-duration="400" data-easing="ease" data-easing2="ease" role="banner" class="navbar w-nav">
        <NavBar/>
      </div>
    </div>
    <div class="margin-top margin-medium">
      <ReferralContentCard/>
    </div>
  </dashboard-layout>
</template>
<script>
	//@ is an alias to /src
	import NavBar from '@/components/bar/NavBar.vue'
	import ReferralContentCard from '@/components/card/ReferralContentCard.vue'
  import DashboardLayout from "@/layout/DashboardLayout";
	export default{
			name: 'ReferalHomeView',
			components:{
        DashboardLayout,
				NavBar,
				ReferralContentCard
			}
	}
</script>
<style scoped>
</style>
