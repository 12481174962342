class BeneficiaryRequest {
    create = {
        beneficiaryName : null,
        beneficiaryUserId : null,
        beneficiaryAccountNumber : null,
        beneficiaryBankCode : null,
        beneficiaryBankName : null
    };

    update = {
        beneficiaryId: null,
        beneficiaryName: null,
        beneficiaryUserId: null,
        beneficiaryAccountNumber: null,
        beneficiaryBankCode: null,
        beneficiaryBankName: null,
        beneficiaryStatus: null
    };

    readBy = {
        beneficiaryId : null
    };
}

export default BeneficiaryRequest;