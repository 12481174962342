class AccountInformationRequest {
    create = {
        accountInformationSourceOfIncome: null,
        accountInformationMonthlyIncome: null,
        accountInformationStatus: "PENDING",
        accountInformationBvn: null
    };

    update = {
        addressId : null,
        addressUserId : null,
        addressNumber : null,
        addressStreet : null,
        addressCity : null,
        addressState : null,
        addressCountry : null,
        addressStatus : null
    };

    readBy = {
        IdentificationCustomerId : null
    };

    updateStatus = {
        addressId : null,
        addressStatus : null
    };

    readByAccountInformationCustomerId = {
        accountInformationCustomerId: null
    }


}

export default AccountInformationRequest;