class KycIdentificationResponse {
    read = {
        responseCode: null,
        responseMessage: null,
        data: []
    };

    readBy = {
        responseCode: null,
        responseMessage: null,
        data: {
            identificationId: null,
            identificationUserId: null,
            identificationType: null,
            identificationNumber: null,
            identificationIssueDate: null,
            identificationExpiryDate: null,
            identificationLink: null,
            identificationStatus: null,
            identificationCreatedAt: null,
            identificationUpdatedAt: null
        }
    };

    readByIdentificationCustomerId = {
        responseCode: null,
        responseMessage: null,
        data:[
            {
                identificationId: null,
                identificationCustomerId: null,
                identificationType: null,
                identificationNumber: null,
                identificationIssueDate: null,
                identificationExpiryDate: null,
                identificationLink: null,
                identificationStatus: null,
                identificationCreatedAt: null,
                identificationUpdatedAt: null
            }
        ]
    };

    data = {
        identificationId: null,
        identificationUserId: null,
        identificationType: null,
        identificationNumber: null,
        identificationIssueDate: null,
        identificationExpiryDate: null,
        identificationLink: null,
        identificationStatus: null,
        identificationCreatedAt: null,
        identificationUpdatedAt: null
    }

}

export default KycIdentificationResponse;