<!--suppress ALL -->
<template>
  <section id="VerifyEmailForm" class="container-small">
            <div class="image-wrapper"><img src="@/assets/images/Group-22264.svg" loading="lazy" alt=""></div>
            <div class="form-wrapper">
              <div class="form-header-wrapper">
                <div class="margin-top margin-xlarge">
                  <h3>Verify email address</h3>
                </div>
                <div class="margin-top margin-xsmall">
                  <p class="text-weight-medium text-color-grey">Please enter the code we sent to <strong>{{ this.signInFormData.username }}</strong></p>
                </div>
              </div>

              <form class="form-body-wrapper" @submit.prevent="initiateResetPassword" >
                <div class="margin-top margin-large">
                  <div class="form-fields-wrapper">
                    <div class="margin-top">
<!--                      <input type="number" v-model="model.customerOtp" class="input-field w-input" maxlength="256" name="field" data-name="Field" placeholder="Enter OTP sent to Email Address" id="field" required="">-->

<!--                      <div class="pin-code" ref="pinContainer">-->
<!--                        <input type="number" autofocus v-model="pins[0]" maxlength="1" @keyup="handleKeyUp($event, 0)" @keydown="handleKeyDown($event, 0)" required="required">-->
<!--                        <input type="number" v-model="pins[1]" maxlength="1" @keyup="handleKeyUp($event, 1)" @keydown="handleKeyDown($event, 1)" required="required">-->
<!--                        <input type="number" v-model="pins[2]" maxlength="1" @keyup="handleKeyUp($event, 2)" @keydown="handleKeyDown($event, 2)" required="required">-->
<!--                        <input type="number" v-model="pins[3]" maxlength="1" @keyup="handleKeyUp($event, 3)" @keydown="handleKeyDown($event, 3)" required="required">-->
<!--                        <input type="number" v-model="pins[4]" maxlength="1" @keyup="handleKeyUp($event, 2)" @keydown="handleKeyDown($event, 4)" required="required">-->
<!--                        <input type="number" v-model="pins[5]" maxlength="1" @keyup="handleKeyUp($event, 3)" @keydown="handleKeyDown($event, 5)" required="required">-->
<!--                      </div>-->


                      <div class="pin-code" ref="pinContainer">
                        <div class="vue-otp-input">
                          <div
                              class="otp-wrapper"
                              :class="isInputFocused ? activeWrapperClassHandler : wrapperClassHandler"
                              :style="wrapperStyle"
                              :id="id"
                          >
                            <input
                                v-for="(digitInput, index) in digits"
                                ref="digitInput"
                                :key="id + index"
                                autocomplete="one-time-code"
                                v-model="inputValue[index]"
                                class="otp-input"
                                :class="[inputClassHandler, activeInput === index ? activeInputClassHandler : '']"
                                :placeholder="placeholder"
                                :disabled="isDisabled"
                                @focus="onFocus(index)"
                                @blur="onBlur"
                                @paste="OnPaste"
                                @input="onInput(index, $event)"
                                @change="onChanged(index)"
                                @keydown="keydownHandler(index, $event)"
                                :style="inputStyle"
                            />
                          </div>
                          <span v-if="!isValid" :class="errorClassHandler">
                          <slot name="errorMessage"></slot>
                        </span>
                        </div>
                      </div>



                    </div>
                  </div>
                </div>
                <div class="margin-top margin-large">
                  <div class="form-fields-wrapper">
                    <div class="margin-top">
                      <div class="margin-bottom margin-small">
<!--                        <button class="button max-width-full w-button" >Verify</button>-->
                        <base-button :loading="loading">Verify</base-button>
                      </div>

                    </div>
                  </div>
                </div>
              </form>
              <button @click="resendOtp" class="button is-secondary max-width-full text-weight-medium w-button">Didn&#x27;t get the code? <span class="text-weight-bold red-text">Resend</span></button>
            </div>
          </section>
</template>
<script>
	import AuthenticationRequest from "@/model/request/AuthenticationRequest";
  import StoreUtils from "@/util/baseUtils/StoreUtils";
  import {mapState} from "vuex";
  import BaseButton from "@/components/button/BaseButton";
  import RouterUtils from "@/util/baseUtils/RouterUtils";

  export default {
	name: "VerifyEmailForm",
    components: {BaseButton},
    props: {
      id: {
        type: String,
        default: "otp",
      },
      digits: {
        type: Number,
        default: 6,
      },
      mode: {
        type: String,
        default: "separate",
      },
      type: {
        type: String,
        default: "number",
      },
      placeholder: {
        type: String,
        default: "-",
      },
      radius: {
        type: Number,
        default: 5,
      },
      gap: {
        type: Number,
        default: 10,
      },
      isDisabled: {
        type: Boolean,
        default: false,
      },
      isValid: {
        type: Boolean,
        default: true,
      },
      rtl: {
        type: Boolean,
        default: false,
      },
      autoFocus: {
        type: Boolean,
        default: true,
      },
      errorClass: {
        type: String,
        default: "",
      },
      separateInputClass: {
        type: String,
        default: "",
      },
      separateWrapperClass: {
        type: String,
        default: "",
      },
      groupInputClass: {
        type: String,
        default: "",
      },
      groupWrapperClass: {
        type: String,
        default: "",
      },
      activeInputClass: {
        type: String,
        default: "",
      },
      activeWrapperClass: {
        type: String,
        default: "",
      },
    },
    data(){
      return {
        model: new AuthenticationRequest().completeResetPassword,
        pins: [],

        inputValue: [],
        joinedValue: "",
        isInputFocused: false,
        activeInput: -1,
      }
    },
    methods:{
      initiateResetPassword(){
        // StoreUtils.dispatch(StoreUtils.actions.auth.initiatePasswordReset, this.model)
        StoreUtils.commit(StoreUtils.mutations.auth.updateSignInFormData, {
          customerEmail: this.signInFormData.username,
          customerOtp: this.joinedValue,
        })
        this.$router.push("/forgot-password-reset-passcode")
      },
     async resendOtp(){
       await StoreUtils.dispatch(StoreUtils.actions.auth.resendOtp, {
          customerEmail: this.signInFormData.username,
          countryCode: "+234",
          username: this.signInFormData.username
        });
       await RouterUtils.navigateTo(RouterUtils.routes.auth.forgotPasswordVerifyEmail.name)
      },

      handleKeyUp(event) {
        const target = event.target;

        const maxLength = parseInt(target.attributes.maxlength.value, 10);
        const myLength = target.value.length;

        if (myLength >= maxLength) {
          const next = target.nextElementSibling;
          if (next && next.tagName.toLowerCase() === 'input') {
            next.focus();
          }
        }

        if (myLength === 0) {
          const prev = target.previousElementSibling;
          if (prev && prev.tagName.toLowerCase() === 'input') {
            prev.focus();
          }
        }

        this.$emit('input', this.pins.join(''));
      },
      handleKeyDown(event) {
        const target = event.target;
        target.value = '';
      },

      keydownHandler(index, e) {
        if (e.keyCode === 8 && e.target.value === "") {
          this.$refs.digitInput[Math.max(0, index - 1)].focus();
        }
      },
      onInput(index) {
        const [first, ...rest] =
            this.type === "number"
                ? this.inputValue[index].replace(/[^0-9]/g, "")
                : this.inputValue[index];
        this.inputValue[index] = first === null || first === undefined ? "" : first;
        const lastInputBox = index === this.digits - 1;
        const insertedContent = first !== undefined;
        if (insertedContent && !lastInputBox) {
          this.$refs.digitInput[index + 1].focus();
          this.$refs.digitInput[index + 1].value = rest.join("");
          this.$refs.digitInput[index + 1].dispatchEvent(new Event("input"));
        }
        this.joinedValue = this.inputValue.map((value) => value).join("");
        this.$emit("value", this.joinedValue);
        if (this.joinedValue.length === this.digits) {
          this.onComplete(this.joinedValue);
        }
      },
      onFocus(index) {
        this.activeInput = index;
        this.isInputFocused = true;
      },
      onBlur() {
        this.activeInput = -1;
        this.isInputFocused = false;
      },
      onComplete(joinedValue) {
        this.onBlur();
        this.$refs.digitInput[this.digits - 1].blur();
        if(this.type === "password"){
          this.$emit("on-complete", joinedValue);
        }
        else
          this.$emit("on-complete", joinedValue);
      },
      onChanged(index) {
        this.$emit("on-changed", this.inputValue[index]);
      },
      OnPaste(event) {
        this.$emit("on-paste", event);
      },
    },
    computed:{
      ...mapState({
        loading: state => state.auth.loading,
        screen: state => state.auth.screen,
        auth: state => state.auth
      }),
      signInFormData() {
        return StoreUtils.rootGetters(StoreUtils.getters.auth.getSignInFormData)
      },

      wrapperStyle() {
        const dir = this.rtl ? "rtl" : "ltr";
        const styles = {
          direction: dir,
          gap: `${this.gap}px`,
          "border-radius": `${this.radius}px`,
        };
        return styles;
      },
      inputStyle() {
        return {
          "--border-radius": `${this.radius}px`,
        };
      },
      inputClassHandler() {
        if (this.mode === "separate") {
          if (this.isValid) {
            return this.separateInputClass ? this.separateInputClass : "defualt-input-separate";
          } else {
            return this.separateInputClass ? this.separateInputClass : "defualt-error-input-separate";
          }
        }
        if (this.mode === "group") {
          return this.groupInputClass ? this.groupInputClass : "defualt-input-group";
        }
        return "";
      },
      activeInputClassHandler() {
        if (this.mode === "separate") {
          return this.activeInputClass ? this.activeInputClass : "defualt-active-input";
        }
        return "";
      },
      activeWrapperClassHandler() {
        if (this.mode === "group") {
          if (this.isValid) {
            return this.activeWrapperClass ? this.activeWrapperClass : "defualt-active-wrapper";
          } else {
            return this.activeWrapperClass ? this.activeWrapperClass : "defualt-error-wrapper-group";
          }
        }
        return "";
      },
      wrapperClassHandler() {
        if (this.mode === "separate") {
          return this.separateWrapperClass ? this.separateWrapperClass : "defualt-wrapper-separate";
        }
        if (this.mode === "group") {
          if (this.isValid) {
            return this.groupWrapperClass ? this.groupWrapperClass : "defualt-wrapper-group";
          } else {
            return this.groupWrapperClass ? this.groupWrapperClass : "defualt-error-wrapper-group";
          }
        }
        return "";
      },
      errorClassHandler() {
        return this.errorClass ? this.errorClass : "default-error-class";
      },
    },
    mounted() {
      if (this.autoFocus && !this.isDisabled) {
        this.onFocus(0);
        this.$refs.digitInput[0].focus();
        //setting input type based on the existing types for now
        const types = ["text", "password", "number"];
        if (types.indexOf(this.type)!=-1) {
          for (let box of this.$refs.digitInput) {
            box.type = this.type;
          }
        }
      }
    },
	};
</script>
<style scoped>
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.pin-code{
  padding: 0;
  display: flex;
  justify-content:space-evenly;
  margin-bottom: 2%;
  margin-top: 2%;
  margin-left: 38%;
  margin-right: 38%;
}

.pin-code input {
  border: 1px solid #D0D5DD;
  text-align: center;
  width: 50px;
  height: 50px;
  font-size: 30px;
  background-color: #F3F3F3;
  margin-right: 10px;
  border-radius: 6px;
  color: #101828;
}

.pin-code input:focus {
  border: 1px solid #101828;
  outline:none;
  color: #101828;
}

@media (max-width: 550px) {
  .pin-code input {
    width: 50px;
    height: 50px;
    font-size: 20px;
  }
}







div.vue-otp-input {
  width: max-content;
}
div.vue-otp-input > div.otp-wrapper {
  direction: var(--direction);
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 0.3s;
}

div.vue-otp-input > div.otp-wrapper > input.otp-input {
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 0.3s;
  border-radius: var(--border-radius);
}
div.vue-otp-input > div.otp-wrapper > input.otp-input:disabled {
  background-color: #F3F3F3 !important;
}
/* SINGLE INPUT IN SEPARATE MODE */
div.vue-otp-input > div.otp-wrapper > input.defualt-input-separate {
//text-align: center;
//font-weight: 600;
//background-color: #F3F3F3;
//border: 1px solid #D0D5DD;
//width: 3rem;
//height: 48px;
  border: 1px solid #D0D5DD;
  text-align: center;
  width: 50px;
  height: 50px;
  font-size: 30px;
  background-color: #F3F3F3;
//margin-right: 10px;
  border-radius: 6px;
  color: #101828;
}
div.vue-otp-input > div.otp-wrapper > input.defualt-error-input-separate {
  text-align: center;
  font-weight: 600;
  background-color: transparent;
  border: 1px solid #D0D5DD;
  width: 3rem;
  height: 48px;
}
@media only screen and (max-width: 600px) {
  div.vue-otp-input > div.otp-wrapper > input.defualt-input-separate {
    width: 50px;
    height: 50px;
  }
}
/* INPUTS WRAPPER IN SEPARATE MODE */
div.vue-otp-input > div.defualt-wrapper-separate {
  background: transparent;
}
/* INPUTS WRAPPER IN GROUP MODE */
div.vue-otp-input > div.defualt-wrapper-group {
  border: 1px solid #D0D5DD;
}
div.vue-otp-input > div.defualt-error-wrapper-group {
  border: 1px solid #D0D5DD;
}

/* SINGLE INPUT IN GROUP MODE */
div.vue-otp-input > div.otp-wrapper > input.defualt-input-group {
  background-color: transparent;
  font-weight: 600;
  border: none;
  width: 3rem;
  height: 48px;
  text-align: center;
}
@media only screen and (max-width: 600px) {
  div.vue-otp-input > div.otp-wrapper > input.defualt-input-group {
    width: 2.5rem;
    height: 40px;
  }
}
div.vue-otp-input > div.otp-wrapper > input.defualt-active-input {
  border: 1px solid #D0D5DD;
}
div.vue-otp-input > div.defualt-active-wrapper {
  border: 1px solid #D0D5DD;
}
div.vue-otp-input > span.default-error-class {
  color: #101828;
  font-weight: bold;
}
input:focus {
  outline: none;
}
/* removing the arrow keys on side of the input area */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
</style>

