<template>
  <dashboard-layout>
    <div class="dashboard-body-wrapper align-center">
      <div class="kyc-nav-wrapper margin-vertical margin-large">
        <router-link to="/identity-verification">
          <a class="w-inline-block"><img src="@/assets/images/Navigation-controls.svg" loading="lazy" alt=""></a>
        </router-link>
      </div>
      <AccountKycForm/>
    </div>
  </dashboard-layout>
</template>
<script>
	//@ is an alias to /src
	import AccountKycForm from '@/components/form/AccountKycForm.vue'
  import DashboardLayout from "@/layout/DashboardLayout";
	export default{
			name: 'AccountInformationView',
			components:{
        DashboardLayout,
				AccountKycForm
			}
	}
</script>
<style scoped>

</style>
