<!--suppress XmlDuplicatedId -->
<template>
		<section id="LoginForm" class="container-small">
      <div class="image-wrapper"><img src="@/assets/images/Group-22264.svg" loading="lazy" alt=""></div>
      <br/>
      <br/>
      <div class="form-wrapper">
              <div class="form-header-wrapper">
                <div class="margin-top margin-xlarge">
                  <h3>Log In</h3>
                </div>
                <div class="margin-top margin-xsmall">
                  <p class="text-weight-medium text-color-grey">Please provide us the email you used to create your Rubies account</p>
                </div>
              </div>

              <form class="form-body-wrapper" @submit.prevent="login" >
                <div class="margin-top margin-large">
                  <div class="form-fields-wrapper">
                    <div class="margin-top">
                      <input type="email" v-model="model.username" class="input-field w-input" maxlength="256" name="field" data-name="Email" placeholder="Enter Interac Email Address " id="Email" required="required">
                      <br/>

<!--                      <input type="password" v-model="model.password" class="input-field w-input" maxlength="256" name="Password" data-name="Password" placeholder="Enter Password" id="Password" required="required">-->

                      <div class="has-addons">
                        <input
                            v-if="showPassword"
                            v-model="model.password"
                            type="text"
                            class="input-field-2 w-input"
                            maxlength="256"
                            name="Password"
                            data-name="Password"
                            placeholder="Enter
                            Password"
                            id="Password"
                            required="required"
                        />
                        <input
                            v-else
                            type="password"
                            v-model="model.password"
                            class="input-field-2 w-input"
                            maxlength="256"
                            name="Password"
                            data-name="Password"
                            placeholder="Enter Password"
                            id="Password"
                            required="required"
                        />

                       <div class="space">
                         <i class="fas" :class="{ 'fa-eye-slash': showPassword, 'fa-eye': !showPassword }" @click="toggleShow"></i>
                       </div>
                      </div>


                    </div>
                    <div class="margin-top margin-xsmall text-align-right">
                      <router-link to="/forgot-password">
                        <a class="forgot-passcode red-text">Forgot Password?</a>
                      </router-link>
                    </div>
                  </div>
                </div>
                <div class="margin-top margin-large">
                  <div class="form-fields-wrapper">
                    <div class="margin-top">
                      <div class="margin-bottom margin-small">
<!--                        <button class="button max-width-full w-button" >Proceed</button>-->
                        <base-button :loading="loading">Proceed</base-button>
                      </div>
                      <router-link to="/sign-up-personal-details">
                        <a class="button is-secondary w-button">Don’t have an account? <strong class="text-weight-bold red-text">Sign up</strong></a>
                      </router-link>
                    </div>
                  </div>
                </div>
              </form>

            </div>
    </section>
</template>
<script>
import AuthenticationRequest from "@/model/request/AuthenticationRequest";
import StoreUtils from "@/util/baseUtils/StoreUtils";
import {mapState} from "vuex"
import BaseButton from "@/components/button/BaseButton";

	export default {
	name: "LoginForm",
    components: {BaseButton},
    data(){
      return {
        model: new AuthenticationRequest().login,
        showPassword: false,
      }
    },
    methods:{
      login(){
        StoreUtils.dispatch(StoreUtils.actions.auth.login, this.model)
      },
      toggleShow() {
        this.showPassword = !this.showPassword;
      },
    },
    computed:{
      ...mapState({
        loading: state => state.auth.loading,
        screen: state => state.auth.screen,
        auth: state => state.auth
      }),
      buttonLabel() {
        return (this.showPassword) ? "Hide" : "Show";
      }
    }
	};
</script>

<style scoped>
.has-addons{
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
}
button{
  background-color: transparent;
}

.fas{
  font-size: 13px;
  margin-top: 10%;
}

.space{
  height: 50px;
  margin-top: 0.6rem;
  margin-bottom: 0;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 5px;
  padding-right: 5px;
  border: 1px solid #d0d5dd;
  border-left-style: none;
  border-radius: 0 8px 8px 0;
  font-size: 1rem;
}


</style>

