<!--suppress XmlDuplicatedId -->
<template>
  <dashboard-layout>
    <div class="dashboard-body-wrapper align-center">
      <KycBackCard/>

      <AddressKycForm/>

      <div v-if="conditionRend.length > 0">

        <form class="margin-top margin-large" v-if="this.reaByCustomerId.data[0].addressStatus === 'VERIFIED'|| this.reaByCustomerId.data[0].addressStatus === 'PROCESSING'"  @submit.prevent="addressCreate" >
          <div class="kyc-form-wrapper" >

            <input id="field" name="field" data-name="Field" class="input-field select-field w-select" required="required" v-model="model.addressCountry"  disabled />

            <select id="field-2" v-model="model.addressCity" :disabled="model === this.reaByCustomerId.data[0]" name="field-2" data-name="Field 2" placeholder="Select Region" class="input-field select-field w-select" required="required">
              <option :value="null" disabled>Select province</option>
              <option :value="province.province" v-for="province in this.provinces.data" v-bind:key="province.province">{{ province.province }}</option>
            </select>
            <select id="field-2" v-model="model.addressState" :disabled="model === this.reaByCustomerId.data[0]" name="field-2" data-name="Field 2" placeholder="Select Province" class="input-field select-field w-select" required="required">
              <option :value="null" disabled>Select city</option>
              <option :value="city" v-for="city in this.filteredProvince.city" v-bind:key="city">{{ city }}</option>
            </select>
            <textarea rows="3" v-model="model.addressStreet" :disabled="model === this.reaByCustomerId.data[0]" class="input-field w-input" maxlength="256" name="field-3" data-name="Field 3" placeholder="Enter Address" id="field-3" required="required" aria-required="required"></textarea>
          </div>
          <div class="margin-top margin-medium">
            <base-button :loading="loading" :disabled="model === this.reaByCustomerId.data[0]">Proceed</base-button>
          </div>
        </form>




        <form class="margin-top margin-large" v-else-if="this.reaByCustomerId.data[0].addressStatus === 'PENDING'|| this.reaByCustomerId.data[0].addressStatus === 'FAILED'" @submit.prevent="addressCreate" >
          <div class="kyc-form-wrapper" >

            <input id="field" name="field" data-name="Field" class="input-field select-field w-select" required="required" v-model="model.addressCountry" disabled />

            <select id="field-2" v-model="model.addressCity"  name="field-2" data-name="Field 2" placeholder="Select Region" class="input-field select-field w-select" required="required">
              <option :value="null" disabled>Select province</option>
              <option :value="province.province" v-for="province in this.provinces.data" v-bind:key="province.province">{{ province.province }}</option>
            </select>
            <select id="field-2" v-model="model.addressState"  name="field-2" data-name="Field 2" placeholder="Select Province" class="input-field select-field w-select" required="required">
              <option :value="null" disabled>Select city</option>
              <option :value="city" v-for="city in this.filteredProvince.city" v-bind:key="city">{{ city }}</option>
            </select>
            <textarea rows="3" v-model="model.addressStreet"  class="input-field w-input" maxlength="256" name="field-3" data-name="Field 3" placeholder="Enter Address" id="field-3" required="required" aria-required="required"></textarea>
          </div>
          <div class="margin-top margin-medium">
            <base-button :loading="loading">Proceed</base-button>
          </div>
        </form>
      </div>


      <div v-else>
        <form class="margin-top margin-large" @submit.prevent="addressCreate" >
          <div class="kyc-form-wrapper" >

            <input id="field" name="field" data-name="Field" class="input-field select-field w-select" required="required" v-model="model.addressCountry"  disabled />

            <select id="field-2" v-model="model.addressCity"  name="field-2" data-name="Field 2" placeholder="Select Region" class="input-field select-field w-select" required="required">
              <option :value="null" disabled>Select province</option>
              <option :value="province.province" v-for="province in this.provinces.data" v-bind:key="province.province">{{ province.province }}</option>
            </select>
            <select id="field-2" v-model="model.addressState"  name="field-2" data-name="Field 2" placeholder="Select Province" class="input-field select-field w-select" required="required">
              <option :value="null" disabled>Select city</option>
              <option :value="city" v-for="city in this.filteredProvince.city" v-bind:key="city">{{ city }}</option>
            </select>
            <textarea rows="3" v-model="model.addressStreet"  class="input-field w-input" maxlength="256" name="field-3" data-name="Field 3" placeholder="Enter Address" id="field-3" required="required" aria-required="required"></textarea>
          </div>
          <div class="margin-top margin-medium">
            <base-button :loading="loading">Proceed</base-button>
          </div>
        </form>
      </div>


    </div>
  </dashboard-layout>
</template>
<script>
	//@ is an alias to /src
	import KycBackCard from '@/components/card/KycBackCard.vue'
	import AddressKycForm from '@/components/form/AddressKycForm.vue'
  import StoreUtils from "@/util/baseUtils/StoreUtils";
  import {mapState} from "vuex";
  import KycAddressRequest from "@/model/request/KycAddressRequest";
  import BaseButton from "@/components/button/BaseButton";
  import DashboardLayout from "@/layout/DashboardLayout";
	export default{
			name: 'AddressVerificationView',
			components:{
        DashboardLayout,
        BaseButton,
				KycBackCard,
				AddressKycForm
			},
    data(){
      return {
        model: new KycAddressRequest().create,
      }
    },
    methods:{
      addressCreate(){
        StoreUtils.dispatch(StoreUtils.actions.address.addressCreate, {
          // addressUserId : "102",
          addressNumber : "5",
          addressStreet : this.model.addressStreet,
          addressCity : this.model.addressCity,
          addressState : this.model.addressState,
          addressCountry : "Canada",
          addressStatus : "Active",
          addressCustomerId: this.userInfo.customerId
        })
      }
    },
    computed:{
      ...mapState({
        loading: state => state.address.loading,
        auth: state => state.auth,
        userInfo: state => state.auth.userInfo,
        address: state => state.address.address,
        addresses: state => state.address.addresses,
        reaByCustomerId: state => state.address.reaByCustomerId,
        conditionRend: state => state.address.reaByCustomerId.data,
      }),
      provinces() {
        return StoreUtils.rootGetters(StoreUtils.getters.address.getProvinces)
      },

      // reaByCustomerId() {
      //   return StoreUtils.rootGetters(StoreUtils.getters.address.getReaByCustomerId)
      // },

      filteredProvince(){
        if (this.provinces.data == null) return {}
        let province = this.provinces.data.filter(it=>it.province === this.model.addressCity)
        return province.length > 0?province[0]:{}
      },
    },

    async mounted() {
      await StoreUtils.dispatch(StoreUtils.actions.address.addressFetchProvinces)
      await StoreUtils.dispatch(StoreUtils.actions.address.addressReaByCustomerId, {
        addressCustomerId : this.userInfo.customerId
      })
      if (this.reaByCustomerId.data.length > 0) this.model = this.reaByCustomerId.data[0]
    }
  }
</script>
<style scoped>


</style>
