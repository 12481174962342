<template>
		<section id="ReviewCard" class="dashboard-section-wrapper">
      <div class="dashboard-section-header margin-bottom margin-small align-center">

        <div style="text-align: center; color: #101828" class="text-style-allcaps">for enquires contact us
          <a style="color: #db657b" class="text-style-allcaps" href="mailto:support@rubieswire.com">support@rubieswire.com</a>
        </div>

      </div>
            <div class="dashboard-section-header margin-bottom margin-small align-center">
              <div style="color: #101828" class="text-style-allcaps">how are you liking rubies?</div>
            </div>
            <div class="feedback-options-wrapper">
              <img src="@/assets/images/Group-48095795.png" loading="lazy" width="100" alt="" class="feedback-option">
              <img src="@/assets/images/Group-48095797.png" loading="lazy" width="100" alt="" class="feedback-option">
              <img src="@/assets/images/Group-48095798.png" loading="lazy" width="100" alt="" class="feedback-option">
              <img src="@/assets/images/Group-48095800.png" loading="lazy" width="100" alt="" class="feedback-option">
            </div>
          </section>
</template>
<script>
	export default {
	name: "ReviewCard"
	};
</script>
<style scoped lang="scss">
   h3 {margin: 40px 0 0; }
   ul {list-style-type: none; padding: 0; }
   li {display: inline-block; margin: 0 10px; }
</style>

