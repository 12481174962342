import RouterUtils from "@/util/baseUtils/RouterUtils";
import AccountCreationErrorView from "@/views/AccountCreationErrorView";
import AccountCreationSuccessView from "@/views/AccountCreationSuccessView";
import ForgotPasswordVerifyEmailView from "@/views/ForgotPasswordVerifyEmailView";
import ForgotPasswordView from "@/views/ForgotPasswordView";
import Loginview from "@/views/Loginview";
import NewsletterSettingsView from "@/views/NewsletterSettingsView";
import SignUpCreatePasscodeView from "@/views/SignUpCreatePasscodeView";
import SignUpPersonalDetailsView from "@/views/SignUpPersonalDetailsView";
import SignUpVerifyPhoneNumberView from "@/views/SignUpVerifyPhoneNumberView";
import Signupview from "@/views/Signupview";
import ForgotPasswordResetPasscodeView from "@/views/ForgotPasswordResetPasscodeView";

export default [
    {
        path: RouterUtils.routes.auth.accountCreationError.path,
        name: RouterUtils.routes.auth.accountCreationError.name,
        meta: {layout: "home", authRequired: false},
        component: AccountCreationErrorView,
    },

    {
        path: RouterUtils.routes.auth.accountCreationSuccess.path,
        name: RouterUtils.routes.auth.accountCreationSuccess.name,
        meta: {layout: "home", authRequired: false},
        component: AccountCreationSuccessView,
    },
    {
        path: RouterUtils.routes.auth.forgotPasswordVerifyEmail.path,
        name: RouterUtils.routes.auth.forgotPasswordVerifyEmail.name,
        meta: {layout: "home", authRequired: false},
        component: ForgotPasswordVerifyEmailView,
    },

    {
        path: RouterUtils.routes.auth.forgotPasswordResetPasscode.path,
        name: RouterUtils.routes.auth.forgotPasswordResetPasscode.name,
        meta: {layout: "home", authRequired: false},
        component: ForgotPasswordResetPasscodeView,
    },

    {
        path: RouterUtils.routes.auth.forgotPassword.path,
        name: RouterUtils.routes.auth.forgotPassword.name,
        meta: {layout: "home", authRequired: false},
        component: ForgotPasswordView,
    },
    {
        path: RouterUtils.routes.auth.loginview.path,
        name: RouterUtils.routes.auth.loginview.name,
        meta: {layout: "home", authRequired: false},
        component: Loginview,
    },
    {
        path: RouterUtils.routes.auth.newsletterSettings.path,
        name: RouterUtils.routes.auth.newsletterSettings.name,
        meta: {layout: "home", authRequired: false},
        component: NewsletterSettingsView,
    },
    {
        path: RouterUtils.routes.auth.signUpCreatePasscode.path,
        name: RouterUtils.routes.auth.signUpCreatePasscode.name,
        meta: {layout: "home", authRequired: false},
        component: SignUpCreatePasscodeView,
    },

    {
        path: RouterUtils.routes.auth.signUpPersonalDetails.path,
        name: RouterUtils.routes.auth.signUpPersonalDetails.name,
        meta: {layout: "home", authRequired: false},
        component: SignUpPersonalDetailsView,
    },

    {
        path: RouterUtils.routes.auth.signUpVerifyPhoneNumber.path,
        name: RouterUtils.routes.auth.signUpVerifyPhoneNumber.name,
        meta: {layout: "home", authRequired: false},
        component: SignUpVerifyPhoneNumberView,
    },

    {
        path: RouterUtils.routes.auth.signupview.path,
        name: RouterUtils.routes.auth.signupview.name,
        meta: {layout: "home", authRequired: false},
        component: Signupview,
    },
];