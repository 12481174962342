import client  from "./BaseService";

export default {
    callAccountInformationCreateApi(payload) {
        return client.apiClient.post("account-information/create", payload);
    },
    callAccountInformationReadApi() {
        return client.apiClient.post("account-information/read", {});
    },
    callAccountInformationUpdateApi(payload) {
        return client.apiClient.post("account-information/update", payload);
    },
    callAccountInformationReadByApi(payload) {
        return client.apiClient.post("account-information/read-by-customer-id", payload);
    },
    callAccountInformationReadSourceOfIncomeApi() {
        return client.apiClient.post("account-information/read-source-of-income", {});
    },
    callAccountInformationUpdateStatusApi(payload) {
        return client.apiClient.post("account-information/update-status", payload);
    },
    callAccountInformationReadByCustomerIdApi(payload) {
      return client.apiClient.post("account-information/read-by-customer-id", payload)
    },
};