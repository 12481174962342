<template>
  <dashboard-layout>
    <div class="main-wrapper newsletter">
    <div class="login-form-wrapper">
      <SelfieCard/>
    </div>
    </div>
  </dashboard-layout>
</template>
<script>
	//@ is an alias to /src
	import SelfieCard from '@/components/card/SelfieCard.vue'
  import DashboardLayout from "@/layout/DashboardLayout";
	export default{
			name: 'TakeSelfieView',
			components:{
        DashboardLayout,
				SelfieCard
			}
	}
</script>
<style scoped>
</style>
