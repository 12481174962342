class AuthenticationRequest {
  login = {
    username: null,
    password: null,
  };

  initiateEnrollment = {
    customerCountryCode: "+1",
    customerCountry: "Canada ",
    customerEmail: null,
    customerFirstName: null,
    customerLastName: null,
    customerDob: null,
    customerGender: null,
    customerPhone: null,
    source: "WEB"
  };

  completeEnrollment = {
    customerOtp: null,
    customerPhone: null,
    countryCode: "+1",
    customerPassword: null,
    customerConfirmPassword: null,
    customerEmail: null,
    customerBusinessName: "new Gang LTD"
  };

  resendOtp = {
    customerEmail: null,
    countryCode: null,
    username: null
  };

  initiateResetPassword = {
    username: null,
    countryCode: "+1"
  };

  completeResetPassword = {
    customerEmail: null,
    customerPassword: null,
    customerPasswordConfirmation: null,
    customerOtp: null
  };

  changePassword = {
    username: null,
    customerPassword: null,
    customerPasswordConfirmation: null,
    customerOldPassword: null,
    countryCode: null
  };

  resetPin = {
    pin: null,
    pinConfirmation: null,
    otp: null
  };
}

export default AuthenticationRequest;
