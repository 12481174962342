<template>
  <div>
    <div class="backdrop"></div>
    <dialog open>
    <section>
      <div class="welcome-modal">
        <div class="modal-image-container"></div>
        <div class="margin-bottom margin-small">
          <div class="balance-heading-wrapper">
            <div class="left-block">
              <div class="text-block-57">Your accounts</div>
            </div>
            <div class="right-block">
              <a data-w-id="67fbd4d2-67b1-f382-36f8-ba9be3dc7334" @click="$emit('close')" class="w-inline-block"><img src="@/assets/images/close.svg" loading="lazy" alt=""></a>
            </div>
          </div>
        </div>
        <a href="#" class="link-block-2 w-inline-block">
          <div class="currency-card">
            <div class="left-block"><img src="@/assets/images/CanadaCA.svg" loading="lazy" alt="">
              <div class="balance-details">
                <div class="currency">Canadian Dollar</div>
                <div class="balance-text">&#36;{{ userInfo.accounts[0].accountBalance | formatAmount }}</div>
              </div>
            </div>
            <div @click="convertCAD" class="currency-switch">
              <div class="right-block" v-if="this.accountState === 'CAD'">
                <img src="@/assets/images/checked.svg" loading="lazy" alt="">
              </div>
              <div class="right-block" v-else>
                <img src="@/assets/images/Unchekced.svg" loading="lazy" alt="">
              </div>
            </div>
          </div>
        </a>
        <a href="#" class="link-block-2 w-inline-block">
          <div class="currency-card">
            <div class="left-block"><img src="@/assets/images/Nigeria-NG.svg" loading="lazy" alt="">
              <div class="balance-details">
                <div class="currency">Nigerian Naira</div>
                <div class="balance-text">&#8358;{{ userInfo.accounts[1].accountBalance | formatAmount }}</div>
              </div>
            </div>

            <div @click="convertNGN" class="currency-switch">
            <div class="right-block" v-if="this.accountState === 'NGN'">
              <img src="@/assets/images/checked.svg" loading="lazy" alt="">
            </div>
            <div class="right-block" v-else>
              <img src="@/assets/images/Unchekced.svg" loading="lazy" alt="">
            </div>
            </div>


          </div>
        </a>
      </div>
    </section>
    </dialog>
  </div>
</template>
<script>
	import {mapState} from "vuex";
  import StoreUtils from "@/util/baseUtils/StoreUtils";

  export default {
	name: "BalanceModalCard",
    emits: ['close'],
    data(){
      return {
        accountState1: "NGN",
        accountState2: "CAD"
      }
    },
    computed: {
      ...mapState({
        loading: state => state.address.loading,
        auth: state => state.auth,
        userInfo: state => state.auth.userInfo,
        accountState: state => state.auth.accountState
      }),
    },
    methods: {
    convertNGN(){
      StoreUtils.commit(StoreUtils.mutations.auth.updateAccountState, this.accountState1)
    },
      convertCAD(){
        StoreUtils.commit(StoreUtils.mutations.auth.updateAccountState, this.accountState2)
      }
    }
	};
</script>
<style scoped>
   h3 {margin: 40px 0 0; }
   ul {list-style-type: none; padding: 0; }
   li {display: inline-block; margin: 0 10px; }



   .backdrop {
     position: fixed;
     top: 0;
     left: 0;
     width: 100%;
     height: 100vh;
     z-index: 10;
     background-color: rgba(0, 0, 0, 0.7);
   }

   div{
     padding-bottom: 10px;
   }
   h3{
     font-family: GTEestiProDisplay-Regular,serif ;
     font-weight: bold;
   }
   p{
     font-family: GTEestiProDisplay-Regular,serif ;
   }
   dialog {
     position: fixed;
     top: 18vh;
     width: 32rem;
     height: 20rem;
     left: calc(50% - 13.5rem);
     margin: 0;
     /*box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);*/
     /*border-radius: 12px;*/
     /*padding: 1rem;*/
     background-color: transparent;
     /*color: black;*/
     z-index: 100;
     border: none;
     animation: modal 0.3s ease-out forwards;
   }


   .currency-switch{
     margin-top: 4%;
   }

   @keyframes modal {
     from {
       opacity: 0;
       transform: translateY(-50px) scale(0.9);
     }

     to {
       opacity: 1;
       transform: translateY(0) scale(1);
     }
   }
   @media (max-width: 500px) {
     dialog {
       top: 18vh;
       width: 25rem;
       height: 20rem;
       /*left: unset;*/
       left: calc(50% - 11.4rem);
     }
     h3{
       font-size: 20px;
     }
     p{
       font-size: unset;
     }
   }
</style>

