class FundTransferRequest {
    nameEnquiry={
        accountNumber: null,
        bankCode: null
    }
    fundTransfer={
        amount: null,
        bankName: null,
        bankCode: null,
        creditAccountName: null,
        creditAccountNumber: null,
        debitAccountName: null,
        debitAccountNumber: null,
        narration: null,
        sessionId: null,
        reference: null,
        saveBeneficiary: "NO",
        pin: null,
    }
    fundsTransferCADNGN={
        email: null,
        amount: null,
        rateReference: null,
        rateBase: null,
        reference: null,
        pin: null
    }
    fundsTransferNGNNaira={
        email: null,
        amount: null,
        bankcode: null,
        reference: null,
        creditAccountName: null,
        creditAccountNumber: null,
        pin: null
    }
}

export default FundTransferRequest