import RouterUtils from "@/util/baseUtils/RouterUtils";
import AccountInformationView from "@/views/AccountInformationView";
import AddressVerificationView from "@/views/AddressVerificationView";
import IdentityVerificationView from "@/views/IdentityVerificationView";
import KycVerificationView from "@/views/KycVerificationView";
import ReviewingKycIdView from "@/views/ReviewingKycIdView";
import ReviewingKycView from "@/views/ReviewingKycView";
import TakeSelfieView from "@/views/TakeSelfieView";
import UpdateKycStepView from "@/views/UpdateKycStepView";

export default [
    {
        path: RouterUtils.routes.kyc.accountInformation.path,
        name: RouterUtils.routes.kyc.accountInformation.name,
        meta: {layout: "home", authRequired: true},
        component: AccountInformationView,
    },
    {
        path: RouterUtils.routes.kyc.addressVerification.path,
        name: RouterUtils.routes.kyc.addressVerification.name,
        meta: {layout: "home", authRequired: true},
        component: AddressVerificationView,
    },
    {
        path: RouterUtils.routes.kyc.identityVerification.path,
        name: RouterUtils.routes.kyc.identityVerification.name,
        meta: {layout: "home", authRequired: true},
        component: IdentityVerificationView,
    },
    {
        path: RouterUtils.routes.kyc.kycVerification.path,
        name: RouterUtils.routes.kyc.kycVerification.name,
        meta: {layout: "home", authRequired: true},
        component: KycVerificationView,
    },
    {
        path: RouterUtils.routes.kyc.reviewingKycId.path,
        name: RouterUtils.routes.kyc.reviewingKycId.name,
        meta: {layout: "home", authRequired: true},
        component: ReviewingKycIdView,
    },

    {
        path: RouterUtils.routes.kyc.reviewingKyc.path,
        name: RouterUtils.routes.kyc.reviewingKyc.name,
        meta: {layout: "home", authRequired: true},
        component: ReviewingKycView,
    },
    {
        path: RouterUtils.routes.kyc.takeSelfie.path,
        name: RouterUtils.routes.kyc.takeSelfie.name,
        meta: {layout: "home", authRequired: true},
        component: TakeSelfieView,
    },
    {
        path: RouterUtils.routes.kyc.updateKycStep.path,
        name: RouterUtils.routes.kyc.updateKycStep.name,
        meta: {layout: "home", authRequired: true},
        component: UpdateKycStepView,
    },
]