<template>
  <dashboard-layout>
    <div class="dashboard-body-wrapper align-center">
      <div class="kyc-nav-wrapper margin-vertical margin-large">
        <router-link to="/send-money">
          <a class="w-inline-block"><img src="@/assets/images/Navigation-controls.svg" loading="lazy" alt=""></a>
        </router-link>
      </div>
      <SendMoneyForm/>
    </div>
  </dashboard-layout>
</template>
<script>
	//@ is an alias to /src
	import SendMoneyForm from '@/components/form/SendMoneyForm.vue'
  import DashboardLayout from "@/layout/DashboardLayout";
	export default{
			name: 'AddRecipientView',
			components:{
        DashboardLayout,
				SendMoneyForm
			}
	}
</script>
<style scoped>
</style>
