<template>
  <dashboard-layout>
    <div class="main-wrapper newsletter">
      <div class="login-form-wrapper newsletter">
        <UpdateKycIntroCard/>
      </div>
    </div>
  </dashboard-layout>
</template>
<script>
	//@ is an alias to /src
	import UpdateKycIntroCard from '@/components/card/UpdateKycIntroCard.vue'
  import DashboardLayout from "@/layout/DashboardLayout";
	export default{
			name: 'UpdateKycStepView',
			components:{
        DashboardLayout,
				UpdateKycIntroCard
			}
	}
</script>
<style scoped>
</style>
