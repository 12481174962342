import { render, staticRenderFns } from "./SelfieCard.vue?vue&type=template&id=474f3126&scoped=true&"
import script from "./SelfieCard.vue?vue&type=script&lang=js&"
export * from "./SelfieCard.vue?vue&type=script&lang=js&"
import style0 from "./SelfieCard.vue?vue&type=style&index=0&id=474f3126&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "474f3126",
  null
  
)

export default component.exports