<template>
  <dashboard-layout>
    <FundWalletCard/>
  </dashboard-layout>
</template>
<script>
	//@ is an alias to /src
	import FundWalletCard from '@/components/card/FundWalletCard.vue'
  import DashboardLayout from "@/layout/DashboardLayout";
	export default{
			name: 'FundWalletView',
			components:{
        DashboardLayout,
				FundWalletCard
			}
	}
</script>
<style scoped>
</style>
