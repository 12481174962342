import { render, staticRenderFns } from "./ReviewIdCard.vue?vue&type=template&id=6ec87f25&scoped=true&"
import script from "./ReviewIdCard.vue?vue&type=script&lang=js&"
export * from "./ReviewIdCard.vue?vue&type=script&lang=js&"
import style0 from "./ReviewIdCard.vue?vue&type=style&index=0&id=6ec87f25&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6ec87f25",
  null
  
)

export default component.exports