import Vue from 'vue'
import App from './App.vue'
import router from './router'
import './filters'
import store from './store/store'

import VueFormulate from '@braid/vue-formulate'

Vue.use(VueFormulate, {
  classes: {
    input: 'input-field w-input',
    label: '.formulate-input-label--[position]'
  }
})

import snsWebSdk from '@sumsub/websdk';

Vue.use(snsWebSdk)


import OtpInput from 'otp-input-vue2';

Vue.use(OtpInput)


new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
