<template>
  <auth-layout>
    <verify-otp-form/>
  </auth-layout>
</template>
<script>
	//@ is an alias to /src
	import VerifyOtpForm from '@/components/form/VerifyOtpForm.vue'
  import AuthLayout from "@/layout/AuthLayout";
	export default{
			name: 'SignUpVerifyPhoneNumberView',
			components:{
        AuthLayout,
				VerifyOtpForm
			}
	}
</script>
<style scoped>

</style>
