class KycAddressRequest {
    create = {
        // addressUserId : "102",
        addressNumber : "5",
        addressStreet : null,
        addressCity : null,
        addressState : null,
        addressCountry : "Canada",
        addressStatus : "Active"
    };

    update = {
        addressId : null,
        addressUserId : null,
        addressNumber : null,
        addressStreet : null,
        addressCity : null,
        addressState : null,
        addressCountry : null,
        addressStatus : null
    };

    readBy = {
        addressCustomerId :  null
    };

    updateStatus = {
        addressId : null,
        addressStatus : null
    };

    readByCustomerId = {
        addressCustomerId: null
    }

}

export default KycAddressRequest;