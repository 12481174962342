<template>
  <dashboard-layout>
    <div class="transaction-header-wrapper">
      <div data-animation="over-right" data-collapse="small" data-duration="400" data-easing="ease" data-easing2="ease" role="banner" class="navbar w-nav">
        <NavBar/>
      </div>
    </div>
    <div class="margin-top margin-medium">
      <div class="notification-wrapper">
        <div class="margin-bottom margin-small">
          <h3>Notifications</h3>
        </div>
        <div class="notification-list-wrapper">
          <NotificationListCard/>
          <div class="transaction-list">
            <div class="margin-bottom margin-medium">
              <div class="text-block-74">15 September 2022</div>
            </div>
            <div class="transaction-list-content">
              <a href="#" class="notifications last w-inline-block">
                <div class="notification-details"><img src="@/assets/images/outward.svg" loading="lazy" alt="">
                  <div class="name-status-wrapper">
                    <div class="notification-text">Your transfer of NGN 4,400.00 to EZEKIEL JEREMIAH IBANGA has been processed successfully</div>
                    <div class="notification-date">8:33 PM</div>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>
        <EmotyStateCard/>
      </div>
    </div>
  </dashboard-layout>
</template>
<script>
	//@ is an alias to /src
	import NavBar from '@/components/bar/NavBar.vue'
	import NotificationListCard from '@/components/card/NotificationListCard.vue'
	import EmotyStateCard from '@/components/card/EmotyStateCard.vue'
  import DashboardLayout from "@/layout/DashboardLayout";
	export default{
			name: 'NotificationsView',
			components:{
        DashboardLayout,
				NavBar,
				NotificationListCard,
				EmotyStateCard
			}
	}
</script>
<style scoped>
</style>
