class FundTransferResponse {
    nameEnquiry={
    exceptionThrown: null,
    hasResult: null,
    message: null,
    responseCode: null,
    result: {
        accountName: null,
        accountNumber: null,
        bankCode: null,
        bankVerificationNumber: null,
        channelCode: null,
        kycLevel: null,
        sessionId: null
    },
    resultType: null,
    successful: null
    }


    fundTransfer={
        sessionId:null,
        amount:null,
        contractReference:null,
        creditAccount:null,
        creditAccountName:null,
        customerId:null,
        debitAccountNumber:null,
        narration:null,
        reference:null,
        requestDatetime:null,
        responseDatetime:null,
        responseMessage:null,
        responseCode:null,
    }


    fundsTransferCADNGN={
        responseCode: null,
        responseMessage: null,
        cbaReference: null,
        reference: null,
        trnAmount: null,
        debitAccountNumber: null,
        creditAccountNumber: null,
        trnNarration: null,
        transactionCode: null,
        debitAccountCustomerId: null,
        creditAccountBankCode: null,
        creditAccountBankName: null
    }

}

export default FundTransferResponse