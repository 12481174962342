<template>
  <auth-layout>
    <verify-email-form/>
  </auth-layout>
</template>
<script>
	//@ is an alias to /src
	import VerifyEmailForm from '@/components/form/VerifyEmailForm.vue'
  import AuthLayout from "@/layout/AuthLayout";
	export default{
			name: 'ForgotPasswordVerifyEmailView',
			components:{
        AuthLayout,
				VerifyEmailForm
			}
	}
</script>
<style scoped>
</style>
