<template>
  <section id="SetupCard" class="dashboard-section-wrapper">
    <div class="margin-bottom margin-medium">
              <div class="margin-bottom margin-small">
                <div v-if =" this.userInfo.customerKycStatus === 'SUCCESS' && this.userInfo.accounts[0].accountBalance > 0 && this.userInfo.isPinSet === 'YES'" class="dashboard-section-header">
                <div class="text-style-allcaps">Complete setup (3/3)</div>
                <div class="kyc-progress-bar-full">
                  <div class="progress-indicator"></div>
                </div>
              </div>

                <div v-else-if = " this.userInfo.accounts[0].accountBalance > 0 && this.userInfo.isPinSet === 'YES'" class="dashboard-section-header">
                <div class="text-style-allcaps">Complete setup (2/3)</div>
                <div class="kyc-progress-bar-fifty">
                  <div class="progress-indicator"></div>
                </div>
              </div>

                <div v-else-if = " this.userInfo.isPinSet === 'YES' && this.userInfo.customerKycStatus === 'SUCCESS'" class="dashboard-section-header">
                  <div class="text-style-allcaps">Complete setup (2/3)</div>
                  <div class="kyc-progress-bar-fifty">
                    <div class="progress-indicator"></div>
                  </div>
                </div>

                <div v-else-if = "this.userInfo.customerKycStatus === 'SUCCESS' && this.userInfo.accounts[0].accountBalance > 0" class="dashboard-section-header">
                  <div class="text-style-allcaps">Complete setup (2/3)</div>
                  <div class="kyc-progress-bar-fifty">
                    <div class="progress-indicator"></div>
                  </div>
                </div>

                <div v-else-if ="this.userInfo.customerKycStatus === 'SUCCESS' || this.userInfo.accounts[0].accountBalance > 0 || this.userInfo.isPinSet === 'YES'" class="dashboard-section-header">
                  <div class="text-style-allcaps">Complete setup (1/3)</div>
                  <div class="kyc-progress-bar-twenty-five">
                    <div class="progress-indicator"></div>
                  </div>
                </div>

                <div v-else class="dashboard-section-header">
                  <div class="text-style-allcaps">Complete setup (0/3)</div>
                  <div class="kyc-progress-bar">
                    <div class="progress-indicator"></div>
                  </div>
                </div>

              </div>

              <div class="kyc-wrapper">


                <router-link to="/update-kyc-step">
                  <a class="setup-link w-inline-block">
                    <div class="setup-title-wrapper"><img src="@/assets/images/KYC.svg" loading="lazy" alt="">
                      <div class="setup-title">
                        <div class="text-block-50">Update KYC</div>
                        <div class="text-block-51">Complete your verification process</div>
                      </div>
                    </div>
<!--                    <div class="checkmark active"><img src="@/assets/images/Component-1.svg" loading="lazy" alt=""></div>-->
                    <div class="checkmark active" v-if="this.userInfo.customerKycStatus === 'SUCCESS'"><img src="@/assets/images/Component-1.svg" loading="lazy" alt=""></div>
                    <div class="checkmark pending" v-else-if="this.userInfo.customerKycStatus === 'PENDING'"><img src="@/assets/images/Component-1.svg" loading="lazy" alt=""></div>
                    <div class="checkmark failed" v-else-if="this.userInfo.customerKycStatus === 'FAILED'"><img src="@/assets/images/Component-1.svg" loading="lazy" alt=""></div>
                    <div class="checkmark" v-else ></div>
                  </a>
                </router-link>
                <div class="divider"></div>
                <router-link to="/login-and-security">
                  <a class="setup-link w-inline-block">
                    <div class="setup-title-wrapper">
                      <i class='bx bx-lock'></i>
                      <div class="setup-title">
                        <div class="text-block-50">Set Pin</div>
                        <div class="text-block-51">Setup your pin to start transacting</div>
                      </div>
                    </div>
                    <!--                    <div class="checkmark active"><img src="@/assets/images/Component-1.svg" loading="lazy" alt=""></div>-->
                    <div class="checkmark active" v-if="this.userInfo.isPinSet === 'YES'"><img src="@/assets/images/Component-1.svg" loading="lazy" alt=""></div>
                    <div class="checkmark" v-else></div>
                  </a>
                </router-link>

                <div class="divider"></div>

                <router-link to="/fund-wallet">
                  <a class="setup-link w-inline-block">
                    <div class="setup-title-wrapper"><img src="@/assets/images/Group-48095688.png" loading="lazy" width="45" alt="">
                      <div class="setup-title">
                        <div class="text-block-50">Fund your account</div>
                        <div class="text-block-51">Top up your account to start transacting</div>
                      </div>
                    </div>
                    <div class="checkmark active" v-if="this.userInfo.accounts[0].accountBalance > 0"><img src="@/assets/images/Component-1.svg" loading="lazy" alt=""></div>
                    <div class="checkmark" v-else></div>
                  </a>
                </router-link>
              </div>

            </div>
  </section>
</template>
<script>
	import {mapState} from "vuex";
  import StoreUtils from "@/util/baseUtils/StoreUtils";

  export default {
	name: "SetupCard",
    computed:{
      ...mapState({
        loading: state => state.address.loading,
        auth: state => state.auth,
        userInfo: state => state.auth.userInfo,
        address: state => state.address.address,
        reaByCustomerId: state => state.address.reaByCustomerId,
        conditionRend: state => state.address.reaByCustomerId.data,
      }),

    },

    async created() {
    await StoreUtils.dispatch(StoreUtils.actions.auth.getUserDetails)
      await StoreUtils.dispatch(StoreUtils.actions.address.addressReaByCustomerId, {
        addressCustomerId : this.userInfo.customerId
      })
    },
  };
</script>
<style scoped lang="scss">
   h3 {margin: 40px 0 0; }
   ul {list-style-type: none; padding: 0; }
   li {display: inline-block; margin: 0 10px; }

   .bx-lock{
     color: #DCDCDC;
     font-size: 39px;
     padding-right: 4px;
   }
</style>

