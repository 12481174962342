import RouterUtils from "@/util/baseUtils/RouterUtils";
import AddRecipientView from "@/views/AddRecipientView";
import ConvertAmountView from "@/views/ConvertAmountView";
import FundWalletView from "@/views/FundWalletView";
import HomeView from "@/views/HomeView";
import InteracFundingView from "@/views/InteracFundingView";
import MyProfileView from "@/views/MyProfileView";
import NotificationsView from "@/views/NotificationsView";
import ReferalHomeView from "@/views/ReferalHomeView";
import SendMoneyView from "@/views/SendMoneyView";
import SettingsView from "@/views/SettingsView";
import LoginAndSecurity from "@/views/LoginAndSecurity";
import TransactionView from "@/views/TransactionView";
import TransferAmountView from "@/views/TransferAmountView";

// remember to change the authrequired of the dashboard routes

export default [
    {
        path: RouterUtils.routes.dashboard.addRecipient.path,
        name: RouterUtils.routes.dashboard.addRecipient.name,
        meta: {layout: "home", authRequired: true},
        component: AddRecipientView,
    },

    {
        path: RouterUtils.routes.dashboard.convertAmount.path,
        name: RouterUtils.routes.dashboard.convertAmount.name,
        meta: {layout: "home", authRequired: true},
        component: ConvertAmountView,
    },

    {
        path: RouterUtils.routes.dashboard.fundWallet.path,
        name: RouterUtils.routes.dashboard.fundWallet.name,
        meta: {layout: "home", authRequired: true},
        component: FundWalletView,
    },
    //take note
    {
        path: RouterUtils.routes.dashboard.homeView.path,
        name: RouterUtils.routes.dashboard.homeView.name,
        meta: {layout: "home", authRequired: true},
        component: HomeView,
    },

    {
        path: RouterUtils.routes.dashboard.interacFunding.path,
        name: RouterUtils.routes.dashboard.interacFunding.name,
        meta: {layout: "home", authRequired: true},
        component: InteracFundingView,
    },

    {
        path: RouterUtils.routes.dashboard.myProfile.path,
        name: RouterUtils.routes.dashboard.myProfile.name,
        meta: {layout: "home", authRequired: true},
        component: MyProfileView,
    },
    {
        path: RouterUtils.routes.dashboard.notifications.path,
        name: RouterUtils.routes.dashboard.notifications.name,
        meta: {layout: "home", authRequired: true},
        component: NotificationsView,
    },
    {
        path: RouterUtils.routes.dashboard.referalHome.path,
        name: RouterUtils.routes.dashboard.referalHome.name,
        meta: {layout: "home", authRequired: true},
        component: ReferalHomeView,
    },
    {
        path: RouterUtils.routes.dashboard.sendMoney.path,
        name: RouterUtils.routes.dashboard.sendMoney.name,
        meta: {layout: "home", authRequired: true},
        component: SendMoneyView,
    },
    {
        path: RouterUtils.routes.dashboard.settings.path,
        name: RouterUtils.routes.dashboard.settings.name,
        meta: {layout: "home", authRequired: true},
        component: SettingsView,
    },
    {
        path: RouterUtils.routes.dashboard.loginAndSecurity.path,
        name: RouterUtils.routes.dashboard.loginAndSecurity.name,
        meta: {layout: "home", authRequired: true},
        component: LoginAndSecurity,
    },
    {
        path: RouterUtils.routes.dashboard.transaction.path,
        name: RouterUtils.routes.dashboard.transaction.name,
        meta: {layout: "home", authRequired: true},
        component: TransactionView,
    },
    {
        path: RouterUtils.routes.dashboard.transferAmount.path,
        name: RouterUtils.routes.dashboard.transferAmount.name,
        meta: {layout: "home", authRequired: true},
        component: TransferAmountView,
    },
]