<template>
  <dashboard-layout>
    <HomeWrapperCard/>
    <IntroMessageModal @close="hideDialog" v-if="dialogIsVisible"/>
    <div class="lawrence">
      <actions-card/>
      <br/>
      <br/>
      <setup-card/>
      <transactions-card/>
      <referral-card/>
      <review-card/>
    </div>
  </dashboard-layout>
</template>
<script>
	// @ is an alias to /src
	import IntroMessageModal from '@/components/modal/IntroMessageModal.vue'
	import HomeWrapperCard from '@/components/card/HomeWrapperCard.vue'
  import ActionsCard from "@/components/card/ActionsCard";
  import ReviewCard from "@/components/card/ReviewCard";
  import ReferralCard from "@/components/card/ReferralCard";
  import SetupCard from "@/components/card/SetupCard";
  import TransactionsCard from "@/components/card/TransactionsCard";
  import {mapState} from "vuex";
  import DashboardLayout from "@/layout/DashboardLayout";
  // import StoreUtils from "@/util/baseUtils/StoreUtils";
	export default{
			name: 'HomeView',
			components:{
        DashboardLayout,
        TransactionsCard,
        SetupCard,
        ReferralCard,
        ReviewCard,
        ActionsCard,
				IntroMessageModal,
				HomeWrapperCard,
			},
    computed:{
      ...mapState({
        loading: state => state.auth.loading,
        screen: state => state.auth.screen,
        auth: state => state.auth
      }),
    },
    data() {
      return {
        dialogIsVisible: false,
      };
    },

    methods: {
      hideDialog() {
        this.dialogIsVisible = false;
      },
      showDialog() {
        this.dialogIsVisible = true;
      },
    },

  }
</script>
<style scoped>

.lawrence{
  margin-left: 33%;
  margin-right: 33%;
}
@media screen and (max-width: 1286px) {
  .lawrence{
    margin-left: 20%;
    margin-right: 20%;
  }
}
@media screen and (max-width: 990px) {
  .lawrence{
    margin-left: 20%;
    margin-right: 20%;
  }
}
@media screen and (max-width: 767px){
  .lawrence{
    margin-left: 6%;
    margin-right: 6%;
  }
}

</style>
