<!--suppress ALL -->
<template>
  <section id="CreatePasscodeForm" class="container-small">
    <div class="image-wrapper"><img src="@/assets/images/Group-22264.svg" loading="lazy" alt=""></div>
    <br/>
    <div class="form-wrapper">
      <div >
        <div class="margin-top margin-xlarge">
          <h3>Reset Password</h3>
        </div>
        <div class="margin-top margin-xsmall">
          <p class="text-weight-medium text-color-grey">You’ll be able to Reset and log in to Rubies using <br>the following Password</p>
        </div>
      </div>
      <form class="form-body-wrapper" @submit.prevent="completeResetPassword" >

        <div class="margin-top margin-large">

<!--          <input type="password" v-model="model.customerPassword" class="input-field w-input" maxlength="256" name="field" data-name="Field" placeholder="Create Passcode" id="field" required="">-->
<!--          <input type="password" v-model="model.customerPasswordConfirmation" class="input-field w-input" maxlength="256" name="field-3" data-name="Field 3" placeholder="Confirm Passcode" id="field-3" required="">-->

          <div class="has-addons">
            <input @input="validatePassword" v-if="showPassword" v-model="model.customerPassword" type="text" class="input-field-2 w-input" maxlength="256" name="Password" data-name="Password" placeholder="Create Password" id="field" required="required" />
            <input @input="validatePassword" v-else type="password" v-model="model.customerPassword" class="input-field-2 w-input" maxlength="256" name="Password" data-name="Password" placeholder="Create Password" id="Password" required="required" >
            <div class="space">
              <i class="fas" :class="{ 'fa-eye-slash': showPassword, 'fa-eye': !showPassword }" @click="toggleShow"></i>
            </div>
          </div>

          <div class="has-addons">
            <input @input="validatePassword" v-if="showPassword" v-model="model.customerPasswordConfirmation" type="text" class="input-field-2 w-input" maxlength="256" name="Password" data-name="Password" placeholder="Confirm Password" id="field" required="required" />
            <input @input="validatePassword" v-else type="password" v-model="model.customerPasswordConfirmation" class="input-field-2 w-input" maxlength="256" name="Password" data-name="Password" placeholder="Confirm Password" id="Password" required="required" >
            <div class="space">
              <i class="fas" :class="{ 'fa-eye-slash': showPassword, 'fa-eye': !showPassword }" @click="toggleShow"></i>
            </div>
          </div>

          <div v-if="passwordErrors.length" class="error-messages">
            <ul>
              <li v-for="error in passwordErrors" :key="error">{{ error }}</li>
            </ul>
          </div>

          <br/>
          <br/>
          <base-button :loading="loading">Proceed</base-button>

        </div>
      </form>
    </div>
  </section>
</template>

<script>
import AuthenticationRequest from "@/model/request/AuthenticationRequest";
import StoreUtils from "@/util/baseUtils/StoreUtils";
import {mapState} from "vuex";
import BaseButton from "@/components/button/BaseButton";
import swal from "sweetalert";

export default {
  name: "ResetPasscodeForm",
  components: {BaseButton},
  data(){
    return {
      model: new AuthenticationRequest().completeResetPassword,
      showPassword: false,
      passwordErrors: [],
    }
  },
  methods:{
    completeResetPassword(event){
      this.validatePassword();

      if (this.passwordErrors.length > 0) {
        event.preventDefault(); // Prevent form submission
        swal('Error', 'Please fix the errors with the password before submitting again.', 'error')
      } else {
        StoreUtils.dispatch(StoreUtils.actions.auth.completePasswordReset, {
          customerEmail: this.signInFormData.customerEmail,
          customerPassword: this.model.customerPassword,
          customerPasswordConfirmation: this.model.customerPasswordConfirmation,
          customerOtp: this.signInFormData.customerOtp,
          countryCode: "+234",
          username: this.signInFormData.customerEmail
        })
      }

    },
    toggleShow() {
      this.showPassword = !this.showPassword;
    },
    validatePassword() {
      this.passwordErrors = [];

      if ( this.model.customerPassword && this.model.customerPasswordConfirmation === '') {
        this.passwordErrors.push("Password is required.");
      }

      if (!/^(?=.*[A-Z])(?=.*\d)(?=.*[@$!&])[A-Za-z\d@$!&]{8,15}$/.test(this.model.customerPassword && this.model.customerPasswordConfirmation)) {
        this.passwordErrors.push("Password must meet the following criteria:");
        this.passwordErrors.push("- It should be alphanumeric.");
        this.passwordErrors.push("- The first letter should be capitalized.");
        this.passwordErrors.push("- It must contain a special character (@, $, !, & etc).");
        this.passwordErrors.push("- It should be at least 8 characters long.");
      }
    },
  },
  computed:{
    ...mapState({
      loading: state => state.auth.loading,
      screen: state => state.auth.screen,
      auth: state => state.auth
    }),
    signInFormData() {
      return StoreUtils.rootGetters(StoreUtils.getters.auth.getSignInFormData)
    },
    buttonLabel() {
      return (this.showPassword) ? "Hide" : "Show";
    }
  }
}
</script>

<style scoped>
.has-addons{
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
}
button{
  background-color: transparent;
}

.fas{
  font-size: 13px;
  margin-top: 10%;
}

.space{
  height: 50px;
  margin-top: 0.6rem;
  margin-bottom: 0;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 5px;
  padding-right: 5px;
  border: 1px solid #d0d5dd;
  border-left-style: none;
  border-radius: 0 8px 8px 0;
  font-size: 1rem;
}

.error-messages {
  color: #db657b;
  font-size: 12px;
  text-decoration: none;
  list-style: none;
}
</style>