class AccountInformationResponse {
    read = {
        responseCode: null,
        responseMessage: null,
        data:[]
    };


    readSourceOfIncome = {
        data: [],
        responseCode: null,
        responseMessage: null,
    };


    readBy = {
        responseCode: null,
        responseMessage: null,
        // data: {
        //     addressId: null,
        //     addressUserId: null,
        //     addressNumber: null,
        //     addressStreet: null,
        //     addressCity: null,
        //     addressState: null,
        //     addressCountry: null,
        //     addressStatus: null,
        //     addressCreatedAt: null,
        //     addressUpdatedAt: null
        // }
    };

    readByAccountInformationCustomerId = {
        responseCode: null,
        responseMessage: null,
        data: {
            accountInformationCustomerId: null,
            accountInformationSourceOfIncome: null,
            accountInformationMonthlyIncome: null,
            accountInformationStatus: null,
            accountInformationBvn: null
        }
    };



    data = {
        accountInformationCustomerId: null,
        accountInformationSourceOfIncome: null,
        accountInformationMonthlyIncome: null,
        accountInformationStatus: null,
        accountInformationBvn: null
    }



}

export default AccountInformationResponse;