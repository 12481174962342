<template>
		<section id="EmotyStateCard" class="empty-state"><img src="@/assets/images/Empty-Illustration.svg" loading="lazy" alt="">
              <div>This is where you&#x27;ll find messages and exciting new offers. Be sure to check back</div>
            </section>
</template>
<script>
	export default {
	name: "EmotyStateCard"
	};
</script>
<style scoped lang="scss">
   h3 {margin: 40px 0 0; }
   ul {list-style-type: none; padding: 0; }
   li {display: inline-block; margin: 0 10px; }
   a {color: #42b983;}
</style>

