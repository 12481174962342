class TransactionResponse {

    read = {
        responseCode: null,
        responseMessage: null,
        data: []
    };

    readBy = {
        responseCode: null,
        responseMessage: null,
        data: {
            transactionId: null,
            transactionRateId: null,
            transactionReference: null,
            transactionType: null,
            transactionBankName: null,
            transactionBankCode: null,
            transactionAmount: null,
            transactionBeneficiaryAmount: null,
            transactionBeneficiaryName: null,
            transactionCreatedAt: null,
            transactionUpdatedAt: null,
            transactionStatus: null
        }
    };

    readByTransactionCustomerId = {
        responseCode: null,
        responseMessage: null,
        dataTransactions: []
    };

    getBanks = {
        hasResult: null,
        responseMessage: null,
        responseCode: null,
        result: [],
        resultType: null,
        successful: null
    };

    readAllWalletTransaction = {
        responseCode: null,
        responseMessage: null,
        accounts: [],
        dataWalletTransaction: []
    }


    result = {
        id: null,
        name: null
    }

    dataTransactions = {
        transactionId: null,
        transactionRateId: null,
        transactionReference: null,
        transactionCustomerId: null,
        transactionType: null,
        transactionBankName: null,
        transactionBankCode: null,
        transactionAmount: null,
        transactionBeneficiaryAmount: null,
        transactionBeneficiaryName: null,
        transactionCreatedAt: null,
        transactionUpdatedAt: null,
        transactionStatus: null
    }

    data = {
        transactionId: null,
        transactionRateId: null,
        transactionReference: null,
        transactionType: null,
        transactionBankName: null,
        transactionBankCode: null,
        transactionAmount: null,
        transactionBeneficiaryAmount: null,
        transactionBeneficiaryName: null,
        transactionCreatedAt: null,
        transactionUpdatedAt: null,
        transactionStatus: null
    }

    accounts = {
        accountId: null,
        accountCustomerId: null,
        accountNumber: null,
        accountName:  null,
        accountType: null,
        accountClass: null,
        accountCurrency: null,
        accountBalance: null,
        accountLastAccessTimestamp: null,
        accountStatus: null,
        accountBankCode: null,
        accountBranchCode: null,
        accountCreatedDate: null,
        accountUpdatedDate: null,
        accountDateOpened: null,
        accountGlCategory: null,
        accountGlLevel1Code: null,
        accountGlLevel2Code: null,
        accountAllowNegativeBalance: null,
        accountLedgerBalance: null,
        accountMaker: null,
        accountAllowCredit: null,
        accountAllowDebit: null,
        accountPhone: null,
        accountChecker: null
    }

    dataWalletTransaction = {
        amount: null,
        counterPartyService: null,
        transactionDate: null,
        eventDate: null,
        counterPartyBankCode: null,
        contractReference: null,
        paymentReference: null,
        counterPartyAccountName: null,
        drCr: null,
        counterPartyBank: null,
        counterPartyChannel: null,
        accountName: null,
        accountNumber: null,
        counterPartyAccountNumber: null,
        transactionType: null,
        id: null,
        narration: null
    }

}

export default TransactionResponse;