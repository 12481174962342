
<template>
  <button class="button2" :disabled="loading2">
    <!--    <svg  class="spinner" viewBox="...">-->
    <!--      <path d="..."/>-->
    <!--    </svg>-->
    <svg v-if="loading2" class="spinner" viewBox="0 0 50 50">
      <circle class="path" cx="25" cy="25" r="20" fill="none" stroke-width="5"></circle>
    </svg>
    <!--    <svg v-else  viewBox="0 0 20 20">-->
    <!--      <path d="..."/>-->
    <!--    </svg>-->
    <span v-if="!loading2">
      <slot/>
    </span>
  </button>
</template>

<script>
export default {
  props: {
    loading2: { type: Boolean }
  },
  name: "BaseButtonHalf"
}
</script>

<style scoped>

.button2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 49.2px;
  padding: 8px 1rem;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 0 8px 8px 0;
  background-color: #101828;
  font-family: Satoshi, sans-serif;
  color: #fff;
  font-weight: 500;
  text-align: center;
  margin-top: 10px;
}


.spinner {
  -webkit-animation: rotate 2s linear infinite;
  animation: rotate 2s linear infinite;
  z-index: 2;
  position: relative;
  top: 30%;
  left: 100%;
  margin: -20px 0 0 -20px;
  width: 25px;
  height: 25px;
}
.spinner .path {
  height: 100%;
  background-image: linear-gradient(-105deg, #ffffff, #ffffff);
  stroke: #ffffff;
  stroke-linecap: round;
  -webkit-animation: dash 1.5s ease-in-out infinite;
  animation: dash 1.5s ease-in-out infinite;
}

@-webkit-keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes dash {
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
  }
}
@keyframes dash {
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
  }
}
</style>