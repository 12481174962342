// import client from "@/service/BaseService";
import client  from "./BaseService";


export default {
    callNameEnquiryApi(payload) {
        return client.apiClient.post("bankly/name-enquiry", payload);
    },
    callFundTransferApi(payload) {
        return client.apiClient.post("virtual-account/fund-transfer", payload);
    },
    callFundsTransferCADNGNApi(payload) {
        return client.apiClient.post("callback/funds-transfer-cad-ngn", payload)
    },
    callFundsTransferNGNNairaApi(payload) {
        return client.apiClient.post("callback/funds-transfer-ngn-naira", payload)
    },
}