import client  from "./BaseService";

export default {
    callAddressCreateApi(payload) {
        return client.apiClient.post("address/create", payload);
    },
    callAddressReadApi() {
        return client.apiClient.post("address/read", {});
    },
    callAddressUpdateApi(payload) {
        return client.apiClient.post("address/update", payload);
    },
    callAddressReadByApi(payload) {
        return client.apiClient.post("address/read-by-address-id", payload);
    },
    callAddressUpdateStatusApi(payload) {
        return client.apiClient.post("address/update-status", payload);
    },
    callAddressReadCanadianProvincesApi() {
        return client.apiClient.post("address/read-canadian-provinces", {});
    },
    callReadByCustomerIdApi(payload) {
        return client.apiClient.post("address/read-by-customer-id", payload);
    }
};