<template>
  <dashboard-layout>
    <div class="main-wrapper">
<!--      <KycNav/>-->
<!--      <KycBannerCard/>-->
<!--      <KycStepsCard/>-->
      <div id="sumsub-websdk-container" class="top"></div>


        <div class="reduce" @click="back">
          <a class="button outline w-button">Back</a>
        </div>

    </div>
  </dashboard-layout>
</template>
<script>
	//@ is an alias to /src
	// import KycNav from '@/components/nav/KycNav.vue'
	// import KycBannerCard from '@/components/card/KycBannerCard.vue'
	// import KycStepsCard from '@/components/card/KycStepsCard.vue'
  import DashboardLayout from "@/layout/DashboardLayout";
  import snsWebSdk from "@sumsub/websdk";
  import {mapState} from "vuex";
  import StoreUtils from "@/util/baseUtils/StoreUtils";
  import RouterUtils from "@/util/baseUtils/RouterUtils";
	export default{
			name: 'KycVerificationView',
			components:{
        DashboardLayout,
				// KycNav,
				// KycBannerCard,
				// KycStepsCard
			},
    data() {
      return {
        accessToken: "",
      }
    },
    computed: {
      ...mapState({
        auth: state => state.auth,
        userInfo: state => state.auth.userInfo,
      }),
    },
    methods: {
      launchWebSdk(accessToken) {
        let snsWebSdkInstance = snsWebSdk
            .init(accessToken, this.getNewAccessToken)
            .withConf({
              lang: 'en',
              email: this.userInfo.customerEmail,
              phone: this.userInfo.customerPhone,
              i18n: {
                document: {
                  subTitles: {
                    IDENTITY: 'Upload a document that proves your identity',
                  },
                },
              },
              onMessage: (type, payload) => {
                console.log('WebSDK onMessage', type, payload);
              },
              uiConf: {
                customCssStr:
                    ':root {\n  --black: #000000;\n   --grey: #F5F5F5;\n  --grey-darker: #B2B2B2;\n  --border-color: #DBDBDB;\n}\n\np {\n  color: var(--black);\n  font-size: 16px;\n  line-height: 24px;\n}\n\nsection {\n  margin: 40px auto;\n}\n\ninput {\n  color: var(--black);\n  font-weight: 600;\n  outline: none;\n}\n\nsection.content {\n  background-color: var(--grey);\n  color: var(--black);\n  padding: 40px 40px 16px;\n  box-shadow: none;\n  border-radius: 6px;\n}\n\nbutton.submit,\nbutton.back {\n  text-transform: capitalize;\n  border-radius: 6px;\n  height: 48px;\n  padding: 0 30px;\n  font-size: 16px;\n  background-image: none !important;\n  transform: none !important;\n  box-shadow: none !important;\n  transition: all 0.2s linear;\n}\n\nbutton.submit {\n  min-width: 132px;\n  background: none;\n  background-color: var(--black);\n}\n\n.round-icon {\n  background-color: var(--black) !important;\n  background-image: none !important;\n}',
              },
              onError: (error) => {
                console.error('WebSDK onError', error);
              },
            })
            .withOptions({ addViewportTag: false, adaptIframeHeight: true })
            .on('idCheck.stepCompleted', (payload) => {
              console.log('stepCompleted', payload);
            })
            .on('idCheck.onError', (error) => {
              console.log('onError', error);
            })
            .onMessage((type, payload) => {
              console.log('onMessage', type, payload);
            })
            .build();
        snsWebSdkInstance.launch('#sumsub-websdk-container');
      },
      getNewAccessToken() {
        // Replace this with your implementation to retrieve a new access token
        return Promise.resolve('YOUR_NEW_ACCESS_TOKEN');
      },

      access() {
        this.accessToken = this.userInfo.sumSubAccessToken.toLocaleString();
      },

       back() {
         StoreUtils.dispatch(StoreUtils.actions.auth.getUserDetails)
         RouterUtils.navigateTo(RouterUtils.routes.dashboard.homeView.name)
      }

    },
    created() {
      this.access()
    },
    mounted() {
      this.launchWebSdk(this.accessToken);
    },
	}
</script>



<style scoped>
.main-wrapper {
  background-image: -webkit-gradient(linear, left top, left bottom, from(#1a1d27), color-stop(50%, #2f354b), to(#1a1d27));
  background-image: linear-gradient(180deg, #1a1d27, #2f354b 50%, #1a1d27);
  height: 200vh;
}

.reduce{
  margin-right: 35%;
  margin-left: 35%;
}

.top{
  padding-top: 3%;
}
</style>
