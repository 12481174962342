<template>
  <section id="SelfieCard" class="container-small">

<!--    <div v-if="identificationsRead.length > 0">-->
<!--      <div v-if="notUploaded && identificationsRead[0].identificationStatus === 'PENDING' || identificationsRead[0].identificationStatus === 'FAILED'">-->
<!--        <div class="image-wrapper" v-if="!isCameraOpen"><img src="@/assets/images/Camera.png" loading="lazy" width="161" alt=""></div>-->
<!--        <div class="form-wrapper">-->
<!--          <div class="form-header-wrapper" v-if="!isCameraOpen">-->
<!--            <div class="margin-top margin-small">-->
<!--              <h3 class="text-align-center text-color-white">We’ll need to access your camera before continuing.</h3>-->
<!--              <div class="margin-top margin-xsmall padding-horizontal padding-small">-->
<!--                <p class="newsletter-text text-color-white text-align-center">You’ll need to submit a selfie before continuing. The photos will be used to verify your identity.</p>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->

<!--          <div class="web-camera-container" v-else>-->

<!--            <div v-show="isCameraOpen && isLoading" class="camera-loading">-->
<!--              <ul class="loader-circle">-->
<!--                <li></li>-->
<!--                <li></li>-->
<!--                <li></li>-->
<!--              </ul>-->
<!--            </div>-->

<!--            <div v-if="isCameraOpen" v-show="!isLoading" class="camera-box" :class="{ 'flash' : isShotPhoto }">-->

<!--              <div class="camera-shutter" :class="{'flash' : isShotPhoto}"></div>-->

<!--              <video v-show="!isPhotoTaken" ref="camera" :width="410" :height="337.5" autoplay></video>-->

<!--              <canvas v-show="isPhotoTaken" id="photoTaken" ref="canvas" :width="410" :height="337.5"></canvas>-->
<!--            </div>-->

<!--            <div v-if="isCameraOpen && !isLoading" class="camera-shoot">-->
<!--              <button type="button" class="button" @click="takePhoto">-->
<!--                <img src="https://img.icons8.com/material-outlined/50/000000/camera&#45;&#45;v2.png">-->
<!--              </button>-->
<!--            </div>-->

<!--            &lt;!&ndash;                <div v-if="isPhotoTaken && isCameraOpen" class="camera-download">&ndash;&gt;-->
<!--            &lt;!&ndash;                  <a id="downloadPhoto" download="my-photo.jpg" class="button" role="button" @click="downloadImage">&ndash;&gt;-->
<!--            &lt;!&ndash;                    Download&ndash;&gt;-->
<!--            &lt;!&ndash;                  </a>&ndash;&gt;-->
<!--            &lt;!&ndash;                </div>&ndash;&gt;-->
<!--          </div>-->

<!--          <div class="form-body-wrapper">-->
<!--            <div class="margin-top margin-large">-->
<!--              <div class="form-fields-wrapper">-->
<!--                <div class="margin-top">-->
<!--                  <div class="margin-bottom margin-small">-->
<!--                    <button type="button" class="button white-button w-button" :class="{ 'is-primary' : !isCameraOpen, 'is-danger' : isCameraOpen}" @click="toggleCamera" v-if="!isPhotoTaken">-->
<!--                      <span v-if="!isCameraOpen">Allow permission</span>-->
<!--                      <span v-else>Close Camera</span>-->
<!--                    </button>-->
<!--                    <button type="button" class="button white-button w-button" v-if="isPhotoTaken" @click="uploadFile">-->
<!--                      <span v-if="!showLoader">Submit Photo</span>-->
<!--                      <span v-else>Loading...</span>-->
<!--                    </button>-->
<!--                    <router-link to="/identity-verification">-->
<!--                      <a class="button outline w-button">Back to verification home</a>-->
<!--                    </router-link>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->

<!--        </div>-->
<!--      </div>-->

<!--      <div class="margin-top margin-small text-block-501" v-if="!notUploaded && identificationsRead[0].identificationStatus === 'VERIFIED' || identificationsRead[0].identificationStatus === 'PROCCESSING'">-->
<!--        <img src="@/assets/images/success-svgrepo-com.svg" loading="lazy" alt="" width="200px" height="200px">-->
<!--        <p>You have successfully uploaded your Selfie</p>-->
<!--        <router-link to="/identity-verification">-->
<!--          <a class="button outline w-button">Back to verification home</a>-->
<!--        </router-link>-->
<!--      </div>-->
<!--    </div>-->


    <div>
      <div>
<!--        <div class="image-wrapper" v-if="!isCameraOpen"><img src="@/assets/images/Camera.png" loading="lazy" width="161" alt=""></div>-->


        <div class="form-wrapper">
          <div class="form-header-wrapper">
            <div class="margin-top margin-small">
<!--              <h3 class="text-align-center text-color-white">We’ll need to access your camera before continuing.</h3>-->
              <h3 class="text-align-center text-color-white">You’ll need to submit a selfie before continuing. The photos will be used to verify your identity.</h3>
              <div class="margin-top margin-xsmall padding-horizontal padding-small">
<!--                <p class="newsletter-text text-color-white text-align-center">You’ll need to submit a selfie before continuing. The photos will be used to verify your identity.</p>-->
              </div>
            </div>
          </div>
<!--          <div class="web-camera-container" v-else>-->

<!--            <div v-show="isCameraOpen && isLoading" class="camera-loading">-->
<!--              <ul class="loader-circle">-->
<!--                <li></li>-->
<!--                <li></li>-->
<!--                <li></li>-->
<!--              </ul>-->
<!--            </div>-->

<!--            <div v-if="isCameraOpen" v-show="!isLoading" class="camera-box" :class="{ 'flash' : isShotPhoto }">-->

<!--              <div class="camera-shutter" :class="{'flash' : isShotPhoto}"></div>-->

<!--              <video v-show="!isPhotoTaken" ref="camera" :width="410" :height="337.5" autoplay></video>-->

<!--              <canvas v-show="isPhotoTaken" id="photoTaken" ref="canvas" :width="410" :height="337.5"></canvas>-->
<!--            </div>-->

<!--            <div v-if="isCameraOpen && !isLoading" class="camera-shoot">-->
<!--              <button type="button" class="button" @click="takePhoto">-->
<!--                <img src="https://img.icons8.com/material-outlined/50/000000/camera&#45;&#45;v2.png">-->
<!--              </button>-->
<!--            </div>-->

<!--            &lt;!&ndash;                <div v-if="isPhotoTaken && isCameraOpen" class="camera-download">&ndash;&gt;-->
<!--            &lt;!&ndash;                  <a id="downloadPhoto" download="my-photo.jpg" class="button" role="button" @click="downloadImage">&ndash;&gt;-->
<!--            &lt;!&ndash;                    Download&ndash;&gt;-->
<!--            &lt;!&ndash;                  </a>&ndash;&gt;-->
<!--            &lt;!&ndash;                </div>&ndash;&gt;-->
<!--          </div>-->

          <div class="form-body-wrapper">
            <div class="margin-top margin-large">
              <div class="form-fields-wrapper">
                <div class="margin-top">
                  <div class="margin-bottom margin-small">

<!--                    <button type="button" class="button white-button w-button" :class="{ 'is-primary' : !isCameraOpen, 'is-danger' : isCameraOpen}" @click="toggleCamera" v-if="!isPhotoTaken">-->
<!--                      <span v-if="!isCameraOpen">Allow permission</span>-->
<!--                      <span v-else>Close Camera</span>-->
<!--                    </button>-->

                    <div id="sumsub-websdk-container"></div>

<!--                    <button type="button" class="button white-button w-button" v-if="isPhotoTaken" @click="uploadFile">-->
<!--                      <span v-if="!showLoader">Submit Photo</span>-->
<!--                      <span v-else>Loading...</span>-->
<!--                    </button>-->
                    <router-link to="/identity-verification">
                      <a class="button outline w-button">Back to verification home</a>
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </section>
</template>
<script>
import {mapState} from "vuex";
import S3Request from "@/model/request/S3Request";
import KycIdentificationRequest from "@/model/request/KycIdentificationRequest";
import RouterUtils from "@/util/baseUtils/RouterUtils";
import snsWebSdk from '@sumsub/websdk';

export default {
  name: "SelfieCard",
  data() {
    return {
      isCameraOpen: false,
      isPhotoTaken: false,
      isShotPhoto: false,
      isLoading: false,
      link: '#',
      url: "",
      disableInput: false,
      showLoader: false,
      fileName: "",
      base64: "",
      model: KycIdentificationRequest.create,
      uploadmodel: S3Request.prototype.uploadBase64(),
      notUploaded: true,
      accessToken: "",
    }
  },
  computed: {
    ...mapState(["auth", "sbucket", "identification"]),
    ...mapState({
      auth: state => state.auth,
      userInfo: state => state.auth.userInfo,
    }),
    user: state => state.user.user,
    identifications: state => state.identification.identification,
    identificationsRead: state => state.identification.readByIdentificationCustomerId,
    getID(){
      let val;
      if (this.identification.identification !== []){
        this.identification.identification.forEach((id) => {
          if (id.identificationType === "SELFIE"){
            val = true
          }
        })
      }
      else {
        val = false
      }
      return val
    },
  },
  methods: {
    toggleCamera() {
      if(this.isCameraOpen) {
        this.isCameraOpen = false;
        this.isPhotoTaken = false;
        this.isShotPhoto = false;
        this.stopCameraStream();
      } else {
        this.isCameraOpen = true;
        this.createCameraElement();
      }
    },
    createCameraElement() {
      this.isLoading = true;

      const constraints = (window.constraints = {
        audio: false,
        video: true
      });


      navigator.mediaDevices
          .getUserMedia(constraints)
          .then(stream => {
            this.isLoading = false;
            this.$refs.camera.srcObject = stream;
          })
          .catch(error => {
            console.log(error)
            this.isLoading = false;
            this.isCameraOpen = false;
            this.isPhotoTaken = false;
            alert("May the browser didn't support or there is some errors.");
          });
    },
    stopCameraStream() {
      let tracks = this.$refs.camera.srcObject.getTracks();

      tracks.forEach(track => {
        track.stop();
      });
    },
    takePhoto() {
      if(!this.isPhotoTaken) {
        this.isShotPhoto = true;

        const FLASH_TIMEOUT = 50;

        setTimeout(() => {
          this.isShotPhoto = false;
        }, FLASH_TIMEOUT);
      }

      this.isPhotoTaken = !this.isPhotoTaken;

      const context = this.$refs.canvas.getContext('2d');
      context.drawImage(this.$refs.camera, 0, 0, 450, 337.5);
    },
    downloadImage() {
      const download = document.getElementById("downloadPhoto");
      const canvas = document.getElementById("photoTaken").toDataURL("image/jpeg")
          .replace("image/jpeg", "image/octet-stream");
      download.setAttribute("href", canvas);
    },
    uploadFile() {
      this.base64 = document.getElementById("photoTaken").toDataURL();
      this.uploadOfficerImage();
    },
    async uploadOfficerImage() {
      this.stopCameraStream();
      this.showLoader = true;
      this.uploadmodel.username = `${
          this.auth.userInfo.userFirstName + this.auth.userInfo.userLastName
      }_${Date.now()}`;
      this.uploadmodel.base64 = this.base64;
      await this.$store.dispatch(
          "sbucket/uploadEncodedFile",
          this.uploadmodel,
          { root: true }
      );
      this.url = this.sbucket.s3bucketResponse.url;
      this.model.identificationLink = this.url;
      this.model.identificationNumber = 7;
      this.model.identificationType = "SELFIE";
      this.model.identificationUserId = this.userInfo.customerId;
      this.model.identificationCustomerId = this.userInfo.customerId;
      this.model.identificationExpiryDate = new Date().toLocaleDateString('en-US');
      this.model.identificationIssueDate = new Date().toLocaleDateString('en-US');
      let resp = await this.$store.dispatch("identification/identificationCreate", this.model).then()
      if (resp.responseCode === "00"){
        this.showLoader = false
        this.notUploaded = false;
        RouterUtils.navigateTo(RouterUtils.routes.kyc.identityVerification.name)
      }
      this.showLoader = false;
    },

    launchWebSdk(accessToken) {
      let snsWebSdkInstance = snsWebSdk
          .init(accessToken, this.getNewAccessToken)
          .withConf({
            lang: 'en',
            email: this.userInfo.customerEmail,
            phone: this.userInfo.customerPhone,
            i18n: {
              document: {
                subTitles: {
                  IDENTITY: 'Upload a document that proves your identity',
                },
              },
            },
            onMessage: (type, payload) => {
              console.log('WebSDK onMessage', type, payload);
            },
            uiConf: {
              customCssStr:
                  ':root {\n  --black: #000000;\n   --grey: #F5F5F5;\n  --grey-darker: #B2B2B2;\n  --border-color: #DBDBDB;\n}\n\np {\n  color: var(--black);\n  font-size: 16px;\n  line-height: 24px;\n}\n\nsection {\n  margin: 40px auto;\n}\n\ninput {\n  color: var(--black);\n  font-weight: 600;\n  outline: none;\n}\n\nsection.content {\n  background-color: var(--grey);\n  color: var(--black);\n  padding: 40px 40px 16px;\n  box-shadow: none;\n  border-radius: 6px;\n}\n\nbutton.submit,\nbutton.back {\n  text-transform: capitalize;\n  border-radius: 6px;\n  height: 48px;\n  padding: 0 30px;\n  font-size: 16px;\n  background-image: none !important;\n  transform: none !important;\n  box-shadow: none !important;\n  transition: all 0.2s linear;\n}\n\nbutton.submit {\n  min-width: 132px;\n  background: none;\n  background-color: var(--black);\n}\n\n.round-icon {\n  background-color: var(--black) !important;\n  background-image: none !important;\n}',
            },
            onError: (error) => {
              console.error('WebSDK onError', error);
            },
          })
          .withOptions({ addViewportTag: false, adaptIframeHeight: true })
          .on('idCheck.stepCompleted', (payload) => {
            console.log('stepCompleted', payload);
          })
          .on('idCheck.onError', (error) => {
            console.log('onError', error);
          })
          .onMessage((type, payload) => {
            console.log('onMessage', type, payload);
          })
          .build();
      snsWebSdkInstance.launch('#sumsub-websdk-container');
    },
    getNewAccessToken() {
      // Replace this with your implementation to retrieve a new access token
      return Promise.resolve('YOUR_NEW_ACCESS_TOKEN');
    },

    access() {
      this.accessToken = this.userInfo.sumSubAccessToken.toLocaleString();
    }

},
  created() {
    this.access()
  },
  mounted() {
    this.launchWebSdk(this.accessToken);
  },
};

</script>
<style scoped lang="scss">
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none; padding: 0;
}
li {
  display: inline-block; margin: 0 10px;
}

.web-camera-container {
  margin-top: 2rem;
  margin-bottom: 2rem;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 450px;

  .camera-button {
    margin-bottom: 2rem;
  }

  .camera-box {
    .camera-shutter {
      opacity: 0;
      width: 410px;
      height: 337.5px;
      background-color: #fff;
      position: absolute;

      &.flash {
        opacity: 1;
      }
    }
  }

  .camera-shoot {
    margin: 1rem 0;

    button {
      height: 60px;
      width: 60px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 100%;
      padding: 0;

      img {
        height: 35px;
        object-fit: cover;
      }
    }
  }

  .camera-loading {
    overflow: hidden;
    height: 100%;
    position: absolute;
    width: 100%;
    min-height: 150px;
    margin: 3rem 0 0 -1.2rem;

    ul {
      height: 100%;
      position: absolute;
      width: 100%;
      z-index: 999999;
      margin: 0;
    }

    .loader-circle {
      display: block;
      height: 14px;
      margin: 0 auto;
      top: 50%;
      left: 100%;
      //transform: translateY(-50%);
      //transform: translateX(-50%);
      position: absolute;
      width: 100%;
      padding: 0;

      li {
        display: block;
        float: left;
        width: 10px;
        height: 10px;
        line-height: 10px;
        padding: 0;
        position: relative;
        margin: 0 0 0 4px;
        background: #999;
        animation: preload 1s infinite;
        top: -50%;
        border-radius: 100%;

        &:nth-child(2) {
          animation-delay: .2s;
        }

        &:nth-child(3) {
          animation-delay: .4s;
        }
      }
    }
  }

  @keyframes preload {
    0% {
      opacity: 1
    }
    50% {
      opacity: .4
    }
    100% {
      opacity: 1
    }
  }
}
.text-block-501{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: GTEestiProDisplay-Regular,serif ;
  //font-weight: bold;
  font-size: 16px;
  gap: 20px;
  color: white;
}

.text-block-501 p{
  font-size: 20px !important;
}


@media screen and (max-width: 480px){
  .web-camera-container {
    width: 340px;
    margin-left: 9%;
  }
}

@media screen and (max-width: 440px){
  .web-camera-container {
    width: 330px;
    margin-left: 6.5%;
  }
}

@media screen and (max-width: 440px){
  .web-camera-container {
    width: 320px;
    margin-left: 7.5%;
  }
}

@media screen and (max-width: 401px){
  .web-camera-container {
    margin-left: 4.5%;
  }
}

@media screen and (max-width: 360px){
  .web-camera-container {
    width: 290px;
    margin-left: 4%;
  }
}

@media screen and (max-width: 330px){
  .web-camera-container {
    width: 280px;
    margin-left: 3.5%;
  }
}

@media screen and (max-width: 315px){
  .web-camera-container {
    width: 270px;
    margin-left: 3%;
  }
}

@media screen and (max-width: 295px){
  .web-camera-container {
    width: 260px;
    margin-left: 2.5%;
  }
}

</style>

