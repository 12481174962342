<template>
  <section id="InteracFundingCard" class="dashboard-body-wrapper align-center">
          <div class="back-button margin-vertical margin-large">
            <router-link to="/fund-wallet">
              <a class="w-inline-block"><img src="@/assets/images/Navigation-controls.svg" loading="lazy" alt=""></a>
            </router-link>
          </div>
          <h4>Follow the instructions below to fund your Rubies payment service wallet</h4>
          <div class="margin-top margin-medium">
<!--            <div class="interac-funding-steps">-->
<!--              <div class="margin-bottom margin-small">-->
<!--                <div class="text-block-60">STEP 1</div>-->
<!--              </div>-->
<!--              <div class="connected-banks-list">-->
<!--                <router-link to="/updateKycStep">-->
<!--                  <a class="interac-card w-inline-block">-->
<!--                    <div class="setup-title-wrapper"><img src="@/assets/images/bank.svg" loading="lazy" alt="">-->
<!--                      <div class="setup-title">-->
<!--                        <div class="text-block-51">Login to your banking app and send money to <strong>deposit@rubies.com</strong></div>-->
<!--                      </div>-->
<!--                    </div>-->
<!--                  </a>-->
<!--                </router-link>-->
<!--              </div>-->
<!--            </div>-->
            <div class="interac-funding-steps">
              <div class="margin-bottom margin-small">
                <div class="text-block-60">STEP 1</div>
              </div>

              <div class="connected-banks-list">
                <router-link to="/updateKycStep">
                  <a class="interac-card w-inline-block">
                    <div class="setup-title-wrapper"><img src="@/assets/images/bank.svg" loading="lazy" alt="">
                      <div class="setup-title">
                        <div class="text-block-51">Login to your banking app and send money to <strong>payment@rubieswire.com</strong></div>
                      </div>
                    </div>
                  </a>
                </router-link>
              </div>

            </div>
            <div class="interac-funding-steps">
              <div class="margin-bottom margin-small">
                <div class="text-block-60">STEP 2</div>
              </div>

              <div class="connected-banks-list">
                <router-link to="/updateKycStep">
                  <a class="interac-card w-inline-block">
                    <div class="setup-title-wrapper"><img src="@/assets/images/bank.svg" loading="lazy" alt="">
                      <div class="setup-title">
                        <div class="text-block-51">Make sure you are sending money from your verified Interac address <strong>({{userInfo.customerEmail}})</strong></div>
                      </div>
                    </div>
                  </a>
                </router-link>
              </div>

            </div>
          </div>
          <div class="margin-top margin-medium">
            <div class="payment-email-wrapper">
              <div class="payment-email">
                <div class="text-block-61">Payment email</div>
                <div class="text-block-62">payment@rubieswire.com</div>
              </div>
              <div class="copy-button">
                <a @click="copyToClipboard('payment@rubieswire.com')" class="button is-secondary copy ">Copy</a>
              </div>
            </div>
          </div>


        </section>
</template>
<script>
	import {mapState} from "vuex";

  export default {
	name: "InteracFundingCard",
    computed: {
      ...mapState({
        loading: state => state.address.loading,
        auth: state => state.auth,
        userInfo: state => state.auth.userInfo,
        accountState: state => state.auth.accountState
      }),
    },
    methods: {
      copyToClipboard(content) {
        const textarea = document.createElement('textarea')
        textarea.value = content
        document.body.appendChild(textarea)
        textarea.select()
        document.execCommand('copy')
        document.body.removeChild(textarea)
      }
    }
	};
</script>
<style scoped lang="scss">
   h3 {margin: 40px 0 0; }
   ul {list-style-type: none; padding: 0; }
   li {display: inline-block; margin: 0 10px; }

   strong{
     text-transform: lowercase;
   }
</style>

