<template>
  <div>
    <div class="backdrop"></div>
    <dialog open>
      <div data-w-id="0faacfbd-8450-6559-5219-33b088173ad4" >


<!--        <div class="id-upload-modal" v-if="identificationsRead.length > 0">-->
<!--          <div class="modal-image-container"></div>-->
<!--          <div class="kyc-id-upload">-->

<!--            <div class="text-size-medium text-weight-medium" v-if="notUploaded && identificationsRead[0].identificationStatus === 'PENDING' || identificationsRead[0].identificationStatus === 'FAILED'">Upload and attach ID</div>-->
<!--          </div>-->

<!--          <select class="input-field w-input" v-model="idType" maxlength="256" name="field" data-name="Field" placeholder="Select ID Type"  aria-placeholder="Select ID Type" id="field" required="required" v-if="notUploaded && identificationsRead[0].identificationStatus === 'PENDING' || identificationsRead[0].identificationStatus === 'FAILED'">-->
<!--            <option :value="null" disabled>Select ID Type</option>-->
<!--            <option  value="">Select ID Type</option>-->
<!--            <option value="DRIVERS">Drivers License</option>-->
<!--            <option value="ID_CARD">ID Card</option>-->
<!--            <option value="RESIDENCE_PERMIT">Resident Permit</option>-->
<!--            <option value="PASSPORT">Passport</option>-->
<!--          </select>-->

<!--          <div class="margin-top margin-small" v-if="notUploaded && identificationsRead[0].identificationStatus === 'PENDING' || identificationsRead[0].identificationStatus === 'FAILED'">-->


<!--            <div style="position: relative">-->

<!--              <label-->
<!--                  class="upload-wrapper"-->
<!--                  v-if="url1 === '' && state"-->
<!--              >-->
<!--                <input-->
<!--                    style="display: none"-->
<!--                    type="file"-->
<!--                    class="input-file"-->
<!--                    id="files"-->
<!--                    name="files"-->
<!--                    @change="uploadFile"-->
<!--                    accept="image/*"-->
<!--                    ref="file"-->
<!--                />-->
<!--                <span class="div-block-170">-->
<!--              <img src="@/assets/images/Featured-icon.svg" loading="lazy" alt="">-->
<!--              <div class="text-block-56" v-if="!showLoader">-->
<!--                <a class="link">Click to upload </a> or drag and drop<br>PNG or JPG (Max size 5mb)<br>(FRONT)-->
<!--              </div>-->
<!--              <span v-else style="margin-top: -60px" class="text-block-500">-->
<!--                Uploading file...-->
<!--              </span>-->
<!--            </span>-->
<!--              </label>-->
<!--              <div v-if="url1 !== '' && state">-->
<!--                <a :href="url1" target="_blank">-->
<!--                  <div-->
<!--                      class="upload-wrapper"-->
<!--                      style="height: 230px"-->
<!--                      :style="{ 'background-image': `url(${this.url1})` ,'width': '100%', 'background-size': 'contain' }"-->
<!--                  ></div>-->
<!--                </a>-->
<!--                <button @click="url1 = ''" class="re-upload">re-upload</button>-->
<!--              </div>-->



<!--              <label-->
<!--                  class="upload-wrapper"-->
<!--                  v-if="url2 === '' && !state"-->
<!--              >-->
<!--                <input-->
<!--                    style="display: none"-->
<!--                    type="file"-->
<!--                    class="input-file"-->
<!--                    id="files"-->
<!--                    name="files"-->
<!--                    @change="uploadFile"-->
<!--                    accept="image/*"-->
<!--                    ref="file"-->
<!--                />-->
<!--                <span class="div-block-170">-->
<!--              <img src="@/assets/images/Featured-icon.svg" loading="lazy" alt="">-->
<!--              <div class="text-block-56" v-if="!showLoader">-->
<!--                <a href="#" class="link">Click to upload </a> or drag and drop<br>PNG or JPG (Max size 5mb)<br>(BACK)-->
<!--              </div>-->
<!--              <span v-else style="margin-top: -60px" class="text-block-500">-->
<!--                Uploading file...-->
<!--              </span>-->
<!--            </span>-->
<!--              </label>-->
<!--              <div v-if="url2 !== '' && !state">-->
<!--                <a :href="url2" target="_blank">-->
<!--                  <div-->
<!--                      class="upload-wrapper"-->
<!--                      style="height: 230px"-->
<!--                      :style="{ 'background-image': `url(${this.url2})` ,'width': '100%', 'background-size': 'contain' }"-->
<!--                  ></div>-->
<!--                </a>-->
<!--                <button @click="url2 = ''" class="re-upload">re-upload</button>-->
<!--              </div>-->
<!--              <a style="position: absolute; left: 0; top: 40%" @click="changeState"><img src="../../assets/images/arrow-left.svg" alt="Arrow"/></a>-->
<!--              <a style="position: absolute; right: 0; top: 40%" @click="changeState"><img src="../../assets/images/arrow-right.svg" alt="Arrow"/></a>-->
<!--            </div>-->

<!--            <input type="text" v-model="model.identificationCardNo" class="input-field w-input" maxlength="256" name="field" data-name="text" placeholder="Enter ID Card No" required="required">-->



<!--            <FormulateInput-->
<!--                type="date"-->
<!--                name="Field"-->
<!--                label="Enter Issue Date"-->
<!--                v-model="model.identificationIssueDate"-->
<!--                maxlength="256"-->
<!--                minlength="256"-->
<!--                placeholder="Enter Issue Date"-->
<!--                style="margin-top:3px;color: #667085;"-->
<!--            />-->


<!--            <FormulateInput-->
<!--                type="date"-->
<!--                name="Field"-->
<!--                v-model="model.identificationExpiryDate"-->
<!--                maxlength="256"-->
<!--                minlength="256"-->
<!--                label="Enter Expiry Date"-->
<!--                placeholder="Enter Expiry Date"-->
<!--                style="margin-top:3px;color: #667085;"-->
<!--            />-->


<!--          </div>-->
<!--          <div class="margin-top margin-small text-block-501" v-else>-->
<!--            <img src="@/assets/images/success-svgrepo-com.svg" loading="lazy" alt="" width="200px" height="200px">-->
<!--            <p>You have successfully uploaded your ID</p>-->
<!--            <a class="button w-button" @click="$emit('close')" style="width: 100%;">Close</a>-->
<!--          </div>-->
<!--          <div class="margin-top margin-small" v-if="notUploaded && identificationsRead[0].identificationStatus === 'PENDING' || identificationsRead[0].identificationStatus === 'FAILED'">-->
<!--            <div class="button-group">-->
<!--              <a class="button is-secondary half-size w-button" @click="$emit('close')">Cancel</a>-->

<!--              <a class="button half-size w-button" v-on:click="checkSuccessful" >Verify</a>-->


<!--            </div>-->
<!--          </div>-->
<!--        </div>-->


        <div class="id-upload-modal" >
          <div class="modal-image-container"></div>
          <div class="kyc-id-upload">

            <div class="text-size-medium text-weight-medium">Upload and attach ID</div>
          </div>

          <div id="sumsub-websdk-container"></div>

<!--          &lt;!&ndash;          <label>Select ID Type</label>&ndash;&gt;-->
<!--          <select class="input-field w-input" v-model="idType" maxlength="256" name="field" data-name="Field" placeholder="Select ID Type"  aria-placeholder="Select ID Type" id="field" required="required" >-->
<!--            <option :value="null" disabled>Select ID Type</option>-->
<!--            <option value="DRIVERS">Drivers License</option>-->
<!--            <option value="ID_CARD">ID Card</option>-->
<!--            <option value="RESIDENCE_PERMIT">Resident Permit</option>-->
<!--            <option value="PASSPORT">Passport</option>-->
<!--          </select>-->


<!--          <div class="margin-top margin-small">-->
<!--            &lt;!&ndash;          <div class="upload-wrapper"><img src="@/assets/images/Featured-icon.svg" loading="lazy" alt="">&ndash;&gt;-->
<!--            &lt;!&ndash;            <div class="text-block-56">&ndash;&gt;-->
<!--            &lt;!&ndash;              <a href="#" class="link">Click to upload</a> or drag and drop<br>PNG, JPG or PDF (max. 800x400px)&ndash;&gt;-->
<!--            &lt;!&ndash;              <input type="file" />&ndash;&gt;-->

<!--            &lt;!&ndash;            </div>&ndash;&gt;-->
<!--            &lt;!&ndash;          </div>&ndash;&gt;-->

<!--            <div style="position: relative">-->
<!--              <label-->
<!--                  class="upload-wrapper"-->
<!--                  v-if="url1 === '' && state"-->
<!--              >-->
<!--                <input-->
<!--                    style="display: none"-->
<!--                    type="file"-->
<!--                    class="input-file"-->
<!--                    id="files"-->
<!--                    name="files"-->
<!--                    @change="uploadFile"-->
<!--                    accept="image/*"-->
<!--                    ref="file"-->
<!--                />-->
<!--                <span class="div-block-170">-->
<!--              <img src="@/assets/images/Featured-icon.svg" loading="lazy" alt="">-->
<!--              <div class="text-block-56" v-if="!showLoader">-->
<!--                <a class="link">Click to upload </a> or drag and drop<br>PNG or JPG (Max size 5mb)<br>(FRONT)-->
<!--              </div>-->
<!--              <span v-else style="margin-top: -60px" class="text-block-500">-->
<!--                Uploading file...-->
<!--              </span>-->
<!--            </span>-->
<!--              </label>-->
<!--              <div v-if="url1 !== '' && state">-->
<!--                <a :href="url1" target="_blank">-->
<!--                  <div-->
<!--                      class="upload-wrapper"-->
<!--                      style="height: 230px"-->
<!--                      :style="{ 'background-image': `url(${this.url1})` ,'width': '100%', 'background-size': 'contain' }"-->
<!--                  ></div>-->
<!--                </a>-->
<!--                <button @click="url1 = ''" class="re-upload">re-upload</button>-->
<!--              </div>-->
<!--              <label-->
<!--                  class="upload-wrapper"-->
<!--                  v-if="url2 === '' && !state"-->
<!--              >-->
<!--                <input-->
<!--                    style="display: none"-->
<!--                    type="file"-->
<!--                    class="input-file"-->
<!--                    id="files"-->
<!--                    name="files"-->
<!--                    @change="uploadFile"-->
<!--                    accept="image/*"-->
<!--                    ref="file"-->
<!--                />-->
<!--                <span class="div-block-170">-->
<!--              <img src="@/assets/images/Featured-icon.svg" loading="lazy" alt="">-->
<!--              <div class="text-block-56" v-if="!showLoader">-->
<!--                <a href="#" class="link">Click to upload </a> or drag and drop<br>PNG or JPG (Max size 5mb)<br>(BACK)-->
<!--              </div>-->
<!--              <span v-else style="margin-top: -60px" class="text-block-500">-->
<!--                Uploading file...-->
<!--              </span>-->
<!--            </span>-->
<!--              </label>-->
<!--              <div v-if="url2 !== '' && !state">-->
<!--                <a :href="url2" target="_blank">-->
<!--                  <div-->
<!--                      class="upload-wrapper"-->
<!--                      style="height: 230px"-->
<!--                      :style="{ 'background-image': `url(${this.url2})` ,'width': '100%', 'background-size': 'contain' }"-->
<!--                  ></div>-->
<!--                </a>-->
<!--                <button @click="url2 = ''" class="re-upload">re-upload</button>-->
<!--              </div>-->
<!--              <a style="position: absolute; left: 0; top: 40%" @click="changeState"><img src="../../assets/images/arrow-left.svg" alt="Arrow"/></a>-->
<!--              <a style="position: absolute; right: 0; top: 40%" @click="changeState"><img src="../../assets/images/arrow-right.svg" alt="Arrow"/></a>-->
<!--            </div>-->

<!--            <input type="text" v-model="model.identificationCardNo" class="input-field w-input" maxlength="256" name="field" data-name="text" placeholder="Enter ID Card No" required="required">-->
<!--            &lt;!&ndash;            <input type="text" v-model="model.identificationIssueDate" class="input-field w-input" maxlength="256" name="field" data-name="Email" placeholder="Enter Issue Date" required="required" onfocus="this.type = 'date'">&ndash;&gt;-->
<!--            &lt;!&ndash;            <input type="text" v-model="model.identificationExpiryDate" class="input-field w-input" maxlength="256" name="field" data-name="Email" placeholder="Enter Expiry Date" required="required" onfocus="this.type = 'date'">&ndash;&gt;-->


<!--            <FormulateInput-->
<!--                type="date"-->
<!--                name="Field"-->
<!--                label="Enter Issue Date"-->
<!--                v-model="model.identificationIssueDate"-->
<!--                maxlength="256"-->
<!--                minlength="256"-->
<!--                placeholder="Enter Issue Date"-->
<!--                style="margin-top:3px;color: #667085;"-->
<!--            />-->


<!--            <FormulateInput-->
<!--                type="date"-->
<!--                name="Field"-->
<!--                v-model="model.identificationExpiryDate"-->
<!--                maxlength="256"-->
<!--                minlength="256"-->
<!--                label="Enter Expiry Date"-->
<!--                placeholder="Enter Expiry Date"-->
<!--                style="margin-top:3px;color: #667085;"-->
<!--            />-->


<!--          </div>-->

          <div class="margin-top margin-small">
            <div class="button-group">
              <a class="button is-secondary half-size w-button" @click="$emit('close')">Cancel</a>
              <!--            <router-link to="/reviewingKycId">-->
<!--              <a class="button half-size w-button" v-on:click="checkSuccessful">Verify</a>-->

              <a class="button half-size w-button" @click="$emit('close')">Verify</a>

              <!--            </router-link>-->
            </div>
          </div>


        </div>
      </div>
    </dialog>
  </div>
</template>

<script>
import swal from "sweetalert";
import S3Request from "@/model/request/S3Request";
import {mapState} from "vuex";
import KycIdentificationRequest from "@/model/request/KycIdentificationRequest";
import snsWebSdk from '@sumsub/websdk';

export default {
  name: "BaseModal",
  components: {},
  emits: ['close'],
  data(){
    return{
      state: true,
      url: "",
      idType:"",
      disableInput: false,
      showLoader: false,
      fileName: "",
      base64: "",
      uploadmodel: S3Request.prototype.uploadBase64(),
      submitted: false,
      model: KycIdentificationRequest.create,
      notUploaded: true,
      url1: "",
      url2: "",
      accessToken: "",
    }
  },
  computed: {
    ...mapState(["auth", "sbucket", "identification"]),
    ...mapState({
      auth: state => state.auth,
      userInfo: state => state.auth.userInfo,
    }),
    identifications: state => state.identification.identification,
    identificationsRead: state => state.identification.readByIdentificationCustomerId,
    response: state => state.identification.response,
    getID() {
      let val;
      if (this.identification.identification !== []){
        this.identification.identification.forEach((id) => {
          if (id.identificationType === "ID"){
            val = true
          }
        })
      }
      else {
        val = false
      }
      return val
    }
  },
  methods:{
    changeState(){
      this.state = !this.state;
      this.showLoader = false;
    },
    async checkSuccessful() {
      this.url = this.url1 + "," + this.url2;
      this.submitted = true
      this.model.identificationLink = this.url;
      this.model.identificationNumber = 9;
      this.model.identificationType = this.idType;
      this.model.identificationUserId = this.auth.userInfo.customerId;
      this.model.identificationCustomerId = this.auth.userInfo.customerId;
      let resp = await this.$store.dispatch("identification/identificationCreate", this.model).then();
      if (resp.responseCode === "00"){
        this.notUploaded = false
        this.$emit('close')
      }
    },
    uploadFile() {
      let input = this.$refs.file;
      let files = input.files;
      this.fileName = files[0].name;
      let size = files[0].size / 1000000;

      if (size > 5) {
        swal(
            "Error",
            "File size must not be more than 5mb, please try with a reduced file size",
            "error"
        );
        return;
      }
      const reader = new FileReader();
      try {
        reader.onload = (e) => {
          this.base64 = e.target.result;
          this.uploadOfficerImage();
        };
        reader.readAsDataURL(files[0]);
        this.$emit("input", files[0]);
      } catch (e) {
        console.warn(e.message);
      }
    },
    async uploadOfficerImage() {
      this.showLoader = true;
      this.uploadmodel.username = `${
          this.auth.userInfo.userFirstName + this.auth.userInfo.userLastName
      }_${Date.now()}`;
      this.uploadmodel.base64 = this.base64;
      await this.$store.dispatch(
          "sbucket/uploadEncodedFile",
          this.uploadmodel,
          { root: true }
      );
      if(this.url1 === ""){
        this.url1 = this.sbucket.s3bucketResponse.url;
      }
      else {
        this.url2 = this.sbucket.s3bucketResponse.url;
      }
      this.
      this.showLoader = false;
    },

    launchWebSdk(accessToken) {
      let snsWebSdkInstance = snsWebSdk
          .init(accessToken, this.getNewAccessToken)
          .withConf({
            lang: 'en',
            email: 'applicant@example.com',
            phone: '1234567890',
            i18n: {
              document: {
                subTitles: {
                  IDENTITY: 'Upload a document that proves your identity',
                },
              },
            },
            onMessage: (type, payload) => {
              console.log('WebSDK onMessage', type, payload);
            },
            uiConf: {
              customCssStr:
                  ':root {\n  --black: #000000;\n   --grey: #F5F5F5;\n  --grey-darker: #B2B2B2;\n  --border-color: #DBDBDB;\n}\n\np {\n  color: var(--black);\n  font-size: 16px;\n  line-height: 24px;\n}\n\nsection {\n  margin: 40px auto;\n}\n\ninput {\n  color: var(--black);\n  font-weight: 600;\n  outline: none;\n}\n\nsection.content {\n  background-color: var(--grey);\n  color: var(--black);\n  padding: 40px 40px 16px;\n  box-shadow: none;\n  border-radius: 6px;\n}\n\nbutton.submit,\nbutton.back {\n  text-transform: capitalize;\n  border-radius: 6px;\n  height: 48px;\n  padding: 0 30px;\n  font-size: 16px;\n  background-image: none !important;\n  transform: none !important;\n  box-shadow: none !important;\n  transition: all 0.2s linear;\n}\n\nbutton.submit {\n  min-width: 132px;\n  background: none;\n  background-color: var(--black);\n}\n\n.round-icon {\n  background-color: var(--black) !important;\n  background-image: none !important;\n}',
            },
            onError: (error) => {
              console.error('WebSDK onError', error);
            },
          })
          .withOptions({ addViewportTag: false, adaptIframeHeight: true })
          .on('idCheck.stepCompleted', (payload) => {
            console.log('stepCompleted', payload);
          })
          .on('idCheck.onError', (error) => {
            console.log('onError', error);
          })
          .onMessage((type, payload) => {
            console.log('onMessage', type, payload);
          })
          .build();
      snsWebSdkInstance.launch('#sumsub-websdk-container');
    },
    getNewAccessToken() {
      // Replace this with your implementation to retrieve a new access token
      return Promise.resolve('YOUR_NEW_ACCESS_TOKEN');
    },
    access() {
      this.accessToken = this.userInfo.sumSubAccessToken.toLocaleString();
    }
  },
  created() {
    this.access()
  },
  mounted() {
    console.log(this.identification);
    this.launchWebSdk(this.accessToken);
  }
}
</script>


<style scoped>

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 10;
  background-color: rgba(0, 0, 0, 0.7);
}

div{
  padding-bottom: 10px;
}
h3{
  font-family: GTEestiProDisplay-Regular,serif ;
  font-weight: bold;
}
p{
  font-family: GTEestiProDisplay-Regular,serif ;
}

dialog {
  position: fixed;
  top: 11vh;
  width: 33rem;
  height: 20rem;
  left: calc(50% - 16rem);
  margin: 0;
  background-color: transparent;
  z-index: 100;
  border: none;
  animation: modal 0.3s ease-out forwards;
}

@keyframes modal {
  from {
    opacity: 0;
    transform: translateY(-50px) scale(0.9);
  }

  to {
    opacity: 1;
    transform: translateY(0) scale(1);
  }
}


@media (max-width: 500px) {
  dialog {
    top: 8vh;
    width: 27rem;
    height: 20rem;
    left: calc(50% - 12.5rem);
  }
  h3{
    font-size: 20px;
  }
  p{
    font-size: unset;
  }
}

.div-block-170 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.text-block-500{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: GTEestiProDisplay-Regular,serif ;
  font-weight: bold;
  font-size: 14px;
  width: 320px;
  height: 140px;
}

.text-block-501{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: GTEestiProDisplay-Regular,serif ;
  font-weight: bold;
  font-size: 16px;
  gap: 20px;
}

.text-block-501 p{
  font-size: 20px !important;
}

.re-upload{
  font-family: GTEestiProDisplay-Bold,serif ;
  font-size: 14px;
  float: left;
  color: red;
  margin-top: 1%;
  margin-bottom: 1%;
}
</style>






