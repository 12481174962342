import store from "../../store/store";

class StoreUtils {
    static actions = {
        auth: {
            login: "auth/login",
            initiateEnrollment: "auth/initiateEnrollment",
            completeEnrollment: "auth/completeEnrollment",
            resendOtp: "auth/resendOtp",
            initiatePasswordReset: "auth/initiatePasswordReset",
            completePasswordReset: "auth/completePasswordReset",
            changePassword: "auth/changePassword",
            getUserDetails: "auth/getUserDetails",
            resetPin: "auth/resetPin"
        },
        address: {
            addressCreate: "address/addressCreate",
            addressUpdate: "address/addressUpdate",
            addressRead: "address/addressRead",
            addressUpdateStatus: "address/addressUpdateStatus",
            addressReads: "address/addressReads",
            addressFetchProvinces: "address/addressFetchProvinces",
            addressReaByCustomerId: "address/addressReaByCustomerId",
        },
        identification: {
            identificationCreate: "identification/identificationCreate",
            identificationUpdate: "identification/identificationUpdate",
            identificationReadBy: "identification/identificationReadBy",
            identificationRead: "identification/identificationRead",
            readByIdentificationCustomerId: "identification/readByIdentificationCustomerId"
        },
        accountInformation:{
            accountInformationCreate: "accountInformation/accountInformationCreate",
            accountReadSourceOfIncome: "accountInformation/accountReadSourceOfIncome",
            accountInformationReaByCustomerId: "accountInformation/accountInformationReaByCustomerId"
        },
        fundTransfer: {
            doNameEnquiry: "fundTransfer/doNameEnquiry",
            doFundTransfer: "fundTransfer/doFundTransfer",
            doFundsTransferCADNGN: "fundTransfer/doFundsTransferCADNGN",
            doFundsTransferNGNNaira: "fundTransfer/doFundsTransferNGNNaira",
        },
        transaction: {
            transactionCreate: "transaction/transactionCreate",
            transactionUpdate: "transaction/transactionUpdate",
            transactionReadBy: "transaction/transactionReadBy",
            transactionRead: "transaction/transactionRead",
            transactionReadByTransactionCustomerId: "transaction/transactionReadByTransactionCustomerId",
            fetchGetBanks: "transaction/fetchGetBanks",
            transactionReadAllWalletTransaction: "transaction/transactionReadAllWalletTransaction",
        },
        rate: {
            fetchActiveRate: "rate/fetchActiveRate"
        },
        beneficiary: {
            beneficiaryCreate: "beneficiary/beneficiaryCreate",
            beneficiaryUpdate: "beneficiary/beneficiaryUpdate",
            beneficiaryReadBy: "beneficiary/beneficiaryReadBy",
            beneficiaryRead: "beneficiary/beneficiaryRead",
        },
    };

    static mutations = {
        auth: {
            updateLoading: "auth/updateLoading",
            updateUserInfo: "auth/updateUserInfo",
            updateToken: "auth/updateToken",
            updateScreen: "auth/updateScreen",
            updateSignInFormData: "auth/updateSignInFormData",
            updateSignUpFormData: "auth/updateSignUpFormData",
            updateAccountState: "auth/updateAccountState",
            updateResetPin: "auth/updateResetPin"
        },
        address: {
            updateLoading: "address/updateLoading",
            updateAddress: "address/updateAddress",
            updateAddresses: "address/updateAddress",
            updateProvinces: "address/updateProvinces",
            updateReaByCustomerId: "address/updateReaByCustomerId",
            updateAddressReadBy: "address/updateAddressReadBy"
        },
        accountInformation:{
            updateLoading: "accountInformation/updateLoading",
            updateAccount: "accountInformation/updateAccount",
            updateAccounts: "accountInformation/updateAccounts",
            updateReadSourceOfIncome: "accountInformation/updateReadSourceOfIncome",
            updateReadByAccountInformationCustomerId: "accountInformation/updateReadByAccountInformationCustomerId"
        },
        identification: {
            updateLoading: "identification/updateLoading",
            updateIdentification: "identification/updateIdentification",
            updateIdentifications: "identification/updateIdentification",
            updateReadByIdentificationCustomerId: "identification/updateReadByIdentificationCustomerId"
        },
        fundTransfer: {
            updateLoading: "fundTransfer/updateLoading",
            updateNameEnquiryResponse: "fundTransfer/updateNameEnquiryResponse",
            updateFundTransferRequest: "fundTransfer/updateFundTransferRequest",
            updateFundsTransferCADNGN: "fundTransfer/updateFundsTransferCADNGN",
            updateFundsTransferNGNNaira: "fundTransfer/updateFundsTransferNGNNaira",
            updateTransferFormData: "fundTransfer/updateTransferFormData",
        },
        transaction: {
            updateLoading: "transaction/updateLoading",
            updateTransaction: "transaction/updateTransaction",
            updateTransactions: "transaction/updateTransactions",
            updateReadByTransactionCustomerId: "transaction/updateReadByTransactionCustomerId",
            updateGetBanks: "transaction/updateGetBanks",
            updateReadAllWalletTransaction: "transaction/updateReadAllWalletTransaction",
        },
        rate: {
            updateLoading: "rate/updateLoading",
            updateActiveRate: "rate/updateActiveRate",
        },
        beneficiary: {
            updateLoading: "beneficiary/updateLoading",
            updateBeneficiary: "beneficiary/updateBeneficiary",
            updateBeneficiaries: "beneficiary/updateBeneficiaries",
            updateReadByBeneficiaryId: "beneficiary/updateReadByBeneficiaryId",
        },
    };

    static getters = {
        auth: {
            getUserInfo: "auth/getUserInfo",
            getToken: "auth/getToken",
            getSignInFormData: "auth/getSignInFormData",
            getSignUpFormData: "auth/getSignUpFormData",
            getAccountState: "auth/getAccountState",
            getResetPin: "auth/getResetPin"
        },
        address: {
            getAddress: "address/getAddress",
            getAddresses: "address/getAddresses",
            getProvinces: "address/getProvinces",
            getReaByCustomerId: "address/getReaByCustomerId",
            getAddressReadBy: "address/getAddressReadBy"
        },
        accountInformation:{
            getAccount: "accountInformation/getAccount",
            getAccounts: "accountInformation/getAccounts",
            getReadSourceOfIncome: "accountInformation/getReadSourceOfIncome",
            getReadByAccountInformationCustomerId: "accountInformation/getReadByAccountInformationCustomerId"
        },
        identification: {
            getIdentification: "identification/getIdentification",
            getIdentifications: "identification/getIdentifications",
            getReadByIdentificationCustomerId: "identification/getReadByIdentificationCustomerId",
        },
        fundTransfer: {
            getNameEnquiryResponse: "fundTransfer/getNameEnquiryResponse",
            getFundTransferRequest: "fundTransfer/getFundTransferRequest",
            getFundsTransferCADNGN: "fundTransfer/getFundsTransferCADNGN",
            getFundsTransferNGNNaira: "fundTransfer/getFundsTransferNGNNaira",
            getTransferFormData: "fundTransfer/getTransferFormData",
        },
        transaction: {
            getTransaction: "transaction/getTransaction",
            getTransactions: "transaction/getTransactions",
            getReadByTransactionCustomerId: "transaction/getReadByTransactionCustomerId",
            getGetBanks: "transaction/getGetBanks",
            getReadAllWalletTransaction: "transaction/getReadAllWalletTransaction",
        },
        rate: {
            getActiveRate: "rate/getActiveRate"
        },
        beneficiary: {
            getBeneficiary: "beneficiary/getBeneficiary",
            getBeneficiaries: "beneficiary/getBeneficiaries",
            getReadByBeneficiaryId: "beneficiary/getReadByBeneficiaryId",
        },
    };

    static dispatch(actionToDispatch, payload) {
        return store.dispatch(actionToDispatch, payload, { root: true });
    }

    static commit(mutationToCommit, payload) {
        return store.commit(mutationToCommit, payload, { root: true });
    }

    static rootGetters(getter, payload) {
        console.log(getter)
        if (payload) {
            return store.getters[getter](payload);
        } else {
            return store.getters[getter];
        }
    }
}

export default StoreUtils;
