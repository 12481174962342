import StoreUtils from "@/util/baseUtils/StoreUtils";
import FundTransferRequest from "@/model/request/FundTransferRequest";
import FundTransferResponse from "@/model/response/FundTransferResponse";
import FundTransferService from "@/service/FundTransferService";
import swal from "sweetalert";
import RouterUtils from "@/util/baseUtils/RouterUtils";


export const state = {
  loading: false,
  nameEnquiryResponse: new FundTransferResponse().nameEnquiry,
  fundTransferRequest: new FundTransferRequest().fundTransfer,
  fundsTransferCADNGN: new FundTransferResponse().fundsTransferCADNGN,
  fundsTransferNGNNaira: null,
  transferFormData: null,
};

export const getters = {
  // getLoading: (state) => {
  //   return state.loading;
  // },
  getNameEnquiryResponse: (state) => {
    return state.nameEnquiryResponse;
  },
  getFundTransferRequest: (state) => {
    return state.fundTransferRequest;
  },
  getFundsTransferCADNGN: (state) => {
    return state.fundsTransferCADNGN;
  },
  getFundsTransferNGNNaira: (state) => {
    return state.fundsTransferNGNNaira;
  },
  getTransferFormData: (state) => {
    return state.transferFormData;
  },
};

export const mutations = {
  updateLoading(state, payload){
    state.loading = payload
  },
  updateNameEnquiryResponse(state, payload){
    state.nameEnquiryResponse = payload
  },
  updateFundTransferRequest(state, payload){
    state.fundTransferRequest = payload
  },
  updateFundsTransferCADNGN(state, payload){
    state.fundsTransferCADNGN = payload
  },
  updateFundsTransferNGNNaira(state, payload){
    state.fundsTransferNGNNaira = payload
  },
  updateTransferFormData(state, payload){
    state.transferFormData = payload
  },
};

export const actions = {

  // eslint-disable-next-line no-empty-pattern
  doNameEnquiry({}, payload = new FundTransferRequest().nameEnquiry){
    StoreUtils.commit(StoreUtils.mutations.fundTransfer.updateLoading, true)
    return FundTransferService.callNameEnquiryApi(payload).then(response=>{
      StoreUtils.commit(StoreUtils.mutations.fundTransfer.updateLoading, false)
      let responseData = response.data
      if (responseData.responseCode === "00"){
        StoreUtils.commit(StoreUtils.mutations.fundTransfer.updateNameEnquiryResponse, responseData)
      }else{
        swal("Error",responseData.responseMessage, 'error').then(r => console.log(r))
      }
    }).catch(error=>{
      console.log(error)
      StoreUtils.commit(StoreUtils.mutations.fundTransfer.updateLoading, false)
    })
  },




  // eslint-disable-next-line no-empty-pattern
  doFundTransfer({}, payload = new FundTransferRequest().fundTransfer){
    StoreUtils.commit(StoreUtils.mutations.fundTransfer.updateLoading, true)
    return FundTransferService.callFundTransferApi(payload).then(response=>{
      StoreUtils.commit(StoreUtils.mutations.fundTransfer.updateLoading, false)
      let responseData = response.data
      if (responseData.responseCode === "00"){
        swal('Success', responseData.responseMessage, 'success').then(()=>{
        })
      }else {
        swal('Error', responseData.responseMessage, 'error').then(r => console.log(r))
      }
    }).catch(error=>{
      console.log(error)
      StoreUtils.commit(StoreUtils.mutations.fundTransfer.updateLoading, false)
    })
  },

  // eslint-disable-next-line no-empty-pattern
  doFundsTransferCADNGN({}, payload = new FundTransferRequest().fundsTransferCADNGN){
    StoreUtils.commit(StoreUtils.mutations.fundTransfer.updateLoading, true)
    return FundTransferService.callFundsTransferCADNGNApi(payload).then(response=>{
      StoreUtils.commit(StoreUtils.mutations.fundTransfer.updateLoading, false)
      let responseData = response.data
      if (responseData.responseCode === "00"){
        swal('Success', responseData.responseMessage, 'success').then(()=>{
          StoreUtils.commit(StoreUtils.mutations.fundTransfer.updateFundsTransferCADNGN, responseData)
          RouterUtils.navigateTo(RouterUtils.routes.dashboard.homeView.name)
        })
      }else {
        swal('Error', responseData.responseMessage, 'error').then(r => console.log(r))
        // RouterUtils.navigateTo(RouterUtils.routes.dashboard.homeView.name)
      }
    }).catch(error=>{
      console.log(error)
      StoreUtils.commit(StoreUtils.mutations.fundTransfer.updateLoading, false)
    })
  },



  // eslint-disable-next-line no-empty-pattern
  doFundsTransferNGNNaira({}, payload = new FundTransferRequest().fundsTransferNGNNaira){
    StoreUtils.commit(StoreUtils.mutations.fundTransfer.updateLoading, true)
    return FundTransferService.callFundsTransferNGNNairaApi(payload).then(response=>{
      StoreUtils.commit(StoreUtils.mutations.fundTransfer.updateLoading, false)
      let responseData = response.data
      if (responseData.responseCode === "00"){
        swal('Success', responseData.responseMessage, 'success').then(()=>{
          // StoreUtils.commit(StoreUtils.mutations.fundTransfer.updateFundTransferRequest, responseData)
          RouterUtils.navigateTo(RouterUtils.routes.dashboard.homeView.name)
        })
      }else {
        swal('Error', responseData.responseMessage, 'error').then(r => console.log(r))
      }
    }).catch(error=>{
      console.log(error)
      StoreUtils.commit(StoreUtils.mutations.fundTransfer.updateLoading, false)
    })
  },

};
