import { render, staticRenderFns } from "./FundWalletView.vue?vue&type=template&id=3d40148e&scoped=true&"
import script from "./FundWalletView.vue?vue&type=script&lang=js&"
export * from "./FundWalletView.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3d40148e",
  null
  
)

export default component.exports