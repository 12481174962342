<template>
  <dashboard-layout>
    <div class="dashboard-body-wrapper align-center">
      <div class="kyc-nav-wrapper margin-vertical margin-large">
        <router-link to="/home-view">
          <a class="w-inline-block"><img src="@/assets/images/Navigation-controls.svg" loading="lazy" alt=""></a>
        </router-link>
      </div>
      <ConvertAmountCard/>
    </div>
  </dashboard-layout>
</template>
<script>
	//@ is an alias to /src
	import ConvertAmountCard from '@/components/card/ConvertAmountCard.vue'
  import DashboardLayout from "@/layout/DashboardLayout";
	export default{
			name: 'ConvertAmountView',
			components:{
        DashboardLayout,
				ConvertAmountCard,
			}
	}
</script>
<style scoped>

</style>
