<template>
  <div>
    <loginview/>
  </div>
</template>

<script>
// @ is an alias to /src

import Loginview from "@/views/Loginview";
export default {
  name: 'LandingPage',
  components: {
    Loginview,
  }
}
</script>
