<template>
  <dashboard-layout>
    <div class="login-form-wrapper lawrence">
      <ReviewKycCard/>
    </div>
  </dashboard-layout>
</template>
<script>
	//@ is an alias to /src
	import ReviewKycCard from '@/components/card/ReviewKycCard.vue'
  import DashboardLayout from "@/layout/DashboardLayout";
	export default{
			name: 'ReviewingKycView',
			components:{
        DashboardLayout,
				ReviewKycCard
			}
	}
</script>
<style scoped>
.lawrence {
  background-image: -webkit-gradient(linear, left top, left bottom, from(#1a1d27), color-stop(50%, #2f354b), to(#1a1d27));
  background-image: linear-gradient(180deg, #1a1d27, #2f354b 50%, #1a1d27);
}
</style>
