class RateResponse {
    activeRate = {
        responseCode: null,
        responseMessage: null,
        data: []
    };

    // data = {
    //     rateReference: null,
    //     rateBase: null,
    //     rateNGN: null,
    //     rateCAD: null,
    //     rateCreatedAt: null
    // };

    data = {
        rateId: null,
        rateReference: null,
        rateSell: null,
        rateBuyCurrency: null,
        rateSellCurrency: null,
        rateBuy: null,
        rateStatus: null,
        rateCreatedAt: null,
        rateUpdatedAt: null
    };

}

export default RateResponse;