import client  from "./BaseService";
import BeneficiaryResponse from "@/model/response/BeneficiaryResponse";

export default {
    callBeneficiaryCreateApi(payload) {
        return client.apiClient.post("beneficiary/create", payload);
    },
    callBeneficiaryReadApi() {
        return client.apiClient.post("beneficiary/read", {});
    },
    callBeneficiaryUpdateApi(payload) {
        return client.apiClient.post("beneficiary/update", payload);
    },
    callBeneficiaryReadByApi(payload) {
        return client.apiClient.post("beneficiary/read-by-beneficiary-id", payload);
    },
};