import AuthenticationRequest from "@/model/request/AuthenticationRequest";
import StoreUtils from "@/util/baseUtils/StoreUtils";
import AuthenticationService from "@/service/AuthenticationService";
import AuthenticationResponse from "@/model/response/AuthenticationResponse";
import RouterUtils from "@/util/baseUtils/RouterUtils";
import swal from "sweetalert";

export const state = {
  loading: false,
  userInfo: new AuthenticationResponse().login,
  token: null,
  screen: "signUp",
  signInFormData: null,
  signUpFormData: null,
  forgotPasswordFormData: null,
  accountState: "CAD",
  resetPin: null,
};

export const getters = {
  getToken: (state) => {
    return state.token ? state.token : localStorage.token;
  },
  getUserInfo: (state) => {
    // console.log("getter getTok")
    return state.userInfo;
  },
  getSignInFormData: (state) => {
    return state.signInFormData;
  },
  getSignUpFormData: (state) => {
    return state.signUpFormData;
  },
  getForgotPasswordFormData: (state) => {
    return state.forgotPasswordFormData;
  },
  getAccountState: (state) => {
    return state.accountState;
  },
  getResetPin: (state) => {
    return state.resetPin
  },
};

export const mutations = {
  updateLoading(state, payload){
    state.loading = payload
  },
  updateUserInfo(state, payload){
    state.userInfo = payload
  },
  updateToken(state, payload){
    state.token = payload
  },
  updateScreen(state, payload){
    state.screen = payload
  },
  updateSignInFormData(state, payload){
    state.signInFormData = payload
  },
  updateSignUpFormData(state, payload){
    state.signUpFormData = payload
  },
  updateForgotPasswordFormData(state, payload){
    state.forgotPasswordFormData = payload
  },
  updateAccountState(state, payload){
    state.accountState = payload
  },
  updateResetPin(state, payload){
    state.resetPin = payload
  }
};

export const actions = {
  // eslint-disable-next-line no-empty-pattern
  login({}, payload = new AuthenticationRequest().login){
    StoreUtils.commit(StoreUtils.mutations.auth.updateLoading, true)
    return AuthenticationService.callLoginApi(payload).then(response=>{
      StoreUtils.commit(StoreUtils.mutations.auth.updateLoading, false)
      let responseData = response.data

      if (responseData.responseCode === "00"){
        StoreUtils.commit(StoreUtils.mutations.auth.updateUserInfo, responseData)
        StoreUtils.commit(StoreUtils.mutations.auth.updateToken, responseData.token)
        StoreUtils.commit(StoreUtils.mutations.auth.updateUserInfo, responseData)
        localStorage.token = responseData.token

        RouterUtils.navigateTo(RouterUtils.routes.dashboard.homeView.name)
      }else {
        swal('Error', responseData.responseMessage, 'error')
      }

    }).catch(error=>{
      console.log(error)
      StoreUtils.commit(StoreUtils.mutations.auth.updateLoading, false)
    })
  },

  // eslint-disable-next-line no-empty-pattern
  initiateEnrollment({}, payload = new AuthenticationRequest().initiateEnrollment){
    StoreUtils.commit(StoreUtils.mutations.auth.updateLoading, true)
    return AuthenticationService.callInitiateEnrollmentApi(payload).then(response=>{
      StoreUtils.commit(StoreUtils.mutations.auth.updateLoading, false)
      let responseData = response.data
      if (responseData.responseCode === "00"){
        // swal("Success",responseData.responseMessage, 'success')
        //     .then(() => {
          RouterUtils.navigateTo(RouterUtils.routes.auth.signUpCreatePasscode.name)
        // })

      }else{
        swal("Error",responseData.responseMessage, 'error').then(r => console.log(r))
      }
    }).catch(error=>{
      console.log(error)
      StoreUtils.commit(StoreUtils.mutations.auth.updateLoading, false)
    })
  },

  // eslint-disable-next-line no-empty-pattern
  completeEnrollment({}, payload = new AuthenticationRequest().completeEnrollment){
    StoreUtils.commit(StoreUtils.mutations.auth.updateLoading, true)
    return AuthenticationService.callCompleteEnrollmentApi(payload).then(response=>{
      StoreUtils.commit(StoreUtils.mutations.auth.updateLoading, false)
      let responseData = response.data
      if (responseData.responseCode === "00"){
        swal("Success",responseData.responseMessage, 'success').then(() => {
          RouterUtils.navigateTo(RouterUtils.routes.auth.loginview.name)
        })

      }else{
        swal("Error",responseData.responseMessage, 'error').then(() => {
          RouterUtils.navigateTo(RouterUtils.routes.auth.signUpPersonalDetails.name)
        })
      }
    }).catch(error=>{
      console.log(error)
      StoreUtils.commit(StoreUtils.mutations.auth.updateLoading, false)
    })
  },

  // eslint-disable-next-line no-empty-pattern
  resendOtp({}, payload = new AuthenticationRequest().resendOtp){
    StoreUtils.commit(StoreUtils.mutations.auth.updateLoading, true)
    return AuthenticationService.callResendOtpApi(payload).then(response=>{
      StoreUtils.commit(StoreUtils.mutations.auth.updateLoading, false)
      let responseData = response.data
      if (responseData.responseCode === "00"){
        swal("Success",responseData.responseMessage, 'success').then(() => {
          // RouterUtils.navigateTo(RouterUtils.routes.auth.forgotPasswordVerifyEmail.name)
        })

      }else{
        swal("Error",responseData.responseMessage, 'error').then(r => console.log(r))
      }
    }).catch(error=>{
      console.log(error)
      StoreUtils.commit(StoreUtils.mutations.auth.updateLoading, false)
    })
  },


  // eslint-disable-next-line no-empty-pattern
  resetPin({}, payload = new AuthenticationRequest().resetPin){
    StoreUtils.commit(StoreUtils.mutations.auth.updateLoading, true)
    return AuthenticationService.callResetPinApi(payload).then(response=>{
      StoreUtils.commit(StoreUtils.mutations.auth.updateLoading, false)
      let responseData = response.data
      if (responseData.responseCode === "00"){
        swal("Success",responseData.responseMessage, 'success').then(() => {
          RouterUtils.navigateTo(RouterUtils.routes.dashboard.homeView.name)
        })

      }else{
        swal("Error",responseData.responseMessage, 'error').then(r => console.log(r))
      }
    }).catch(error=>{
      console.log(error)
      StoreUtils.commit(StoreUtils.mutations.auth.updateLoading, false)
    })
  },



  // remember to change the route to the required one
  // eslint-disable-next-line no-empty-pattern
  initiatePasswordReset({}, payload = new AuthenticationRequest().initiateResetPassword){
    StoreUtils.commit(StoreUtils.mutations.auth.updateLoading, true)
    return AuthenticationService.callInitiatePasswordResetApi(payload).then(response=>{
      StoreUtils.commit(StoreUtils.mutations.auth.updateLoading, false)
      let responseData = response.data
      if (responseData.responseCode === "00"){
        swal("Success",responseData.responseMessage, 'success').then(() => {
          RouterUtils.navigateTo(RouterUtils.routes.auth.forgotPasswordVerifyEmail.name)
        })

      }else{
        swal("Error",responseData.responseMessage, 'error').then(r => console.log(r))
      }
    }).catch(error=>{
      console.log(error)
      StoreUtils.commit(StoreUtils.mutations.auth.updateLoading, false)
    })
  },

  // remember to change the route to the required one
  // eslint-disable-next-line no-empty-pattern
   completePasswordReset({}, payload = new AuthenticationRequest().completeResetPassword){
     StoreUtils.commit(StoreUtils.mutations.auth.updateLoading, true)
    return AuthenticationService.callCompletePasswordResetApi(payload).then( response => {
      StoreUtils.commit(StoreUtils.mutations.auth.updateLoading, false)
      let responseData = response.data
      if (responseData.responseCode === "00") {
         swal("Success", responseData.responseMessage, 'success').then(() => {
           RouterUtils.navigateTo(RouterUtils.routes.auth.accountCreationSuccess.name)
        })

      } else {
        swal("Error", responseData.responseMessage, 'error').then(() => {
          RouterUtils.navigateTo(RouterUtils.routes.auth.accountCreationError.name)
        })
      }
    }).catch(error=>{
      console.log(error)
      StoreUtils.commit(StoreUtils.mutations.auth.updateLoading, false)
    })
  },

  // remember to change the route to the required one
  // eslint-disable-next-line no-empty-pattern
  changePassword({}, payload = new AuthenticationRequest().changePassword){
    StoreUtils.commit(StoreUtils.mutations.auth.updateLoading, true)
    return AuthenticationService.callChangePasswordApi(payload).then(response=>{
      StoreUtils.commit(StoreUtils.mutations.auth.updateLoading, false)
      let responseData = response.data
      if (responseData.responseCode === "00"){
        swal("Success",responseData.responseMessage, 'success').then(() => {
          // RouterUtils.navigateTo(RouterUtils.routes.auth.login)
        })

      }else{
        swal("Error",responseData.responseMessage, 'error').then(r => console.log(r))
      }
    }).catch(error=>{
      console.log(error)
      StoreUtils.commit(StoreUtils.mutations.auth.updateLoading, false)
    })
  },

  //eslint-disable-next-line no-empty-pattern
  getUserDetails({}, payload = {}){
    StoreUtils.commit(StoreUtils.mutations.auth.updateLoading, true)
    return AuthenticationService.callUserDetailsApi(payload).then(response=>{
      StoreUtils.commit(StoreUtils.mutations.auth.updateLoading, false)
      let responseData = response.data
      if (responseData.responseCode === '00'){
        StoreUtils.commit(StoreUtils.mutations.auth.updateUserInfo, responseData)
      }else{
        RouterUtils.navigateTo(RouterUtils.routes.auth.loginview.name)
      }
    }).catch(error=>{
      console.log(error)
      StoreUtils.commit(StoreUtils.mutations.auth.updateLoading, false)
    })
  }
};
