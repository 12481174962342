import swal from "sweetalert";
import StoreUtils from "@/util/baseUtils/StoreUtils";
import RateResponse from "@/model/response/RateResponse";
import RateService from "@/service/RateService";



export const state = {
    loading: false,
    activeRate: new RateResponse().activeRate,
};

export const getters = {
    getActiveRate: (state) => {
        return state.activeRate;
    },
};

export const mutations = {
    updateLoading(state, payload){
        state.loading = payload
    },
    updateActiveRate(state, payload){
        state.activeRate = payload
    },
};


export const actions = {

    // eslint-disable-next-line no-empty-pattern
    // fetchActiveRate({}, payload = {}){
    //     StoreUtils.commit(StoreUtils.mutations.rate.updateLoading, true)
    //     return RateService.callActiveRateApi(payload).then(response=>{
    //         StoreUtils.commit(StoreUtils.mutations.rate.updateLoading, false)
    //         let responseData = response.data
    //         if (responseData.responseCode === '00'){
    //             StoreUtils.commit(StoreUtils.mutations.activeRate, responseData.activeRate)
    //         }else{
    //             swal("Error",responseData.responseMessage, 'error').then(r => console.log(r))
    //         }
    //     }).catch(error=>{
    //         console.log(error)
    //         StoreUtils.commit(StoreUtils.mutations.rate.updateLoading, false)
    //     })
    // },

    //eslint-disable-next-line no-empty-pattern
    fetchActiveRate({}, payload = {}){
        // StoreUtils.commit(StoreUtils.mutations.rate.updateLoading, true)
        return RateService.callActiveRateApi(payload).then(response=>{
            // StoreUtils.commit(StoreUtils.mutations.rate.updateLoading, false)
            let responseData = response.data
            if (responseData.responseCode === '00'){
                StoreUtils.commit(StoreUtils.mutations.rate.updateActiveRate, responseData)
            }else{
                swal("Error",responseData.responseMessage, 'error').then(r => console.log(r))
            }
        }).catch(error=>{
            console.log(error)
            // StoreUtils.commit(StoreUtils.mutations.rate.updateLoading, false)
        })
    },

};