<template>
  <div id="app">
<!--    <transition name="slide-fade" mode="out-in">-->
    <router-view/>
<!--    </transition>-->


  </div>
</template>

<script>

export default {
  name: "app",
}
</script>

<style>
@import "assets/css/webflow.css";
@import "assets/css/normalize.css";
@import "assets/css/rubies.webflow.css";
a{
  text-decoration: none;
  cursor: pointer;
}

.slide-fade-enter {
  opacity: 0;
  transform: translateY(10px);
}

.slide-fade-enter-to {
}

.slide-fade-enter-active {
  transition: all 0.4s ease-in;
}

.slide-fade-leave {
}

.slide-fade-leave-to {
  opacity: 0;
  transform: translateY(10px);
}

.slide-fade-leave-active {
  transition: all 0.4s ease-out;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
</style>
