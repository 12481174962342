<template>
		<section id="AddressKycForm" class="address-form">
            <section class="kyc-title-wrapper">
              <div class="margin-bottom margin-xxsmall">
                <div class="text-block-53">Address verification</div>
              </div>
              <div class="text-block-54">Kindly provide details about your home address</div>
            </section>
    </section>
</template>
<script>
	export default {
	name: "AddressKycForm"
	};
</script>
<style scoped lang="scss">
   h3 {margin: 40px 0 0; }
   ul {list-style-type: none; padding: 0; }
   li {display: inline-block; margin: 0 10px; }
</style>

