import swal from "sweetalert";
import StoreUtils from "@/util/baseUtils/StoreUtils";
import BeneficiaryRequest from "@/model/request/BeneficiaryRequest";
import BeneficiaryResponse from "@/model/response/BeneficiaryResponse";
import BeneficiaryService from "@/service/BeneficiaryService";
// import RouterUtils from "@/util/baseUtils/RouterUtils";




export const state = {
    loading: false,
    beneficiary: [],
    beneficiaries: new BeneficiaryResponse().read,
    readByBeneficiaryId: new BeneficiaryResponse().readBy,
};

export const getters = {
    getBeneficiary: (state) => {
        return state.beneficiary;
    },
    getBeneficiaries: (state) => {
        return state.beneficiaries;
    },
    getReadByBeneficiaryId: (state) => {
        return state.readByBeneficiaryId;
    }
};


export const mutations = {
    updateLoading(state, payload){
        state.loading = payload
    },
    updateBeneficiary(state, payload){
        state.beneficiary = payload
    },
    updateBeneficiaries(state, payload){
        state.beneficiaries = payload
    },
    updateReadByBeneficiaryId(state, payload){
        state.readByBeneficiaryId = payload
    },
};

export const actions = {
    // eslint-disable-next-line no-empty-pattern
    beneficiaryCreate({}, payload = new BeneficiaryRequest().create){
        StoreUtils.commit(StoreUtils.mutations.beneficiary.updateLoading, true)
        return BeneficiaryService.callBeneficiaryCreateApi(payload).then(response=>{
            StoreUtils.commit(StoreUtils.mutations.beneficiary.updateLoading, false)
            let responseData = response.data
            if (responseData.responseCode === "00"){
                swal("Beneficiary saved",responseData.responseMessage, 'success').then(r => console.log(r))
                // RouterUtils.navigateTo(RouterUtils.routes.kyc.reviewingKyc.name)
            }else{
                swal("Error saving Beneficiary",responseData.responseMessage, 'error').then(r => console.log(r))
            }
        }).catch(error=>{
            console.log(error)
            StoreUtils.commit(StoreUtils.mutations.beneficiary.updateLoading, false)
        })
    },

    // eslint-disable-next-line no-empty-pattern
    beneficiaryUpdate({}, payload = new BeneficiaryRequest().update){
        StoreUtils.commit(StoreUtils.mutations.beneficiary.updateLoading, true)
        return BeneficiaryService.callBeneficiaryUpdateApi(payload).then(response=>{
            StoreUtils.commit(StoreUtils.mutations.beneficiary.updateLoading, false)
            let responseData = response.data
            if (responseData.responseCode === "00"){
                swal("Success",responseData.responseMessage, 'success').then(r => console.log(r))
            }else{
                swal("Error",responseData.responseMessage, 'error').then(r => console.log(r))
            }
        }).catch(error=>{
            console.log(error)
            StoreUtils.commit(StoreUtils.mutations.beneficiary.updateLoading, false)
        })
    },

    // eslint-disable-next-line no-empty-pattern
    beneficiaryReadBy({}, payload = new BeneficiaryRequest().readBy){
        StoreUtils.commit(StoreUtils.mutations.beneficiary.updateLoading, true)
        return BeneficiaryService.callBeneficiaryReadByApi(payload).then(response=>{
            StoreUtils.commit(StoreUtils.mutations.beneficiary.updateLoading, false)
            let responseData = response.data
            if (responseData.responseCode === "00"){
                // StoreUtils.commit(StoreUtils.mutations.beneficiaries, responseData.beneficiaries)
                StoreUtils.commit(StoreUtils.mutations.beneficiary.updateReadByBeneficiaryId, responseData)
            }else{
                swal("Error",responseData.responseMessage, 'error').then(r => console.log(r))
            }
        }).catch(error=>{
            console.log(error)
            StoreUtils.commit(StoreUtils.mutations.beneficiary.updateLoading, false)
        })
    },

    // eslint-disable-next-line no-empty-pattern
    beneficiaryRead({}, payload = {}){
        StoreUtils.commit(StoreUtils.mutations.beneficiary.updateLoading, true)
        return BeneficiaryService.callBeneficiaryReadApi(payload).then(response=>{
            StoreUtils.commit(StoreUtils.mutations.beneficiary.updateLoading, false)
            let responseData = response.data
            if (responseData.responseCode === '00'){
                // StoreUtils.commit(StoreUtils.mutations.transactions, responseData.transactions)
                StoreUtils.commit(StoreUtils.mutations.beneficiary.updateBeneficiaries, responseData)
            }else{
                swal("Error",responseData.responseMessage, 'error').then(r => console.log(r))
            }
        }).catch(error=>{
            console.log(error)
            StoreUtils.commit(StoreUtils.mutations.beneficiary.updateLoading, false)
        })
    },

};