import router from "../../router/index";


class RouterUtils {
  static routes = {
    base: {
      home: {
        path: '/',
        name: 'LandingPage'
      },
      // createEntity: {
      //   path: '/createEntity',
      //   name: 'createEntity'
      // },
    },
    auth: {
      signUpCreatePasscode: {
        path: '/sign-up-create-passcode',
        name: 'signUpCreatePasscode'
      },
      signUpPersonalDetails: {
        path: '/sign-up-personal-details',
        name: 'signUpPersonalDetails'
      },
      signUpVerifyPhoneNumber: {
        path: '/sign-up-verify-phoneNumber',
        name: 'signUpVerifyPhoneNumber'
      },
      signupview: {
        path: '/sign-up-view',
        name: 'signupview'
      },
      loginview: {
        path: '/log-in-view',
        name: 'loginview'
      },
      newsletterSettings: {
        path: '/newsletter-settings',
        name: 'newsletterSettings'
      },
      forgotPasswordVerifyEmail: {
        path: '/forgot-password-verify-email',
        name: 'forgotPasswordVerifyEmail'
      },
      forgotPasswordResetPasscode: {
        path: '/forgot-password-reset-passcode',
        name: 'forgotPasswordResetPasscode'
      },
      forgotPassword: {
        path: '/forgot-password',
        name: 'forgotPassword'
      },
      //confirm from anthony
      accountCreationError: {
        path: '/account-creation-error',
        name: 'accountCreationError'
      },
      accountCreationSuccess: {
        path: '/account-creation-success',
        name: 'accountCreationSuccess'
      },
      //confirm from anthony
    },
    kyc: {
      accountInformation: {
        path: '/account-information',
        name: 'accountInformation'
      },
      addressVerification: {
        path: '/address-verification',
        name: 'addressVerification'
      },
      identityVerification: {
        path: '/identity-verification',
        name: 'identityVerification'
      },
      kycVerification: {
        path: '/kyc-verification',
        name: 'kycVerification'
      },
      reviewingKycId: {
        path: '/reviewing-kyc-id',
        name: 'reviewingKycId'
      },
      reviewingKyc: {
        path: '/reviewing-kyc',
        name: 'reviewingKyc'
      },
      takeSelfie: {
        path: '/take-selfie',
        name: 'takeSelfie'
      },
      updateKycStep: {
        path: '/update-kyc-step',
        name: 'updateKycStep'
      },
    },
    dashboard: {
      addRecipient: {
        path: '/add-recipient',
        name: 'addRecipient'
      },
      convertAmount: {
        path: '/convert-amount',
        name: 'convertAmount'
      },
      fundWallet: {
        path: '/fund-wallet',
        name: 'fundWallet'
      },
      settings: {
        path: '/settings',
        name: 'settings'
      },
      loginAndSecurity: {
        path: '/login-and-security',
        name: 'loginAndSecurity'
      },
      homeView: {
        path: '/home-view',
        name: 'HomeView'
      },
      interacFunding: {
        path: '/interac-funding',
        name: 'interacFunding'
      },
      sendMoney: {
        path: '/send-money',
        name: 'sendMoney'
      },
      transaction: {
        path: '/transaction',
        name: 'TRANSACTION'
      },
      referalHome: {
        path: '/referal-home',
        name: 'referalHome'
      },
      myProfile: {
        path: '/my-profile',
        name: 'myProfile'
      },
      notifications: {
        path: '/notifications',
        name: 'notifications'
      },
      transferAmount: {
        path: '/transfer-amount',
        name: 'transferAmount'
      },
    }
  };

  static pageTitles = {
    DEFAULT: "Ruby Loan",
    accountingEntry: {
      CREATE: "Assign Entries",
      EDIT: "Edit Entries"
    }
  };
  static navigateTo(targetRoute) {
    router.push({
      name: targetRoute
    })
  }
  static goBack() {
    router.go(-1);
  }
}
export default RouterUtils;

