<template>
  <section id="KycBackCard" class="kyc-nav-wrapper margin-vertical margin-large">
    <router-link to="/kyc-verification">
      <a class="w-inline-block"><img src="@/assets/images/Navigation-controls.svg" loading="lazy" alt=""></a>
    </router-link>
  </section>
</template>
<script>


  export default {
	name: "KycBackCard",

	};


</script>
<style scoped lang="scss">
   h3 {margin: 40px 0 0; }
   ul {list-style-type: none; padding: 0; }
   li {display: inline-block; margin: 0 10px; }
</style>

