<template>
  <auth-layout>
    <login-form/>
  </auth-layout>
</template>
<script>
	//@ is an alias to /src
	import LoginForm from '@/components/form/LoginForm.vue'
  import {mapState} from "vuex";
  import AuthLayout from "@/layout/AuthLayout";
	export default{
			name: 'Loginview',
			components:{
        AuthLayout,
				LoginForm
			},
    computed:{
      ...mapState({
        loading: state => state.auth.loading,
        screen: state => state.auth.screen,
        auth: state => state.auth
      })
    }
	}
</script>
<style scoped>

</style>
