<template>
  <auth-layout>
    <forgot-password-form/>
  </auth-layout>
</template>
<script>
	//@ is an alias to /src
	import ForgotPasswordForm from '@/components/form/ForgotPasswordForm.vue'
  import AuthLayout from "@/layout/AuthLayout";
	export default{
			name: 'ForgotPasswordView',
			components:{
        AuthLayout,
				ForgotPasswordForm
			}
	}
</script>
<style scoped>

</style>
