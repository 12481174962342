<template>
  <dashboard-layout>
    <InteracFundingCard/>
  </dashboard-layout>
</template>
<script>
	//@ is an alias to /src
	import InteracFundingCard from '@/components/card/InteracFundingCard.vue'
  import DashboardLayout from "@/layout/DashboardLayout";
	export default{
			name: 'InteracFundingView',
			components:{
        DashboardLayout,
				InteracFundingCard
			}
	}
</script>
<style scoped>
</style>
