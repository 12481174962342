class TransactionRequest {

    create = {
        transactionRateId : null,
        transactionReference : null,
        transactionUserId : null,
        transactionType : null,
        transactionBankName : null,
        transactionBankCode : null,
        transactionAmount : null,
        transactionBeneficiaryAmount : null,
        transactionBeneficiaryName : null
    };

    update = {
        transactionId: null,
        transactionRateId: null,
        transactionReference: null,
        transactionUserId: null,
        transactionType: null,
        transactionBankName: null,
        transactionBankCode: null,
        transactionAmount: null,
        transactionBeneficiaryAmount: null,
        transactionBeneficiaryName: null
    };

    readBy = {
        transactionId :  null
    };

    readByTransactionCustomerId = {
        transactionCustomerId :  null
    };

    readAllWalletTransaction = {
        searchItem: null,
        startDate: null,//YYYY-MM-DD eg. 2023-02-05
        endDate: null,//YYYY-MM-DD eg. 2023-02-05
        page: 1
    };

}

export default TransactionRequest;