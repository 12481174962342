<template>

		<section id="BalanceCard" class="balance-wrapper margin-top margin-large">
            <div class="balance heading-style-h2" v-if="this.accountState === 'CAD'">&#36;{{ userInfo.accounts[0].accountBalance | formatAmount }}</div>
      <div class="balance heading-style-h2" v-else-if="this.accountState === 'NGN'">&#8358;{{ userInfo.accounts[1].accountBalance | formatAmount }}</div>
      <div class="balance heading-style-h2" v-else>&#8358;0.00</div>

            <a data-w-id="b4a46994-6187-287f-f799-9f553a35b0df" v-if="this.accountState === 'CAD'" href="#" @click="showDialog" class="link-block w-inline-block">
              <img src="@/assets/images/CAD.svg" loading="lazy" width="16" alt="">
              <div class="dropdown-text dashboard text-size-small text-weight-medium">CAD Account</div>
              <img src="@/assets/images/arrow-down.svg" loading="lazy" width="16" alt="">
            </a>

      <a data-w-id="b4a46994-6187-287f-f799-9f553a35b0df" v-else-if="this.accountState === 'NGN'" href="#" @click="showDialog" class="link-block w-inline-block">
        <img src="@/assets/images/Nigeria-NG.svg" loading="lazy" width="16" alt="">
        <div class="dropdown-text dashboard text-size-small text-weight-medium">NGN Account</div>
        <img src="@/assets/images/arrow-down.svg" loading="lazy" width="16" alt="">
      </a>

      <a data-w-id="b4a46994-6187-287f-f799-9f553a35b0df" v-else href="#" @click="showDialog" class="link-block w-inline-block">
        <img src="@/assets/images/CAD.svg" loading="lazy" width="16" alt="">
        <div class="dropdown-text dashboard text-size-small text-weight-medium">CAD Account</div>
        <img src="@/assets/images/arrow-down.svg" loading="lazy" width="16" alt="">
      </a>

      <BalanceModalCard @close="hideDialog" v-if="dialogIsVisible"/>

          </section>
</template>
<script>
	import BalanceModalCard from "@/components/card/BalanceModalCard";
  import {mapState} from "vuex";
  export default {
	name: "BalanceCard",
    components: {BalanceModalCard},
    data() {
      return {
        dialogIsVisible: false,
      };
    },

    computed: {
      ...mapState({
        loading: state => state.address.loading,
        auth: state => state.auth,
        userInfo: state => state.auth.userInfo,
        accountState: state => state.auth.accountState
      }),
    },

    methods: {
      hideDialog() {
        this.dialogIsVisible = false;
      },
      showDialog() {
        this.dialogIsVisible = true;
      },
    },
	};
</script>
<style scoped lang="scss">
   h3 {margin: 40px 0 0; }
   ul {list-style-type: none; padding: 0; }
   li {display: inline-block; margin: 0 10px; }
</style>

