<template>
  <section id="SendMoneyCard" class="add-money-banner">
            <div class="add-money-wrapper">
              <div>
                <div class="text-block-58">Send money</div>
                <div class="text-block-59">Add money to your Rubies wallet</div>
              </div>
              <div>
                <img src="@/assets/images/dollar-transfer-1.png" loading="lazy" width="124" alt="" class="send-money">
              </div>
            </div>
          </section>
</template>
<script>
	export default {
	name: "SendMoneyCard"
	};
</script>
<style scoped lang="scss">
   h3 {margin: 40px 0 0; }
   ul {list-style-type: none; padding: 0; }
   li {display: inline-block; margin: 0 10px; }
   a {color: #42b983;}
</style>

