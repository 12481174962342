class KycAddressResponse {
    read = {
    responseCode: null,
    responseMessage: null,
        data:[]
    };


    getProvinces = {
        dataProvinces: [],
        responseCode: null,
        responseMessage: null,
    }


    readBy = {
    responseCode: null,
    responseMessage: null,
    data: {
        addressId: null,
        addressCustomerId: null,
        addressNumber: null,
        addressStreet: null,
        addressCity: null,
        addressState: null,
        addressCountry: null,
        addressStatus: null,
        addressCreatedAt: null,
        addressUpdatedAt: null
    }
};

    readByCustomerId = {
    responseCode: null,
    responseMessage: null,
    data:
        {
            addressId: null,
            addressCustomerId: null,
            addressNumber: null,
            addressStreet: null,
            addressCity: null,
            addressState: null,
            addressCountry: null,
            addressStatus: null,
            addressCreatedAt: null,
            addressUpdatedAt: null
        }
    };

    data = {
        addressId: null,
        addressUserId: null,
        addressNumber: null,
        addressStreet: null,
        addressCity: null,
        addressState: null,
        addressCountry: null,
        addressStatus: null,
        addressCreatedAt: null,
        addressUpdatedAt: null
    }

    dataProvinces = {
        city: [],
        province: null,
        town: []
    }

}

export default KycAddressResponse;