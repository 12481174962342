<template>
  <section id="ForgotPasswordForm" class="container-small">
            <div class="image-wrapper"><img src="@/assets/images/Group-22264.svg" loading="lazy" alt=""></div>
    <br/>
            <div class="form-wrapper">
              <div class="form-header-wrapper">
                <div class="margin-top margin-xlarge">
                  <h3>Forgot password</h3>
                </div>
                <div class="margin-top margin-xsmall">
                  <p class="text-weight-medium text-color-grey">Please provide us the email you used to create your Rubies account</p>
                </div>
              </div>

              <form class="form-body-wrapper" @submit.prevent="initiateResetPassword" >
                <div class="margin-top margin-large">
                  <div class="form-fields-wrapper">
                    <div class="margin-top">
                      <div class="margin-bottom margin-small">
                        <div class="phone-input-field">
                          <input type="email" v-model="model.username" class="input-field w-input" maxlength="256" name="field" data-name="Field" placeholder="Enter Interac Email" id="field" required=""></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="margin-top margin-large">
                  <div class="form-fields-wrapper">
                    <div class="margin-top">
                      <div class="margin-bottom margin-small">

<!--                        <button class="button max-width-full w-button" >Proceed</button>-->
                        <base-button :loading="loading">Proceed</base-button>
                      </div>
                    </div>
                  </div>
                </div>
              </form>

            </div>
          </section>
</template>
<script>
	import AuthenticationRequest from "@/model/request/AuthenticationRequest";
  import StoreUtils from "@/util/baseUtils/StoreUtils";
  import {mapState} from "vuex";
  import BaseButton from "@/components/button/BaseButton";

  export default {
	name: "ForgotPasswordForm",
    components: {BaseButton},
    data(){
      return {
        model: new AuthenticationRequest().initiateResetPassword
      }
    },
    methods:{
      initiateResetPassword(){
        StoreUtils.dispatch(StoreUtils.actions.auth.initiatePasswordReset, this.model)
        StoreUtils.commit(StoreUtils.mutations.auth.updateSignInFormData, this.model)
      },
    },
    computed:{
      ...mapState({
        loading: state => state.auth.loading,
        screen: state => state.auth.screen,
        auth: state => state.auth
      })
    }
	};
</script>
<style scoped>

</style>

