import swal from "sweetalert";
import StoreUtils from "@/util/baseUtils/StoreUtils";
import TransactionResponse from "@/model/response/TransactionResponse";
import TransactionRequest from "@/model/request/TransactionRequest";
import TransactionService from "@/service/TransactionService";



export const state = {
    loading: false,
    transaction: null,
    transactions: new TransactionResponse().read,
    readByTransactionCustomerId: new TransactionResponse().readByTransactionCustomerId,
    getBanks: new TransactionResponse().getBanks,
    readAllWalletTransaction: new TransactionResponse().readAllWalletTransaction
};

export const getters = {
    getTransaction: (state) => {
        return state.transaction;
    },
    getTransactions: (state) => {
        return state.transactions;
    },
    getReadByTransactionCustomerId: (state) => {
        return state.readByTransactionCustomerId;
    },
    getGetBanks: (state) => {
        return state.getBanks;
    },
    getReadAllWalletTransaction: (state) => {
        return state.readAllWalletTransaction;
    },
};


export const mutations = {
    updateLoading(state, payload){
        state.loading = payload
    },
    updateTransaction(state, payload){
        state.transaction = payload
    },
    updateTransactions(state, payload){
        state.transactions = payload
    },
    updateReadByTransactionCustomerId(state, payload){
        state.readByTransactionCustomerId = payload
    },
    updateGetBanks(state, payload){
        state.getBanks = payload
    },
    updateReadAllWalletTransaction(state, payload){
        state.readAllWalletTransaction = payload
    },
};


export const actions = {
    // eslint-disable-next-line no-empty-pattern
    transactionCreate({}, payload = new TransactionRequest().create){
        StoreUtils.commit(StoreUtils.mutations.transaction.updateLoading, true)
        return TransactionService.callTransactionCreateApi(payload).then(response=>{
            StoreUtils.commit(StoreUtils.mutations.transaction.updateLoading, false)
            let responseData = response.data
            if (responseData.responseCode === "00"){
                swal("Success",responseData.responseMessage, 'success').then(r => console.log(r))
                // RouterUtils.navigateTo(RouterUtils.routes.kyc.reviewingKyc.name)
            }else{
                swal("Error",responseData.responseMessage, 'error').then(r => console.log(r))
            }
        }).catch(error=>{
            console.log(error)
            StoreUtils.commit(StoreUtils.mutations.transaction.updateLoading, false)
        })
    },

    // eslint-disable-next-line no-empty-pattern
    transactionUpdate({}, payload = new TransactionRequest().update){
        StoreUtils.commit(StoreUtils.mutations.transaction.updateLoading, true)
        return TransactionService.callTransactionUpdateApi(payload).then(response=>{
            StoreUtils.commit(StoreUtils.mutations.transaction.updateLoading, false)
            let responseData = response.data
            if (responseData.responseCode === "00"){
                swal("Success",responseData.responseMessage, 'success').then(r => console.log(r))
            }else{
                swal("Error",responseData.responseMessage, 'error').then(r => console.log(r))
            }
        }).catch(error=>{
            console.log(error)
            StoreUtils.commit(StoreUtils.mutations.transaction.updateLoading, false)
        })
    },

    // eslint-disable-next-line no-empty-pattern
    transactionReadBy({}, payload = new TransactionRequest().readBy){
        StoreUtils.commit(StoreUtils.mutations.transaction.updateLoading, true)
        return TransactionService.callTransactionReadByApi(payload).then(response=>{
            StoreUtils.commit(StoreUtils.mutations.transaction.updateLoading, false)
            let responseData = response.data
            if (responseData.responseCode === "00"){
                StoreUtils.commit(StoreUtils.mutations.transactions, responseData.transactions)
            }else{
                swal("Error",responseData.responseMessage, 'error').then(r => console.log(r))
            }
        }).catch(error=>{
            console.log(error)
            StoreUtils.commit(StoreUtils.mutations.transaction.updateLoading, false)
        })
    },

    // eslint-disable-next-line no-empty-pattern
    transactionRead({}, payload = {}){
        StoreUtils.commit(StoreUtils.mutations.transaction.updateLoading, true)
        return TransactionService.callTransactionReadApi(payload).then(response=>{
            StoreUtils.commit(StoreUtils.mutations.transaction.updateLoading, false)
            let responseData = response.data
            if (responseData.responseCode === '00'){
                StoreUtils.commit(StoreUtils.mutations.transactions, responseData.transactions)
            }else{
                swal("Error",responseData.responseMessage, 'error').then(r => console.log(r))
            }
        }).catch(error=>{
            console.log(error)
            StoreUtils.commit(StoreUtils.mutations.transaction.updateLoading, false)
        })
    },

    // eslint-disable-next-line no-empty-pattern
    transactionReadByTransactionCustomerId({}, payload = new TransactionRequest().readByTransactionCustomerId){
        StoreUtils.commit(StoreUtils.mutations.transaction.updateLoading, true)
        return TransactionService.callReadByTransactionCustomerIdApi(payload).then(response=>{
            StoreUtils.commit(StoreUtils.mutations.transaction.updateLoading, false)
            let responseData = response.data
            if (responseData.responseCode === "00"){
                StoreUtils.commit(StoreUtils.mutations.transaction.updateReadByTransactionCustomerId, responseData)
            }else{
                swal("Error",responseData.responseMessage, 'error').then(r => console.log(r))
            }
        }).catch(error=>{
            console.log(error)
            StoreUtils.commit(StoreUtils.mutations.transaction.updateLoading, false)
        })
    },


    // eslint-disable-next-line no-empty-pattern
    transactionReadAllWalletTransaction({}, payload = new TransactionRequest().readAllWalletTransaction){
        StoreUtils.commit(StoreUtils.mutations.transaction.updateLoading, true)
        return TransactionService.callReadAllWalletTransactionApi(payload).then(response=>{
            StoreUtils.commit(StoreUtils.mutations.transaction.updateLoading, false)
            let responseData = response.data
            if (responseData.responseCode === "00"){
                StoreUtils.commit(StoreUtils.mutations.transaction.updateReadAllWalletTransaction, responseData)
            }else{
                swal("Error",responseData.responseMessage, 'error').then(r => console.log(r))
            }
        }).catch(error=>{
            console.log(error)
            StoreUtils.commit(StoreUtils.mutations.transaction.updateLoading, false)
        })
    },


    //eslint-disable-next-line no-empty-pattern
    fetchGetBanks({}, payload = {}){
        StoreUtils.commit(StoreUtils.mutations.transaction.updateLoading, true)
        return TransactionService.callGetBanksApi(payload).then(response=>{
            StoreUtils.commit(StoreUtils.mutations.transaction.updateLoading, false)
            let responseData = response.data
            if (responseData.responseCode === '00'){
                StoreUtils.commit(StoreUtils.mutations.transaction.updateGetBanks, responseData)
            }else{
                swal("Error",responseData.responseMessage, 'error').then(r => console.log(r))
            }
        }).catch(error=>{
            console.log(error)
            StoreUtils.commit(StoreUtils.mutations.transaction.updateLoading, false)
        })
    },


};