<template>
		<section id="IdentityKycCard">
            <div class="kyc-title-wrapper">
              <div class="margin-bottom margin-xxsmall">
                <div class="text-block-53">Identity verification</div>
              </div>
              <div class="text-block-54">Kindly provide your personal information &amp; ID.</div>
            </div>
            <div class="margin-top margin-large">
              <div class="kyc-form-wrapper">

                <a class="id-verification-links w-inline-block" @click="showDialog">
                  <div class="left-block"><img src="@/assets/images/Component-2.svg" loading="lazy" alt="">
                    <div class="text-block-55">Upload ID</div>
                  </div>
                  <div class="right-block"><img src="@/assets/images/Component-3.svg" loading="lazy" alt=""></div>
                </a>

                <base-modal @close="hideDialog" v-if="dialogIsVisible"/>

                <router-link to="/take-selfie">
                  <a class="id-verification-links w-inline-block">
                    <div class="left-block"><img src="@/assets/images/Component-2.svg" loading="lazy" alt="">
                      <div class="text-block-55">Take a Selfie</div>
                    </div>
                    <div class="right-block"><img src="@/assets/images/Component-3.svg" loading="lazy" alt=""></div>
                  </a>
                </router-link>

                <router-link to="/account-information">
                  <a class="id-verification-links w-inline-block">
                    <div class="left-block"><img src="@/assets/images/Component-2.svg" loading="lazy" alt="">
                      <div class="text-block-55">Add account information</div>
                    </div>
                    <div class="right-block"><img src="@/assets/images/Component-3.svg" loading="lazy" alt=""></div>
                  </a>
                </router-link>

              </div>
            </div>
          </section>
</template>
<script>
	import BaseModal from "@/components/modal/BaseModal";
  export default {
	name: "IdentityKycCard",
    components: {BaseModal},
    data() {
      return {
        dialogIsVisible: false,
      };
    },

    methods: {
      hideDialog() {
        this.dialogIsVisible = false;
      },
      showDialog() {
        this.dialogIsVisible = true;
      },
    },
  };
</script>
<style scoped lang="scss">
   h3 {margin: 40px 0 0; }
   ul {list-style-type: none; padding: 0; }
   li {display: inline-block; margin: 0 10px; }
</style>

