class S3Request {
    uploadBase64() {
        return {
            username: "banner_2347849392789_1662737051442",
            base64: null,
            region: "us-east-1",
            source: "qucoon",
            s3bucket: "apvertise-repo"
        };
    }
}

export default S3Request;
