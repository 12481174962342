<template>
  <dashboard-layout>
    <div class="dashboard-body-wrapper align-center">
      <div class="back-button margin-vertical margin-large">
        <router-link to="/home-view">
          <a class="w-inline-block"><img src="@/assets/images/Navigation-controls.svg" loading="lazy" alt=""></a>
        </router-link>
      </div>
      <SendMoneyCard/>
      <SendToBeneficiaryCard/>
      <div class="margin-top margin-medium">
        <div>
          <SavedBeneficiaryCard/>
        </div>
      </div>
    </div>
  </dashboard-layout>
</template>
<script>
	//@ is an alias to /src
	import SendMoneyCard from '@/components/card/SendMoneyCard.vue'
	import SendToBeneficiaryCard from '@/components/card/SendToBeneficiaryCard.vue'
	import SavedBeneficiaryCard from '@/components/card/SavedBeneficiaryCard.vue'
  import DashboardLayout from "@/layout/DashboardLayout";
	export default{
			name: 'SendMoneyView',
			components:{
        DashboardLayout,
				SendMoneyCard,
				SendToBeneficiaryCard,
				SavedBeneficiaryCard
			}
	}
</script>
<style scoped>
</style>
