<!--suppress ALL -->
<template>
		<section id="AccountKycForm">
            <div class="kyc-title-wrapper">
              <div class="margin-bottom margin-xxsmall">
                <div class="text-block-53">Account information</div>
              </div>
              <div class="text-block-54">Kindly provide your account information</div>
            </div>

      <form class="margin-top margin-large" v-if="this.readByAccountInformationCustomerId.data.accountInformationStatus === 'VERIFIED'|| this.readByAccountInformationCustomerId.data.accountInformationStatus === 'PROCESSING'"  @submit.prevent="accountInformationCreate" >
              <div class="kyc-form-wrapper">

                <input type="number" class="input-field w-input" v-model="model.accountInformationBvn" :disabled="model === this.readByAccountInformationCustomerId.data" maxlength="256" name="field-3" data-name="Field 3" placeholder="Bank Verification Number" id="field-3" required="">
                <input type="number" class="input-field w-input" v-model="model.accountInformationMonthlyIncome" :disabled="model === this.readByAccountInformationCustomerId.data" maxlength="256" name="field-3" data-name="Field 3" placeholder="Monthly Income" id="field-3" required="">

                <select id="field" name="field" data-name="Field" class="input-field select-field w-select" v-model="model.accountInformationSourceOfIncome" :disabled="model === this.readByAccountInformationCustomerId.data" aria-required="required" aria-placeholder="Source of income">
                  <option :value="null" disabled>Source of income</option>
                  <option :value="item" v-for="item in this.readSourceOfIncome.data" v-bind:key="item">{{ item }}</option>
                </select>
              </div>


              <div class="margin-top margin-medium">
                <base-button :loading="loading" :disabled="model === this.readByAccountInformationCustomerId.data">Save information</base-button>
              </div>
            </form>

      <form class="margin-top margin-large" v-else-if="this.readByAccountInformationCustomerId.data.accountInformationStatus === 'PENDING'|| this.readByAccountInformationCustomerId.data.accountInformationStatus === 'FAILED'" @submit.prevent="accountInformationCreate" >
        <div class="kyc-form-wrapper">

          <input type="number" class="input-field w-input" v-model="model.accountInformationBvn"  maxlength="256" name="field-3" data-name="Field 3" placeholder="Bank Verification Number" id="field-3" required="">
          <input type="number" class="input-field w-input" v-model="model.accountInformationMonthlyIncome"  maxlength="256" name="field-3" data-name="Field 3" placeholder="Monthly Income" id="field-3" required="">

          <select id="field" name="field" data-name="Field" class="input-field select-field w-select" v-model="model.accountInformationSourceOfIncome"  aria-required="required" aria-placeholder="Source of income">
            <option :value="null" disabled>Source of income</option>
            <option :value="item" v-for="item in this.readSourceOfIncome.data" v-bind:key="item">{{ item }}</option>
          </select>
        </div>


        <div class="margin-top margin-medium">
          <base-button :loading="loading">Save information</base-button>
        </div>
      </form>

      <form class="margin-top margin-large" v-else @submit.prevent="accountInformationCreate" >
        <div class="kyc-form-wrapper">

          <input type="number" class="input-field w-input" v-model="model.accountInformationBvn"  maxlength="256" name="field-3" data-name="Field 3" placeholder="Bank Verification Number" id="field-3" required="">
          <input type="number" class="input-field w-input" v-model="model.accountInformationMonthlyIncome"  maxlength="256" name="field-3" data-name="Field 3" placeholder="Monthly Income" id="field-3" required="">

          <select id="field" name="field" data-name="Field" class="input-field select-field w-select" v-model="model.accountInformationSourceOfIncome"  aria-required="required" aria-placeholder="Source of income">
            <option :value="null" disabled>Source of income</option>
            <option :value="item" v-for="item in this.readSourceOfIncome.data" v-bind:key="item">{{ item }}</option>
          </select>
        </div>


        <div class="margin-top margin-medium">
          <base-button :loading="loading">Save information</base-button>
        </div>
      </form>

          </section>
</template>
<script>
  import StoreUtils from "@/util/baseUtils/StoreUtils";
  import {mapState} from "vuex";
  import AccountInformationRequest from "@/model/request/AccountInformationRequest";
  import BaseButton from "@/components/button/BaseButton";

  export default {
	name: "AccountKycForm",
    components: {BaseButton},
    data(){
      return {
        model: new AccountInformationRequest().create,
      }
    },
    methods:{
      accountInformationCreate(){
        StoreUtils.dispatch(StoreUtils.actions.accountInformation.accountInformationCreate, {
          accountInformationSourceOfIncome: this.model.accountInformationSourceOfIncome,
          accountInformationMonthlyIncome: this.model.accountInformationMonthlyIncome,
          accountInformationStatus: "PENDING",
          accountInformationBvn: this.model.accountInformationBvn
        })
      }
    },
    computed:{
      ...mapState({
        loading: state => state.accountInformation.loading,
        auth: state => state.auth,
        userInfo: state => state.auth.userInfo,
        account: state => state.accountInformation.account,
        accounts: state => state.accountInformation.accounts,
      }),

      readByAccountInformationCustomerId() {
        return StoreUtils.rootGetters(StoreUtils.getters.accountInformation.getReadByAccountInformationCustomerId)
      },

      readSourceOfIncome() {
        return StoreUtils.rootGetters(StoreUtils.getters.accountInformation.getReadSourceOfIncome)
      },

    },

    async mounted() {
      await StoreUtils.dispatch(StoreUtils.actions.accountInformation.accountReadSourceOfIncome)
      await StoreUtils.dispatch(StoreUtils.actions.accountInformation.accountInformationReaByCustomerId, {
        accountInformationCustomerId : this.userInfo.customerId
      })
      this.model = this.readByAccountInformationCustomerId.data
    }
	};
</script>
<style scoped lang="scss">
   h3 {margin: 40px 0 0; }
   ul {list-style-type: none; padding: 0; }
   li {display: inline-block; margin: 0 10px; }

   /* Chrome, Safari, Edge, Opera */
   input::-webkit-outer-spin-button,
   input::-webkit-inner-spin-button {
     -webkit-appearance: none;
     margin: 0;
   }

   /* Firefox */
   input[type=number] {
     -moz-appearance: textfield;
   }
</style>

