class KycIdentificationRequest {

    static create = {
        identificationUserId : null,
        identificationType : null,
        identificationNumber : null,
        identificationIssueDate : null,
        identificationExpiryDate : null,
        identificationLink : null,
        identificationCustomerId: null,
        identificationCardNo: null
    };

    update = {
        identificationId: null,
        identificationUserId: null,
        identificationType: null,
        identificationNumber: null,
        identificationIssueDate: null,
        identificationExpiryDate: null,
        identificationLink: null,
        identificationStatus: null
    };


    readBy = {
        identificationId :  null
    };

    static readByIdentificationCustomerId = {
        identificationCustomerId: null
    }

}

export default KycIdentificationRequest;