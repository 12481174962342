<template>

		<section id="AccountCreationSuccessCard" class="container-small">
            <div class="image-wrapper"><img src="@/assets/images/party-popper-1.png" loading="lazy" width="161" sizes="161px" srcset="@/assets/images/party-popper-1-p-500.png 500w, @/assets/images/party-popper-1.png 744w" alt=""></div>
            <div class="form-wrapper">
              <div class="form-header-wrapper">
                <div class="margin-top">
                  <h3 class="text-align-center text-color-white">You&#x27;re all set!</h3>
                  <div class="margin-top margin-xsmall padding-horizontal padding-small">
                    <p class="newsletter-text text-color-white text-align-center">Here we go. Your journey towards <br>awesomeness starts now</p>
                  </div>
                </div>
              </div>
              <div class="form-body-wrapper">
                <div class="margin-top margin-large">
                  <div class="form-fields-wrapper">
                    <div class="margin-top">
                      <div class="margin-bottom margin-small">
<!--                        <a href="@/assets/views/loginview.html" class="button white-button w-button">Proceed to Login</a>-->
                        <router-link to="/log-in-view"><a class="button white-button w-button">Proceed to Login</a></router-link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
</template>
<script>
	export default {
	name: "AccountCreationSuccessCard"
	};
</script>
<style scoped lang="scss">
   h3 {margin: 40px 0 0; }
   ul {list-style-type: none; padding: 0; }
   li {display: inline-block; margin: 0 10px; }
   //a {color: #42b983;}
</style>

