<template>
  <auth-layout>
    <sign-up-personal-details-form/>
  </auth-layout>
</template>
<script>
	//@ is an alias to /src
	// import SignUpForm from '@/components/form/SignUpForm.vue'
	import SignUpPersonalDetailsForm from "@/components/form/SignUpPersonalDetailsForm";
  import AuthLayout from "@/layout/AuthLayout";
  export default{
			name: 'SignUpPersonalDetailsView',
			components:{
        AuthLayout,
        SignUpPersonalDetailsForm
				// SignUpForm
			}
	}
</script>
<style scoped>
</style>
