import swal from "sweetalert";
import StoreUtils from "@/util/baseUtils/StoreUtils";
import AccountInformationResponse from "@/model/response/AccountInformationResponse";
import AccountInformationRequest from "@/model/request/AccountInformationRequest";
import AccountInformationService from "@/service/AccountInformationService";
import RouterUtils from "@/util/baseUtils/RouterUtils";



export const state = {
    loading: false,
    account: null,
    accounts: new AccountInformationResponse().read,
    readSourceOfIncome: new AccountInformationResponse().readSourceOfIncome,
    readByAccountInformationCustomerId: new AccountInformationResponse().readByAccountInformationCustomerId,
};

export const getters = {
    getAccount: (state) => {
        return state.account;
    },
    getAccounts: (state) => {
        return state.accounts;
    },
    getReadSourceOfIncome: (state) => {
        return state.readSourceOfIncome
    },
    getReadByAccountInformationCustomerId: (state) => {
        return state.readByAccountInformationCustomerId
    }
};


export const mutations = {
    updateLoading(state, payload){
        state.loading = payload
    },
    updateAccount(state, payload){
        state.account = payload
    },
    updateAccounts(state, payload){
        state.accounts = payload
    },
    updateReadSourceOfIncome(state, payload){
        state.readSourceOfIncome = payload
    },
    updateReadByAccountInformationCustomerId(state, payload){
        state.readByAccountInformationCustomerId = payload
    },
};


export const actions = {
    // eslint-disable-next-line no-empty-pattern
    accountInformationCreate({}, payload = new AccountInformationRequest().create){
        StoreUtils.commit(StoreUtils.mutations.accountInformation.updateLoading, true)
        return AccountInformationService.callAccountInformationCreateApi(payload).then(response=>{
            StoreUtils.commit(StoreUtils.mutations.accountInformation.updateLoading, false)
            let responseData = response.data
            if (responseData.responseCode === "00"){
                swal("Success",responseData.responseMessage, 'success').then(r => console.log(r))
                RouterUtils.navigateTo(RouterUtils.routes.kyc.kycVerification.name)
            }else{
                swal("Error",responseData.responseMessage, 'error').then(r => console.log(r))
            }
        }).catch(error=>{
            console.log(error)
            StoreUtils.commit(StoreUtils.mutations.accountInformation.updateLoading, false)
        })
    },



    //eslint-disable-next-line no-empty-pattern
    accountReadSourceOfIncome({}, payload = {}){
        StoreUtils.commit(StoreUtils.mutations.accountInformation.updateLoading, true)
        return AccountInformationService.callAccountInformationReadSourceOfIncomeApi(payload).then(response=>{
            StoreUtils.commit(StoreUtils.mutations.accountInformation.updateLoading, false)
            let responseData = response.data
            if (responseData.responseCode === '00'){
                StoreUtils.commit(StoreUtils.mutations.accountInformation.updateReadSourceOfIncome, responseData)
            }else{
                swal("Error",responseData.responseMessage, 'error').then(r => console.log(r))
            }
        }).catch(error=>{
            console.log(error)
            StoreUtils.commit(StoreUtils.mutations.accountInformation.updateLoading, false)
        })
    },


    // eslint-disable-next-line no-empty-pattern
    accountInformationReaByCustomerId({}, payload = new AccountInformationRequest().readByAccountInformationCustomerId){
        StoreUtils.commit(StoreUtils.mutations.accountInformation.updateLoading, true)
        return AccountInformationService.callAccountInformationReadByCustomerIdApi(payload).then(response=>{
            StoreUtils.commit(StoreUtils.mutations.accountInformation.updateLoading, false)
            let responseData = response.data
            if (responseData.responseCode === "00"){
                StoreUtils.commit(StoreUtils.mutations.accountInformation.updateReadByAccountInformationCustomerId, responseData)
            }else{
                // swal("Error",responseData.responseMessage, 'error').then(r => console.log(r))
            }
        }).catch(error=>{
            console.log(error)
            StoreUtils.commit(StoreUtils.mutations.accountInformation.updateLoading, false)
        })
    },

};