<template>
  <section id="ReviewKycCard" class="container-small">
            <div class="image-wrapper"><img src="@/assets/images/clock.png" loading="lazy" width="161" alt=""></div>
            <div class="form-wrapper">
              <div class="form-header-wrapper">
                <div class="margin-top">
                  <h3 class="text-align-center text-color-white">Reviewing</h3>
                  <div class="margin-top margin-xsmall padding-horizontal padding-small">
                    <p class="newsletter-text text-color-white text-align-center">Your address information is being reviewed and you will be notified when verified.</p>
                  </div>
                </div>
              </div>
              <div class="form-body-wrapper">
                <div class="margin-top margin-large">
                  <div class="form-fields-wrapper">
                    <div class="margin-top">
                      <div class="margin-bottom margin-small">


                          <router-link to="/kyc-verification"><a class="button white-button w-button">Back to verification home</a></router-link>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
</template>
<script>
	export default {
	name: "ReviewKycCard"
	};
</script>
<style scoped lang="scss">
   h3 {margin: 40px 0 0; }
   ul {list-style-type: none; padding: 0; }
   li {display: inline-block; margin: 0 10px; }

</style>

