<template>
  <dashboard-layout>
    <div class="transaction-header-wrapper">
      <div data-animation="over-right" data-collapse="small" data-duration="400" data-easing="ease" data-easing2="ease" role="banner" class="navbar w-nav">
        <NavBar/>
      </div>
    </div>
    <div class="margin-top margin-small">
      <div class="transaction-wrapper">
        <div class="margin-bottom margin-medium">
          <div class="filter-wrapper">
            <div data-hover="false" data-delay="0" class="filter w-dropdown">
              <div class="filter-inner w-dropdown-toggle">
                <div class="w-icon-dropdown-toggle"></div>
                <div>Date</div>
              </div>
              <nav class="w-dropdown-list">
                <a href="#" class="w-dropdown-link">Link 1</a>
                <a href="#" class="w-dropdown-link">Link 2</a>
                <a href="#" class="w-dropdown-link">Link 3</a>
              </nav>
            </div>
            <div data-hover="false" data-delay="0" class="filter w-dropdown">
              <div class="filter-inner w-dropdown-toggle">
                <div class="w-icon-dropdown-toggle"></div>
                <div>Status</div>
              </div>
              <nav class="w-dropdown-list">
                <a href="#" class="w-dropdown-link">Link 1</a>
                <a href="#" class="w-dropdown-link">Link 2</a>
                <a href="#" class="w-dropdown-link">Link 3</a>
              </nav>
            </div>
            <div data-hover="false" data-delay="0" class="filter w-dropdown">
              <div class="filter-inner w-dropdown-toggle">
                <div class="w-icon-dropdown-toggle"></div>
                <div>Currency</div>
              </div>
              <nav class="w-dropdown-list">
                <a href="#" class="w-dropdown-link">Link 1</a>
                <a href="#" class="w-dropdown-link">Link 2</a>
                <a href="#" class="w-dropdown-link">Link 3</a>
              </nav>
            </div>
          </div>
        </div>
        <div class="transaction-list-wrapper">
          <div class="transaction-list">
            <div class="margin-bottom margin-medium">
              <div class="text-block-74">Today</div>
            </div>
            <div class="transaction-list-content">
              <a href="#" class="transactions w-inline-block">
                <div class="txn-details"><img src="@/assets/images/outward.svg" loading="lazy" alt="">
                  <div class="name-status-wrapper">
                    <div class="name-text">Olakunle Temitope</div>
                    <div class="text-block-76">Successful</div>
                  </div>
                </div>
                <div class="txn-amount">
                  <div class="amount-text">-5.00 CAD</div>
                  <div class="converted-amount-text">-4,047.50NGN</div>
                </div>
              </a>
              <a href="#" class="transactions w-inline-block">
                <div class="txn-details"><img src="@/assets/images/outward.svg" loading="lazy" alt="">
                  <div class="name-status-wrapper">
                    <div class="name-text">Olakunle Temitope</div>
                    <div class="text-block-76">Successful</div>
                  </div>
                </div>
                <div class="txn-amount">
                  <div class="amount-text">-5.00 CAD</div>
                  <div class="converted-amount-text">-4,047.50NGN</div>
                </div>
              </a>
              <a href="#" class="transactions last w-inline-block">
                <div class="txn-details"><img src="@/assets/images/outward.svg" loading="lazy" alt="">
                  <div class="name-status-wrapper">
                    <div class="name-text">Olakunle Temitope</div>
                    <div class="text-block-76">Successful</div>
                  </div>
                </div>
                <div class="txn-amount">
                  <div class="amount-text">-5.00 CAD</div>
                  <div class="converted-amount-text">-4,047.50NGN</div>
                </div>
              </a>
            </div>
          </div>
          <div class="transaction-list">
            <div class="margin-bottom margin-medium">
              <div class="text-block-74">15 September 2022</div>
            </div>
            <div class="transaction-list-content">
              <a href="#" class="transactions w-inline-block">
                <div class="txn-details"><img src="@/assets/images/outward.svg" loading="lazy" alt="">
                  <div class="name-status-wrapper">
                    <div class="name-text">Olakunle Temitope</div>
                    <div class="text-block-76">Successful</div>
                  </div>
                </div>
                <div class="txn-amount">
                  <div class="amount-text">-5.00 CAD</div>
                  <div class="converted-amount-text">-4,047.50NGN</div>
                </div>
              </a>
              <a href="#" class="transactions w-inline-block">
                <div class="txn-details"><img src="@/assets/images/outward.svg" loading="lazy" alt="">
                  <div class="name-status-wrapper">
                    <div class="name-text">Olakunle Temitope</div>
                    <div class="text-block-76">Successful</div>
                  </div>
                </div>
                <div class="txn-amount">
                  <div class="amount-text">-5.00 CAD</div>
                  <div class="converted-amount-text">-4,047.50NGN</div>
                </div>
              </a>
              <a href="#" class="transactions last w-inline-block">
                <div class="txn-details"><img src="@/assets/images/outward.svg" loading="lazy" alt="">
                  <div class="name-status-wrapper">
                    <div class="name-text">Olakunle Temitope</div>
                    <div class="text-block-76">Successful</div>
                  </div>
                </div>
                <div class="txn-amount">
                  <div class="amount-text">-5.00 CAD</div>
                  <div class="converted-amount-text">-4,047.50NGN</div>
                </div>
              </a>
            </div>
          </div>
        </div>
        <div class="empty-state"><img src="@/assets/images/Empty-Illustration.svg" loading="lazy" alt="">
          <div>You have no transcation yet!</div>
        </div>
      </div>
    </div>
  </dashboard-layout>
</template>
<script>
	//@ is an alias to /src
	import NavBar from '@/components/bar/NavBar.vue'
  import DashboardLayout from "@/layout/DashboardLayout";
	export default{
			name: 'MyProfileView',
			components:{
        DashboardLayout,
				NavBar
			}
	}
</script>
<style scoped>
</style>
