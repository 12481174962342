<template>
  <section id="UpdateKycIntroCard" class="container-small">
            <div class="image-wrapper"><img src="@/assets/images/Group-48095645.png" loading="lazy" width="167" sizes="167px" srcset="@/assets/images/Group-48095645-p-500.png 500w, @/assets/images/Group-48095645.png 592w" alt=""></div>
            <div class="form-wrapper">
              <div class="form-header-wrapper">
                <div class="margin-top margin-medium">
                  <h3 class="text-align-center text-color-white padding-horizontal padding-large">Hi <strong>{{this.userInfo.customerFirstName}}</strong>👋, we’ll need to verify your identity</h3>
                  <div class="margin-top margin-xsmall padding-horizontal padding-large">
                    <p class="newsletter-text text-color-white text-align-center">We’re required by law to verify your identity before you can send money.</p>
                  </div>
                </div>
              </div>
              <div class="form-body-wrapper">
                <div class="margin-top margin-large">
                  <div class="form-fields-wrapper">
                    <div class="margin-top">
                      <div class="margin-bottom margin-small">
                        <router-link to="/kyc-verification">
                          <a class="button white-button w-button">Verify identity</a>
                        </router-link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
</template>
<script>
	import {mapState} from "vuex";
  // import StoreUtils from "@/util/baseUtils/StoreUtils";

  export default {
	name: "UpdateKycIntroCard",
    computed:{
      ...mapState({
        loading: state => state.auth.loading,
        screen: state => state.auth.screen,
        auth: state => state.auth,
        userInfo: state => state.auth.userInfo
      }),
    }
	};
</script>
<style scoped lang="scss">
   h3 {margin: 40px 0 0; }
   ul {list-style-type: none; padding: 0; }
   li {display: inline-block; margin: 0 10px; }

   strong{
     text-transform: lowercase;
   }


</style>

