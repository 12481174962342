<!--suppress ALL -->
<template>
  <section id="SignUpForm" class="container-small">
    <div class="image-wrapper"><img src="@/assets/images/Group-22264.svg" loading="lazy" alt=""></div>

    <div class="form-wrapper">
      <div class="form-header-wrapper">
        <div class="margin-top margin-xlarge">
          <h3>Tell us about yourself</h3>
        </div>
        <div class="margin-top margin-xsmall">
          <p class="text-weight-medium text-color-grey">We need this information to verify your identity</p>
        </div>
      </div>

      <form class="form-body-wrapper" @submit.prevent="submitForm" >


        <div class="margin-top">

          <div class="seperate">
            <div class="form-fields-wrapper">
              <div class="margin-top">
                <label>First Name</label>
                <input type="text" v-model="model.customerFirstName" class="input-field w-input" maxlength="256" name="field" data-name="Field" placeholder="First Name" id="field" required="required">
              </div>
            </div>

            <div class="form-fields-wrapper">
              <div class="margin-top">
                <label>Last Name</label>
                <input type="text" v-model="model.customerLastName" class="input-field w-input" maxlength="256" name="field" data-name="Field" placeholder="Last Name" id="field" required="required">
              </div>
            </div>
          </div>

          <div class="form-fields-wrapper" style="margin-bottom:11px;">
            <div class="margin-top">
              <label>Email Address</label>
              <input type="email" v-model="model.customerEmail" class="input-field w-input" maxlength="256" name="field" data-name="Field" placeholder="Enter Interac email address" id="field" required="required">
            </div>
          </div>


          <label>Phone Number</label>
          <div class="phone-input-field">
            <div data-hover="false" data-delay="0" class="w-dropdown">
              <div class="select-country-wrapper w-dropdown-toggle">
                <img src="@/assets/images/canadian-flag.svg" alt="caFlag" class="law">
                <p class="country-flag" >+1</p>
              </div>
            </div>
            <input type="number" v-model="model.customerPhone" class="input-field phone-number w-input" maxlength="256" name="field" data-name="Field" placeholder="Enter Phone Number " id="field" required="">
          </div>


          <div class="form-fields-wrapper">
            <div class="margin-top">
<!--              @click="changeInputType" @focus="changeInputType" @blur="inputType"-->
<!--              <input type="date"  v-model="model.customerDob" class="input-field w-input" maxlength="256" minlength="256" name="field" data-name="Field" placeholder="Date of birth (MM/DD/YYYY)"  aria-placeholder="Date of birth (MM/DD/YYYY)" id="field" required="required">-->

<!--                <input type="date" v-model="model.customerDob" onfocus="(this.type='date')" onclick="(this.type='date')" onblur="(this.type='text')" onchange="(this.type='date')" maxlength="256" minlength="256" name="field" data-name="Field" placeholder="Date of birth (MM/DD/YYYY)" aria-placeholder="Date of birth (MM/DD/YYYY)" id="field" required="required" class="input-field w-input">-->

<!--              -->
              <label>Date of birth</label>
              <input
                  type="date"
                  id="birthdate"
                  v-model="model.customerDob"
                  class="input-field w-input"
                  maxlength="256" name="field"
                  data-name="Field" placeholder="Date of birth (MM/DD/YYYY)"
                  required="required"
                  :max="maxDate"
              />


<!--              onfocus="this.type = 'date'"-->
<!--              onclick="this.type='date'"-->
<!--              onblur="this.type='text'"-->
<!--              onchange="this.type='date'"-->

<!--              <FormulateInput-->
<!--                  type="date"-->
<!--                  name="Field"-->
<!--                  label="Date of birth (MM/DD/YYYY)"-->
<!--                  v-model="model.customerDob"-->
<!--                  maxlength="256"-->
<!--                  minlength="256"-->
<!--                  placeholder="Date of birth (MM/DD/YYYY)"-->
<!--                  style="margin-top:11px;color: #667085;"-->
<!--              />-->

            </div>
          </div>

          <div class="form-fields-wrapper">
            <label>Gender</label>
            <div class="margin-top">
              <select
                  class="input-field w-input"
                  v-model="model.customerGender"
                  maxlength="256"
                  name="field"
                  data-name="Field"
                  placeholder="Gender"
                  aria-placeholder="Gender"
                  id="field"
                  required="required"
              >
                <option :value="null" disabled>Select Gender</option>
                <option value="Male">Male</option>
                <option value="Female">Female</option>
                <option value="Other">Other</option>
              </select>
            </div>
          </div>

        </div>

        <br/>

        <div class="margin-to">
          <div class="form-fields-wrapper">
            <div class="margin-top">
              <div class="margin-bottom margin-small">
<!--                <button class="button max-width-full w-button" >Proceed</button>-->
                <base-button :loading="loading">Proceed</base-button>
              </div>
              <router-link to="/log-in-view">
                <a class="button is-secondary max-width-full text-weight-medium w-button">Do you have an account?<span class="text-weight-bold red-text">Login</span></a>
              </router-link>
            </div>
          </div>
        </div>
      </form>

    </div>

  </section>
</template>

<script>
import AuthenticationRequest from "@/model/request/AuthenticationRequest";
import StoreUtils from "@/util/baseUtils/StoreUtils";
import {mapState} from "vuex";
import BaseButton from "@/components/button/BaseButton";
import swal from 'sweetalert';


export default {
  name: "SignUpPersonalDetailsForm",
  components: {BaseButton},
  data(){
    return {
      model: new AuthenticationRequest().initiateEnrollment,
      ageLimit: 18, // Adjust this value as needed
    }
  },
  methods:{
    initiateEnrollment(){
      StoreUtils.dispatch(StoreUtils.actions.auth.initiateEnrollment, this.model)
      StoreUtils.commit(StoreUtils.mutations.auth.updateSignUpFormData, {
        customerCountryCode: "+1",
        customerCountry: this.model.customerCountry,
        customerEmail: this.model.customerEmail,
        customerFirstName: this.model.customerFirstName,
        customerLastName: this.model.customerLastName,
        customerDob: this.model.customerDob,
        customerGender: this.model.customerGender,
        customerPhone: this.model.customerPhone,
        source: "WEB"
      })
    },

    submitForm() {
      const birthdate = new Date(this.model.customerDob);
      const age = new Date() - birthdate;
      const years = age / (1000 * 60 * 60 * 24 * 365.25); // Approximate calculation

      if (years < this.ageLimit) {
        // alert(`You must be at least ${this.ageLimit} years old.`);
        swal('Error!', `You must be at least ${this.ageLimit} years old.` , 'error');
      } else {

        this.initiateEnrollment()
      }
    },
  },
  computed:{
    ...mapState({
      loading: state => state.auth.loading,
      screen: state => state.auth.screen,
      auth: state => state.auth
    }),
    maxDate() {
      const today = new Date();
      const year = today.getFullYear() - this.ageLimit;
      const month = today.getMonth() + 1; // Months are zero-based
      const day = today.getDate();
      return `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`;
    },
  }
}
</script>

<style scoped lang="scss">

h3 {margin: 40px 0 0; }
ul {list-style-type: none; padding: 0; }
li {display: inline-block; margin: 0 10px; }
.seperate{
  display: flex;
  justify-content: space-between;
}

.law{
  padding-right: 3px;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

input[type=date]:required:invalid::-webkit-datetime-edit {
  color: transparent;
}
input[type=date]:focus::-webkit-datetime-edit {
  color: #667085 !important;
}


input[type='date']::before {
  //content: "Date of birth";
  min-width: 30px;
  padding-right: 5%;
  height: 50px;
  margin-top: 0.6rem;
  margin-bottom: 0px;
  padding-top: 11px;
  //padding-bottom: 10px;
  //border: 1px solid #d0d5dd;
  border-radius: 8px;
  color: #667085;
  font-size: 1rem;
}


label{
  color: #667085;
  padding-top: 5px;
}


</style>