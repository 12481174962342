<template>
		<section id="NotificationListCard" class="transaction-list">
                <div class="margin-bottom margin-medium">
                  <div class="text-block-74">Today</div>
                </div>
                <div class="transaction-list-content">
                  <a id="NotificationCard" href="#" class="notifications w-inline-block">
                    <div class="notification-details"><img src="@/assets/images/outward.svg" loading="lazy" alt="">
                      <div class="name-status-wrapper">
                        <div class="notification-text">Your transfer of NGN 4,400.00 to EZEKIEL JEREMIAH IBANGA has been processed successfully</div>
                        <div class="notification-date">8:33 PM</div>
                      </div>
                    </div>
                  </a>
                  <a href="#" class="notifications w-inline-block">
                    <div class="notification-details"><img src="@/assets/images/outward.svg" loading="lazy" alt="">
                      <div class="name-status-wrapper">
                        <div class="notification-text">Your transfer of NGN 4,400.00 to EZEKIEL JEREMIAH IBANGA has been processed successfully</div>
                        <div class="notification-date">8:33 PM</div>
                      </div>
                    </div>
                  </a>
                  <a href="#" class="notifications w-inline-block">
                    <div class="notification-details"><img src="@/assets/images/outward.svg" loading="lazy" alt="">
                      <div class="name-status-wrapper">
                        <div class="notification-text">Your transfer of NGN 4,400.00 to EZEKIEL JEREMIAH IBANGA has been processed successfully</div>
                        <div class="notification-date">8:33 PM</div>
                      </div>
                    </div>
                  </a>
                  <a href="#" class="notifications last w-inline-block">
                    <div class="notification-details"><img src="@/assets/images/outward.svg" loading="lazy" alt="">
                      <div class="name-status-wrapper">
                        <div class="notification-text">Your transfer of NGN 4,400.00 to EZEKIEL JEREMIAH IBANGA has been processed successfully</div>
                        <div class="notification-date">8:33 PM</div>
                      </div>
                    </div>
                  </a>
                </div>
              </section>
</template>
<script>
	export default {
	name: "NotificationListCard"
	};
</script>
<style scoped lang="scss">
   h3 {margin: 40px 0 0; }
   ul {list-style-type: none; padding: 0; }
   li {display: inline-block; margin: 0 10px; }
   a {color: #42b983;}
</style>

