import swal from "sweetalert";
import StoreUtils from "@/util/baseUtils/StoreUtils";

import KycIdentificationRequest from "@/model/request/KycIdentificationRequest";

import KycIdentificationService from "@/service/KycIdentificationService";

import KycIdentificationResponse from "@/model/response/KycIdentificationResponse";
import RouterUtils from "@/util/baseUtils/RouterUtils";




export const state = {
    loading: false,
    identification: [],
    identifications: new KycIdentificationResponse().read,
    readByIdentificationCustomerId: new KycIdentificationResponse().readByIdentificationCustomerId,
};

export const getters = {
    getIdentification: (state) => {
        return state.identification;
    },
    getIdentifications: (state) => {
        return state.identifications;
    },
    getReadByIdentificationCustomerId: (state) => {
        return state.readByIdentificationCustomerId;
    }
};


export const mutations = {
    updateLoading(state, payload){
        state.loading = payload
    },
    updateResponse(state, payload){
        state.response = payload
    },
    updateIdentification(state, payload){
        state.identification = payload
    },
    updateIdentifications(state, payload){
        state.identifications = payload
    },
    updateReadByIdentificationCustomerId(state, payload){
        state.readByIdentificationCustomerId = payload
    },
};

export const actions = {
    // eslint-disable-next-line no-empty-pattern
    identificationCreate({commit}, payload = new KycIdentificationRequest().create){
        StoreUtils.commit(StoreUtils.mutations.identification.updateLoading, true)
        return KycIdentificationService.callIdentificationCreateApi(payload).then(response=>{
            StoreUtils.commit(StoreUtils.mutations.identification.updateLoading, false)
            let responseData = response.data
            if (responseData.responseCode === "00"){
                commit("updateResponse", responseData.responseCode)
                swal("Success",responseData.responseMessage, 'success').then(r => console.log(r))
                RouterUtils.navigateTo(RouterUtils.routes.kyc.reviewingKycId.name);
                return responseData;
            }else{
                commit("updateResponse", responseData.responseCode)
                swal("Error",responseData.responseMessage, 'error').then(r => console.log(r))
            }
        }).catch(error=>{
            console.log(error)
            StoreUtils.commit(StoreUtils.mutations.identification.updateLoading, false)
        })
    },

    // eslint-disable-next-line no-empty-pattern
    identificationUpdate({}, payload = new KycIdentificationRequest().update){
        StoreUtils.commit(StoreUtils.mutations.identification.updateLoading, true)
        return KycIdentificationService.callIdentificationUpdateApi(payload).then(response=>{
            StoreUtils.commit(StoreUtils.mutations.identification.updateLoading, false)
            let responseData = response.data
            if (responseData.responseCode === "00"){
                StoreUtils.commit(StoreUtils.mutations.identification.updateResponse, responseData.responseCode)
                swal("Success",responseData.responseMessage, 'success').then(r => console.log(r))
            }else{
                swal("Error",responseData.responseMessage, 'error').then(r => console.log(r))
            }
        }).catch(error=>{
            console.log(error)
            StoreUtils.commit(StoreUtils.mutations.identification.updateLoading, false)
        })
    },


    // eslint-disable-next-line no-empty-pattern
    identificationReadBy({}, payload = new KycIdentificationRequest().readBy){
        StoreUtils.commit(StoreUtils.mutations.identification.updateLoading, true)
        return KycIdentificationService.callIdentificationReadByApi(payload).then(response=>{
            StoreUtils.commit(StoreUtils.mutations.identification.updateLoading, false)
            let responseData = response.data
            if (responseData.responseCode === "00"){
                StoreUtils.commit(StoreUtils.mutations.identifications, responseData.identifications)
            }else{
                swal("Error",responseData.responseMessage, 'error').then(r => console.log(r))
            }
        }).catch(error=>{
            console.log(error)
            StoreUtils.commit(StoreUtils.mutations.identification.updateLoading, false)
        })
    },

    // eslint-disable-next-line no-empty-pattern
    identificationRead({}, payload = {}){
        StoreUtils.commit(StoreUtils.mutations.identification.updateLoading, true)
        return KycIdentificationService.callAddressReadApi(payload).then(response=>{
            StoreUtils.commit(StoreUtils.mutations.identification.updateLoading, false)
            let responseData = response.data
            if (responseData.responseCode === '00'){
                StoreUtils.commit(StoreUtils.mutations.identifications, responseData.identifications)
            }else{
                swal("Error",responseData.responseMessage, 'error').then(r => console.log(r))
            }
        }).catch(error=>{
            console.log(error)
            StoreUtils.commit(StoreUtils.mutations.identification.updateLoading, false)
        })
    },

    // eslint-disable-next-line no-empty-pattern
    readByIdentificationCustomerId({}, payload = new KycIdentificationRequest().readByIdentificationCustomerId){
        StoreUtils.commit(StoreUtils.mutations.identification.updateLoading, true)
        return KycIdentificationService.callReadByIdentificationCustomerIdApi(payload).then(response=>{
            StoreUtils.commit(StoreUtils.mutations.identification.updateLoading, false)
            let responseData = response.data
            if (responseData.responseCode === "00"){
                StoreUtils.commit(StoreUtils.mutations.identification.updateIdentification, responseData.data)
                // commit('updateIdentification', responseData.data)
                StoreUtils.commit(StoreUtils.mutations.identification.updateReadByIdentificationCustomerId, responseData.data)
            }else{
                swal("Error",responseData.responseMessage, 'error').then(r => console.log(r))
            }
        }).catch(error=>{
            console.log(error)
            StoreUtils.commit(StoreUtils.mutations.identification.updateLoading, false)
        })
    },

};