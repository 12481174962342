<template>
		<section id="NewsletterCard" class="container-small">
            <div class="image-wrapper"><img src="@/assets/images/subscribe-1.png" loading="lazy" width="226" sizes="(max-width: 479px) 90vw, 226px" srcset="@/assets/images/subscribe-1-p-500.png 500w, @/assets/images/subscribe-1-p-800.png 800w, @/assets/images/subscribe-1.png 904w" alt=""></div>
            <div class="form-wrapper">
              <div class="form-header-wrapper">
                <div class="margin-top">
                  <h3 class="text-align-center text-color-white">Share exciting things with me</h3>
                  <div class="margin-top margin-xsmall padding-horizontal padding-large">
                    <p class="newsletter-text text-color-white text-align-center">We want to let you know about new features,<br>offers and rewards that we think you&#x27;ll like.<br>‍<br>It&#x27;ll only be the odd email or push notification<br>and you can opt out in Settings at any time.</p>
                  </div>
                </div>
              </div>
              <div class="form-body-wrapper">
                <div class="margin-top margin-large">
                  <div class="form-fields-wrapper">
                    <div class="margin-top">
                      <div class="margin-bottom margin-small">
                        <router-link to="/update-kyc-step">
                          <a class="button white-button w-button">Yes Please</a>
                        </router-link>
                      </div>
                      <a href="#" class="button is-text text-color-white w-button">Not Now</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
</template>
<script>
	export default {
	name: "NewsletterCard"
	};
</script>
<style scoped lang="scss">
   h3 {margin: 40px 0 0; }
   ul {list-style-type: none; padding: 0; }
   li {display: inline-block; margin: 0 10px; }
</style>

