import RouterUtils from "@/util/baseUtils/RouterUtils"
// import CreateEntityView from "../../views/CreateEntityView";
import LandingPage from '@/views/LandingPage.vue';


export default [
    {
        path: RouterUtils.routes.base.home.path,
        name: RouterUtils.routes.base.home.name,
        meta: {layout: "home", authRequired: false},
        component: LandingPage,
    },
    // {
    // path: RouterUtils.routes.base.createEntity.path,
    // name: RouterUtils.routes.base.createEntity.name,
    // meta: {layout: "home", authRequired: true},
    // component: CreateEntityView,
    // },
];
